import URI from "urijs";

export const getAutocompleteSuggestions = async (searchTerm: string, country: string, blockedProfiles: string[]) => {
  if (searchTerm === "") {
    return [];
  }
  const url = URI('/api/search/autocomplete');
  url.addQuery("q", searchTerm);
  url.addQuery("country", country);
  url.addQuery("blockedProfiles", blockedProfiles)
  const response = await fetch(url.toString());
  const json = await response.json();
  return json.items;
}
