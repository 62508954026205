import {
  getCollectionsFromContentfulAdapter,
  getSubjectCollectionByUsNameFromContentfulAdapter,
  getSubjectCollectionFromContentfulAdapter,
} from './adapters/contentful-collection-content-api';
import { getContentfulClient } from '../../../config/contentful-config';
import type { GetCollections, GetSubjectCollection } from './collection-types';
import type { GenericGetContents } from '../../../content/data/generics/generic-get-content';
import type {
  Collection,
  UsSpecificSubjectFields,
} from '../../data/collection';
import getContentFromBff from '../../../content/factories/get-content-from-bff';

const getCollections: GetCollections = getCollectionsFromContentfulAdapter(
  getContentfulClient()
);

const getSubjectCollection: GetSubjectCollection =
  getSubjectCollectionFromContentfulAdapter(getContentfulClient());

const getSubjectCollectionByUsName: GetSubjectCollection =
  getSubjectCollectionByUsNameFromContentfulAdapter(getContentfulClient());

const getCollectionFromEs: GenericGetContents<
  Collection & UsSpecificSubjectFields
> = getContentFromBff<Collection & UsSpecificSubjectFields>('collection');

export {
  getCollections,
  getCollectionFromEs,
  getSubjectCollection,
  getSubjectCollectionByUsName,
};
