import { Action, Category, NavigationClicked } from '../data/events';

export const createNavigationClickEvent = (
  action: Action.HEADER | Action.FOOTER | Action.BANNER,
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): NavigationClicked => ({
  category: Category.NAVIGATION,
  action,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});
