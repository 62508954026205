import React, { useEffect, useState } from 'react';
import Suggestions from './suggestions';
import type { AutocompleteSuggestion } from '../data/autocomplete';
import { useTypedSelector } from '../../store/hooks/use-typed-selector';
import {
  organisationBlockedProfilesSelector,
  organisationCountryAlpha3Selector,
} from '../../store/user/selectors';
import { getAutocompleteSuggestions } from '../api/frontend/get-autocomplete-suggestions';
import { searchTermSelector } from '../../store/search/selectors';

export const AutocompleteSuggestions: React.FC = () => {
  const [suggestions, setSuggestions] = useState<AutocompleteSuggestion[]>([]);
  const countryCodeAlpha3 = useTypedSelector(organisationCountryAlpha3Selector);
  const blockedProfiles = useTypedSelector(organisationBlockedProfilesSelector);
  const searchTerm: string = useTypedSelector(searchTermSelector);

  useEffect(() => {
    getAutocompleteSuggestions(
      searchTerm,
      countryCodeAlpha3,
      blockedProfiles
    ).then((response) => {
      setSuggestions(response.map((item) => ({ name: item })));
    });
  }, [searchTerm, countryCodeAlpha3, blockedProfiles]);

  if (suggestions.length === 0) {
    return null;
  }

  return <Suggestions suggestions={suggestions} searchTerm={searchTerm} />;
};
