import type { FC, ReactElement } from 'react';
import Link from 'next/link';
import { useAuthContext } from '../../../auth/providers/auth-context';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import {
  organisationNameSelector,
  userNameSelector,
} from '../../../store/user/selectors';
import Button from '../button/button';
import { useShowBookmarks } from '../../hooks/use-show-bookmarks';
import bookmarkFilled from '../../../assets/svg/sprites/bookmark-filled.svg';

const UserName: FC = (): ReactElement => {
  const userName = useTypedSelector(userNameSelector);

  return (
    <div className="c-user-info__name" data-matomo-mask>
      {userName}
    </div>
  );
};

const SignOutButton: FC = (): ReactElement | null => {
  const { appAuthService, appAuthState } = useAuthContext();
  const ipAuth =
    appAuthState?.accessToken === null ||
    appAuthState?.idToken === null ||
    appAuthState?.accessToken === undefined ||
    appAuthState?.idToken === undefined;

  if (ipAuth) {
    return null;
  }

  const onLogout = (): void => appAuthService?.logout();

  return (
    <div className="u--mr-1 u-ml-3 u-whitespace-nowrap">
      <Button
        handleButtonClick={onLogout}
        variant="secondary-dark"
        size="md"
        className="u-flex"
      >
        Sign out
      </Button>
    </div>
  );
};

const OrganisationName: FC = (): ReactElement | null => {
  const organisationName = useTypedSelector(organisationNameSelector);
  const { appAuthState } = useAuthContext();

  const ipAuth =
    appAuthState?.accessToken === null || appAuthState?.idToken === null;

  if (ipAuth || !organisationName) {
    return null;
  }

  return (
    <div className="c-header-dropdown__box__section c-header-dropdown__box__section--padded">
      <div className="u-mr-auto">Institution</div>
      <div className="u--mr-1 u-ml-3 u--my-2">
        <div className="c-button c-button--tertiary-dark c-button--md u-pointer-events-none">
          {organisationName}
        </div>
      </div>
    </div>
  );
};

const BookmarksLink: FC = () => {
  return (
    <div className="c-header-dropdown__box__section c-header-dropdown__box__section--padded">
      <svg
        viewBox={bookmarkFilled.viewBox}
        aria-hidden="true"
        height="16"
        width="14"
        className="u-mr-2"
      >
        <use href={bookmarkFilled} />
      </svg>
      <Link href="/account/bookmarks">
        <a className="type-inline-link type-inline-link--dark">Bookmarks</a>
      </Link>
    </div>
  );
};

const LoggedInMenuDropdown: FC = () => {
  const shownBookmarks = useShowBookmarks();
  return (
    <div className="c-header-dropdown__box__inner" tabIndex={-1}>
      <div className="c-header-dropdown__box__section c-header-dropdown__box__section--padded">
        <div className="c-user-info u-mr-auto">
          <UserName />
        </div>
        <SignOutButton />
      </div>
      {shownBookmarks && <BookmarksLink />}
      <OrganisationName />
      <div className="c-header-dropdown__box__section c-header-dropdown__box__section--padded">
        <div className="u-flex u-flex-col u-space-y-3 u-items-start">
          <a
            href="https://support.digitaltheatreplus.com"
            className="type-inline-link type-inline-link--dark"
          >
            Help and support
          </a>
          <a
            href="https://www.digitaltheatreplus.com/contact-us"
            className="type-inline-link type-inline-link--dark"
          >
            Contact
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoggedInMenuDropdown;
