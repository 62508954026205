/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { FC, MouseEvent } from 'react';
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { trackEvent } from '../../../analytics/api';
import Pill from '../pills/pill';
import { createEducationNavigationOptionClickEvent } from '../../../analytics/events/create-education-navigation-click-event';
import toKebabCase from '../../../utils/to-kebab-case';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { usePersonalisedMenu } from './hooks/use-personalised-menu';
import { getGroupedOptionsByCategory } from '../../../utils/get-grouped-options-by-category';

interface Props {
  menuOption: MenuOption;
  onClickCallback: (() => void) | undefined;
  onHoverCallback?: (() => void) | undefined;
  dropdownTitle: string;
  isCurrentOptionExpanded?: boolean;
}

const ExpandableOption: FC<Props> = ({
  menuOption: { title, url, isNew, ariaLabel, subOptions },
  onClickCallback,
  onHoverCallback,
  dropdownTitle,
  isCurrentOptionExpanded,
}: Props) => {
  const router = useRouter();
  const { pageTitle, pageType } = useAnalyticsContext();
  const { isUS } = usePersonalisedMenu();

  const expandable = !!subOptions;

  const isCurrentPage = (location): boolean =>
    `/${router.asPath.split('/')[1]}` === location;

  const event = useMemo(
    () =>
      createNavigationClickEvent(
        Action.HEADER,
        title,
        pageType,
        pageTitle,
        router.asPath
      ),
    [title, pageTitle, pageType, router.asPath]
  );

  const linkClickedEvent = useMemo(() => {
    const dropdownTitleToActionMap = {
      'Middle School': Action.MIDDLE_SCHOOL,
      'High School': Action.HIGH_SCHOOL,
      'Key stage 3': Action.KEY_STAGE_3,
      GCSE: Action.GCSE,
      'Higher Education': isUS
        ? Action.HIGHER_EDUCATION_US
        : Action.HIGHER_EDUCATION_UK,
      Browse: Action.BROWSE,
    };

    return (label: string, parentTitle: string) => {
      return createEducationNavigationOptionClickEvent(
        dropdownTitleToActionMap[parentTitle],
        label,
        pageType,
        pageTitle,
        router.asPath
      );
    };
  }, [isUS, pageType, pageTitle, router.asPath]);

  // This lets us style the link as soon as mouseUp happens, but sticks around
  // between pages, so we also have to remove it from the other not-clicked links
  function handleClick(
    e: MouseEvent,
    linkLabel: string,
    parentTitle: string
  ): void {
    e.stopPropagation();
    if (linkClickedEvent) {
      trackEvent(linkClickedEvent(linkLabel, parentTitle));
    } else {
      trackEvent(event);
    }

    document.querySelectorAll('.js-clicked').forEach((element) => {
      element.classList.remove('js-clicked');
    });
    e.currentTarget.classList.add('js-clicked');

    if (onClickCallback) {
      // Allow time for the user to see which link they pressed, instad of instantly closing
      setTimeout(onClickCallback, 200);
    }
  }

  const linkItem = (
    <>
      {isNew && (
        <Pill
          className="u-mr-2"
          variant="green"
          size="rel-sm"
          style={{ lineHeight: 1.4 }} // Custom line-height just for top-level navigation links
        >
          NEW
        </Pill>
      )}
      <span className="c-link-item-text">{title}</span>
    </>
  );

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isCurrentOptionExpanded) {
      setIsFocused(false);
    }
  }, [isCurrentOptionExpanded]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <li
      key={toKebabCase(title)}
      data-testid={`${toKebabCase(title)}-option`}
      className={`u-static ${expandable ? 'c-expandable-option' : ''}`}
      onMouseOver={() => onHoverCallback?.()}
      onKeyDown={(e) => {
        if (url) {
          return;
        }
        if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowRight') {
          setIsFocused(true);
          e.preventDefault(); // prevent scroll
          const li = e.currentTarget as HTMLLIElement;
          (
            li.querySelectorAll('.c-second-level-links a')[0] as HTMLElement
          ).focus();
        }
      }}
      onFocus={() => onHoverCallback?.()}
    >
      <div
        className="u-w-full"
        style={{
          borderRadius: '4px',
          background: isCurrentOptionExpanded ? '#F5F6F7' : '',
        }}
      >
        {url ? (
          <Link href={url}>
            <a
              data-testid={`${toKebabCase(title)}-link`}
              aria-current={isCurrentPage({ url }) ? 'page' : undefined}
              onClick={(e) => handleClick(e, title, dropdownTitle)}
              aria-label={ariaLabel}
              tabIndex={-1}
            >
              {linkItem}
            </a>
          </Link>
        ) : (
          <>
            <div className="c-expandable-option__wrapper">
              <div className="c-expandable-option__label">{linkItem}</div>
              <div className="c-expandable-option__arrow" />
            </div>
            <ul
              className="c-second-level-links"
              style={{
                position: 'absolute',
                left: '280px',
                top: '0',
                display: isCurrentOptionExpanded ? '' : 'none',
                width: '280px',
                maxHeight: 'calc(100vh - 90px)',
                overflowY: 'auto',
              }}
              tabIndex={-1}
            >
              {subOptions &&
                Object.entries(
                  getGroupedOptionsByCategory(
                    subOptions.filter(
                      (o): o is MenuSubOption => o.type === 'menu-option'
                    )
                  )
                ).map(([category, options]) => (
                  <div key={category}>
                    {category !== 'Undefined' ? (
                      <>
                        <span className="u-text-gray-dark u-proximanova u-font-bold">
                          {category}
                        </span>
                        <hr className="u-text-gray-light u-mt-1" />
                      </>
                    ) : null}
                    {options?.map((subOption) => (
                      <li key={toKebabCase(subOption.title)}>
                        <Link href={subOption.url}>
                          <a
                            role="link"
                            data-testid={`${toKebabCase(subOption.title)}-link`}
                            onClick={(e) =>
                              handleClick(e, subOption.title, title)
                            }
                            onKeyDown={(e) => {
                              e.stopPropagation();
                              if (subOption.url && e.key === ' ') {
                                e.preventDefault();
                                router.push(subOption.url);
                              }
                              if (e.key === 'ArrowLeft') {
                                // focus option <li>
                                (
                                  e.currentTarget.closest(
                                    '.c-expandable-option'
                                  ) as HTMLElement
                                ).focus();
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                            aria-label={subOption.ariaLabel}
                            tabIndex={isFocused ? 0 : -1}
                          >
                            {subOption.title}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </div>
                ))}
            </ul>
          </>
        )}
      </div>
    </li>
  );
};

export default ExpandableOption;
