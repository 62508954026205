import type { Filter } from '../data/filter';

export const mapGenericContentToFilter = (
  content: {
    id: string;
    name: string;
    slug: string;
  },
  aggregations
): Filter => {
  return {
    id: content.id,
    label: content.name,
    value: content.slug,
    count: aggregations.find((item) => item.key === content.slug)?.count || 0,
  };
};
