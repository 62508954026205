import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { isEqual } from 'lodash';
import Button from '../button/button';
import { useAuthContext } from '../../../auth/providers/auth-context';
import accountIcon from '../../../assets/svg/sprites/account-outline.svg';
import settingsIcon from '../../../assets/svg/sprites/settings.svg';
import { onLogin } from '../../../auth/utils/login';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { userNameSelector } from '../../../store/user/selectors';
import { SettingsLinkWrapper } from './settings-link-wrapper';
import bookmarkFilled from '../../../assets/svg/sprites/bookmark-filled.svg';
import { useShowBookmarks } from '../../hooks/use-show-bookmarks';
import toKebabCase from '../../../utils/to-kebab-case';
import TopLevelLink from './top-level-link';
import { trackEvent } from '../../../analytics/api';
import { createEducationNavigationClickEvent } from '../../../analytics/events/create-education-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { useTour } from '../../contexts/tour/use-tour';
import TourStepWrapper from '../tour/tour-step-wrapper';
import { usePersonalisedMenu } from './hooks/use-personalised-menu';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { getGroupedOptionsByCategory } from '../../../utils/get-grouped-options-by-category';

interface Props {
  expanded: boolean;
  id: string;
  toggleCallback: () => void;
  options: Array<MenuOption | SkeletonOption>;
}

const BookmarkLink: FC = () => {
  return (
    <div className="u-text-white u-pl-2">
      <Link href="/account/bookmarks">
        <a className="u-h-14 u-flex u-gap-2 u-items-center">
          <svg
            viewBox={bookmarkFilled.viewBox}
            aria-hidden="true"
            height="16"
            width="14"
          >
            <use href={bookmarkFilled} />
          </svg>
          Bookmarks
        </a>
      </Link>
    </div>
  );
};

interface OptionsListProps {
  options: Array<MenuOption | SkeletonOption>;
  currentStep: number;
  handleOptionClick: (index: number) => void;
  expandedOptionIndex: number | null;
}

interface SuboptionsListProps {
  option: MenuOption;
  back: () => void;
}

const OptionsList: FC<OptionsListProps> = ({
  options,
  currentStep,
  handleOptionClick,
  expandedOptionIndex,
}: OptionsListProps) => {
  return (
    <>
      {options.map((option, index) => {
        if (option.type === 'menu-option') {
          if (option.title === 'High School') {
            return (
              <TourStepWrapper key="tour-step-1" open={currentStep === 1}>
                <TopLevelLink
                  key={toKebabCase(option.title)}
                  url={option.url}
                  title={option.title}
                  onClickCallback={() => handleOptionClick(index)}
                  expandable={!!option.subOptions}
                  isCurrentOptionExpanded={index === expandedOptionIndex}
                  isNew={!!option.isNew}
                />
              </TourStepWrapper>
            );
          }
          if (option.title === 'Browse') {
            return (
              <TourStepWrapper key="tour-step-2" open={currentStep === 2}>
                <TopLevelLink
                  key={toKebabCase(option.title)}
                  url={option.url}
                  title={option.title}
                  onClickCallback={() => handleOptionClick(index)}
                  expandable={!!option.subOptions}
                  isCurrentOptionExpanded={index === expandedOptionIndex}
                  isNew={!!option.isNew}
                />
              </TourStepWrapper>
            );
          }
          return (
            <TopLevelLink
              key={toKebabCase(option.title)}
              url={option.url}
              title={option.title}
              onClickCallback={() => handleOptionClick(index)}
              expandable={!!option.subOptions}
              isCurrentOptionExpanded={index === expandedOptionIndex}
              isNew={!!option.isNew}
            />
          );
        }
        return (
          <div
            key={option.id}
            className="u-flex u-p-1 u-m-1 u-items-center"
            style={{ height: '54px' }}
          >
            <span className="c-skeleton type-h2 u-h-full u-w-full">
              Loading
            </span>
          </div>
        );
      })}
    </>
  );
};

const CategoryWithSubOptions: FC<{
  category: string;
  dropdownTitle: string;
  subOptions: MenuSubOption[];
}> = ({ category, dropdownTitle, subOptions }) => {
  return (
    <>
      {category !== 'Undefined' ? (
        <>
          <hr className="u-text-gray-light u-mb-3" />
          <span className="u-text-gray-dark u-proximanova u-font-bold u-mb-4">
            {category}
          </span>
        </>
      ) : null}
      {subOptions?.map((subOption) => (
        <TopLevelLink
          key={toKebabCase(subOption.title)}
          url={subOption.url}
          title={subOption.title}
          onClickCallback={subOption.onClickCallback}
          expandable={false}
          isCurrentOptionExpanded={false}
          dropdownTitle={dropdownTitle}
        />
      ))}
    </>
  );
};

const SuboptionsList: FC<SuboptionsListProps> = ({
  option,
  back,
}: SuboptionsListProps) => {
  return (
    <div
      style={{
        height: 'calc(100vh - 232px)', // size of the header plus footer
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <li>
        <a
          role="button"
          tabIndex={0}
          onClick={back}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              back();
            }
          }}
        >
          <button
            className="c-slide-out-menu__back-button u-font-bold u-w-full u-text-left"
            type="button"
            tabIndex={-1}
          >
            {option.title}
          </button>
        </a>
      </li>
      {option.subOptions &&
        Object.entries(
          getGroupedOptionsByCategory(
            option.subOptions.filter(
              (o): o is MenuSubOption => o.type === 'menu-option'
            )
          )
        ).map(([category, subOptions]) => (
          <CategoryWithSubOptions
            key={category}
            category={category}
            subOptions={subOptions}
            dropdownTitle={option.title}
          />
        ))}
    </div>
  );
};

const MobileMenu: FC<Props> = ({
  expanded,
  id,
  toggleCallback,
  options,
}: Props) => {
  const { appAuthService, appAuthState } = useAuthContext();
  const showBookmarks = useShowBookmarks();
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();
  const onLogout = (): void => appAuthService?.logout();
  const userName = useTypedSelector(userNameSelector);
  const { isUS } = usePersonalisedMenu();

  const breakpoint = useMediaBreakpoint();

  const mobileMenuRef = useRef<HTMLDivElement | null>(null);

  const steps = [
    {
      title: 'Our site navigation has changed',
      text: 'Our site navigation has been updated to help you more easily find content tailored to your needs as an educator.',
    },
    {
      title: 'Browse content by stage and subject',
      text: 'Access curated units of content and lesson plans for Middle School and High School.',
    },
    {
      title: 'The previous navigation is located here',
      text: "You can still access the previous navigation options to browse 'Titles', 'People', etc. across all subjects and grade levels.",
    },
  ];

  const { currentStep, started, setUpTour, startTour } = useTour();

  const prevOptionsRef = useRef<(MenuOption | SkeletonOption)[]>([]);

  useEffect(() => {
    if (!isEqual(options, prevOptionsRef.current)) {
      if (
        options.length < 3 ||
        options.some((option) => option.type === 'skeleton-option')
      ) {
        return;
      }

      if (!started && isUS) {
        setUpTour(steps, pageTitle, pageType);
        startTour();
      }
    }

    prevOptionsRef.current = options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const closeMenuButton = (
    <div className="c-slide-out-menu__close">
      <button
        type="button"
        className="c-square-button c-square-button--light-ish c-square-button--close"
        aria-label="Close Menu"
        onClick={toggleCallback}
      >
        <div />
        <div />
      </button>
    </div>
  );

  const authenticatedFooter = (
    <>
      <div className="u-px-6 u-py-4 u-flex u-items-center u-flex-wrap u--m-2">
        {showBookmarks && <BookmarkLink />}
        <SettingsLinkWrapper>
          <div className="u-flex u-h-full u-items-center u-mx-2 u-my-1 u-flex-grow-max">
            <svg
              className="u-h-12 u-w-12 u--m-2 u-mr-1 u-text-white u-flex-shrink-0"
              viewBox={accountIcon.viewBox}
              aria-hidden="true"
            >
              <use href={accountIcon} />
            </svg>
            <div className="c-user-info">
              <div className="c-user-info__text">
                <div
                  className="c-user-info__name u-overflow-wrap-anywhere"
                  data-matomo-mask
                >
                  {userName}
                </div>
                <div className="c-user-info__role u-text-green" />
              </div>
            </div>
          </div>
        </SettingsLinkWrapper>

        <Button
          variant="secondary-light"
          size="md"
          handleButtonClick={onLogout}
          className="u-m-2 u-flex-grow"
        >
          Sign out
        </Button>
      </div>

      <div className="u-px-6 u-py-6 u-border-top u-sofiapro u-hidden">
        <span className="u-mr-1">Preferences</span>
        <button
          type="button"
          className="u-inline-flex u-items-end c-square-button c-square-button--light"
        >
          <span className="u-mr-1 type-inline-link">All subjects</span>
          <svg
            className="u-h-5 u-w-5"
            viewBox={settingsIcon.viewBox}
            aria-hidden="true"
          >
            <use href={settingsIcon} />
          </svg>
        </button>
      </div>
    </>
  );

  const unauthenticatedFooter = (
    <div className="u-px-6 u-py-4 u-flex u-items-center">
      <Button
        variant="secondary-light"
        size="md"
        className="u-w-full"
        handleButtonClick={() => onLogin(appAuthService!, router)}
      >
        Sign in
      </Button>
    </div>
  );

  const [expandedOptionIndex, setExpandedOptionIndex] = useState<number | null>(
    null
  );

  const handleOptionClick = (index: number) => {
    if (!options) {
      return;
    }
    const selectedOption = options[index];
    if (selectedOption.type === 'menu-option' && selectedOption.subOptions) {
      if (expandedOptionIndex === index) {
        return;
      }
      trackEvent(
        createEducationNavigationClickEvent(
          Action.EDUCATION_NAVIGATION_BROWSE,
          selectedOption.title,
          pageType,
          pageTitle,
          router.asPath
        )
      );
      setExpandedOptionIndex((prevIndex) => {
        return prevIndex === index ? null : index;
      });
    } else {
      // close the expanded dropdown
      setExpandedOptionIndex(null);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(expanded || currentStep > 0);
  }, [currentStep, expanded]);

  if (breakpoint > MediaBreakpoints.SM) {
    return null;
  }

  return (
    <div
      className="c-slide-out-menu c-slide-out-menu--left"
      aria-hidden={!isVisible}
      ref={mobileMenuRef}
      id={id}
      data-testid="mobile-menu"
    >
      <div className="c-slide-out-menu__panel">
        <div className="c-slide-out-menu__panel__main">
          <nav style={{ height: '100%' }}>
            {options && (
              <div className={`u-flex `}>
                <TourStepWrapper key="tour-step-0" open={currentStep === 0} />

                <ul
                  className="c-top-level-links c-top-level-links--mobile u-flex-1"
                  data-testid="top-level-links"
                >
                  {expandedOptionIndex === null ? (
                    <OptionsList
                      options={options}
                      currentStep={currentStep}
                      handleOptionClick={handleOptionClick}
                      expandedOptionIndex={expandedOptionIndex}
                    />
                  ) : (
                    <SuboptionsList
                      option={options[expandedOptionIndex] as MenuOption}
                      back={() => setExpandedOptionIndex(null)}
                    />
                  )}
                </ul>
              </div>
            )}
          </nav>
        </div>

        <div className="c-slide-out-menu__panel__footer">
          {appAuthState?.isAuthenticated
            ? authenticatedFooter
            : unauthenticatedFooter}
        </div>
      </div>

      {closeMenuButton}
    </div>
  );
};
export default MobileMenu;
