/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo } from 'react';
import type { ReactElement } from 'react';

import { useRouter } from 'next/router';
import Button from '../button/button';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { Action } from '../../../analytics/data/events';

const QuickStartResourcesButton = (): ReactElement => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();

  const buttonClicked = useMemo(
    () =>
      createNavigationClickEvent(
        Action.FOOTER,
        'quick-start-resources',
        pageType,
        pageTitle,
        router.asPath
      ),
    [pageType, pageTitle, router.asPath]
  );

  return (
    <Button
      variant="secondary-light"
      size="lg"
      href="https://www.digitaltheatreplus.com/quick-start-resources"
      analyticsTrackedEvent={buttonClicked}
    >
      Quick start resources
    </Button>
  );
};

export default QuickStartResourcesButton;
