module.exports = {
  current: {
    DEFAULT: 'currentColor',
  },
  white: '#ffffff',
  black: '#000000',
  midnight: {
    light: '#19232e',
    DEFAULT: '#121921',
  },
  gray: {
    lighter: '#f8f7f7',
    light: '#e7e6e6',
    DEFAULT: '#cfd3d8',
    dark: '#7C838D',
    darker: '#717781',
    darkest: '#575c5f',
  },
  pink: {
    DEFAULT: '#ff4f88',
    dark: '#d43c6d', // 4.5:1 on white
  },
  red: {
    DEFAULT: '#fe1806', // 4.5:1 on midnight-DEFAULT
    dark: '#e40e00', // 4.5:1 on gray-lighter
    darker: '#b60b00',
  },
  yellow: {
    DEFAULT: '#fedb20',
  },
  blue: {
    lightest: '#d7dbe0',
    lighter: '#a2b0be',
    light: '#4a6583',
    DEFAULT: '#354962',
    dark: '#2d3e50',
  },
  green: {
    DEFAULT: '#01ffcd',
    dim: '#13EDC2',
    dark: '#01e2b6',
    darker: '#00c19c',
    darkest: '#008260',
  },
};
