import type { EducationLevel } from '../../../data/education-level';

export const mapSdkToEducationLevel = (
  contentfulSdkEducationlevel: any
): EducationLevel => {
  const {
    sys: { id },
    fields: {
      name,
      countryCode,
      level,
      stage,
      year,
      entryAge,
      exitAge,
      alternativeLevels,
      alternativeStages,
    },
  } = contentfulSdkEducationlevel;

  return {
    id,
    name,
    slug: name, // We use slug because a lot of our code works easier if we have it but name is unique
    countryCode,
    level,
    stage,
    yearOrGrade: year,
    entryAge,
    ...(exitAge !== undefined && { exitAge }),
    alternativeLevels: alternativeLevels ?? [],
    alternativeStages: alternativeStages ?? [],
  };
};
