import { useRouter } from 'next/router';
import { useMemo } from 'react';
import parseFilterValues from '../../utils/parse-filter-values';
import { parseRouterQuery } from '../../utils/parse-router-query';
import { CONTENTFUL_SORT } from '../api/adapters/types';

const parseSortDirectionFromRouter = (sort?: string | string[]) => {
  const parsedSort = parseRouterQuery(sort);

  if (parsedSort === CONTENTFUL_SORT.DESCENDING) {
    return CONTENTFUL_SORT.DESCENDING;
  }
  if (parsedSort === CONTENTFUL_SORT.ASCENDING) {
    return CONTENTFUL_SORT.ASCENDING;
  }
  if (parsedSort === CONTENTFUL_SORT.RELEVANCE) {
    return CONTENTFUL_SORT.RELEVANCE;
  }
  if (parsedSort === CONTENTFUL_SORT.NEWEST) {
    return CONTENTFUL_SORT.NEWEST;
  }
  if (parsedSort === CONTENTFUL_SORT.OLDEST) {
    return CONTENTFUL_SORT.OLDEST;
  }
  if (parsedSort === undefined) {
    return undefined;
  }

  return undefined;
};

const parsePageNumberFromRouter = (page?: string | string[]) => {
  const parsedPage = parseRouterQuery(page);

  return parsedPage === undefined ? 1 : parseInt(parsedPage, 10);
};

export const useDirectoryParams = (defaultSort = CONTENTFUL_SORT.ASCENDING) => {
  const router = useRouter();

  const {
    page: pageQuery,
    sort_by: sortQuery,
    genre: genresQuery,
    course: coursesQuery,
    form: formsQuery,
    theme: themesQuery,
    role: rolesQuery,
    type: typesQuery,
    subType: subTypesQuery,
    company: companiesQuery,
    period: periodsQuery,
    englishSkill: englishSkillsQuery,
    theatreSkill: theatreSkillsQuery,
    level: levelsQuery,
    q: searchTerm,
  } = router.query;

  return useMemo(() => {
    const page = parsePageNumberFromRouter(pageQuery);
    const sortDirection =
      parseSortDirectionFromRouter(sortQuery) || defaultSort;

    const selectedFilters = {
      genres: parseFilterValues(genresQuery),
      courses: parseFilterValues(coursesQuery),
      forms: parseFilterValues(formsQuery),
      themes: parseFilterValues(themesQuery),
      roles: parseFilterValues(rolesQuery),
      types: parseFilterValues(typesQuery),
      subTypes: parseFilterValues(subTypesQuery),
      companies: parseFilterValues(companiesQuery),
      periods: parseFilterValues(periodsQuery),
      englishSkills: parseFilterValues(englishSkillsQuery),
      theatreSkills: parseFilterValues(theatreSkillsQuery),
      levels: parseFilterValues(levelsQuery),
    };

    return {
      page,
      sortDirection,
      selectedFilters,
      searchTerm,
    };
    // defaultSort should not be included in the dependency array as it does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageQuery,
    sortQuery,
    coursesQuery,
    formsQuery,
    genresQuery,
    themesQuery,
    rolesQuery,
    typesQuery,
    subTypesQuery,
    companiesQuery,
    periodsQuery,
    englishSkillsQuery,
    theatreSkillsQuery,
    levelsQuery,
    searchTerm,
  ]);
};
