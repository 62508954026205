import toKebabCase from '../../utils/to-kebab-case';
import { Action, Category, CheckboxClicked } from '../data/events';

export const createCheckboxClickEvent = (
  pageType: string,
  pageTitle: string,
  routerAsPath: string,
  defaultChecked: boolean | undefined,
  name: string | undefined,
  value: string | undefined
): CheckboxClicked => ({
  category: Category.FILTER,
  action: defaultChecked ? Action.FILTER_REMOVE : Action.FILTER_SELECT,
  name: `${name?.toLowerCase()}-${value && toKebabCase(value)}`,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl: routerAsPath,
});
