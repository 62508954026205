import { mapGqlToImage } from '../../../../../shared/api/adapters/map-to-image';
import type { Genre } from '../../../../data/genre';

export function mapToGenre(contentfulGenre: any): Genre {
  return {
    id: contentfulGenre.sys.id,
    name: contentfulGenre.name,
    slug: contentfulGenre.slug,
    shortIntroduction: '',
    longIntroduction: null,
    image: mapGqlToImage(contentfulGenre.mainImage),
    totalTitles: contentfulGenre.linkedFrom.titleCollection.total,
  };
}
