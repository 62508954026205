/* eslint-disable prefer-destructuring */
import type { ContentfulClientApi } from 'contentful';
import { mapSdkResponseToTitle } from './mappers/map-sdk-to-title';
import type { GetTitle } from '../types';
import { CONTENTFUL_CONTENT_TYPE } from '../../../../shared/api/adapters/types';
import type { Title } from '../../../data/title';
import { ConsoleLogger } from '../../../../shared/services/logging/console-logger';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';
import { getUnauthorisedRightsAgreementIdsForCountry } from '../../../../respositories/isomorphic/contentful-repository';

export const newGetTitleFromContentfulViaSdk = (
  contentfulClient: ContentfulClientApi
): GetTitle => {
  const consoleLogger = new ConsoleLogger();
  return async (slug: string): Promise<Title> => {
    const response = await getEntries(
      {
        content_type: 'title',
        'fields.slug': slug,
      },
      contentfulClient
    );

    if (response.items.length === 0) {
      consoleLogger.error('No results returned');
    }

    return mapSdkResponseToTitle(response.items[0]);
  };
};

export function newGetTotalNumberOfTitles(client: ContentfulClientApi) {
  return async (): Promise<number> => {
    const { total } = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.TITLE,
        limit: 0,
      },
      client
    );

    return total;
  };
}

export const newGetTitleNameFromContentfulViaSdk = (
  contentfulClient: ContentfulClientApi
): GetContentNameBySlug => {
  const consoleLogger = new ConsoleLogger();
  return async (slug: string, filters: Filters): Promise<string> => {
    const unauthorisedRightsAgreementIds =
      await getUnauthorisedRightsAgreementIdsForCountry(
        filters.organisationCountry
      );
    const response = await getEntries(
      {
        content_type: 'title',
        'fields.slug': slug,
        'fields.relatedRightsAgreement.sys.id[nin]':
          unauthorisedRightsAgreementIds,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        select: 'fields.name',
      },
      contentfulClient
    );

    if (response.items.length === 0) {
      consoleLogger.error('No results returned');
    }

    return response.items[0].fields.name;
  };
};

export const getNumberOfCuratedTitlesAssociatedWithContent = async (
  content: { id: string },
  associatedContentType: string
): Promise<number> => {
  const titlesWithContent = await getEntries(
    // eslint-disable-next-line camelcase
    {
      content_type: 'title',
      [associatedContentType]: content.id,
      'fields.curated': true,
      limit: 0,
    }
  );
  return titlesWithContent.total;
};
