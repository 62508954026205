import { cacheSkill } from '../../cache';
import { userContentForMultipleSlugsFactory } from '../../content/hooks/use-multiple-slugs';
import { getSkills } from '../api/frontend/get-skills';
import type { SkillCardData } from '../data/Skill';

export const useSkillsForMultipleSlugs =
  userContentForMultipleSlugsFactory<SkillCardData>(
    getSkills,
    'skill',
    cacheSkill
  );
