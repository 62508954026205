import type { Document } from '@contentful/rich-text-types';
import type { Image } from '../../shared/data/image';

export enum ThemeEnum {
  THEME = 'Theme',
  FORM = 'Form',
  COURSE = 'Course',
}

export interface Theme {
  id: string;
  name: string;
  slug: string;
  shortIntroduction: string;
  longIntroduction: Document | null;
  totalTitles: number;
  image: Image | null;
  themeType: ThemeEnum;
}
