import { cacheGenre, cacheGuide, cacheInterview, cacheLessonPlan, cachePerson, cacheProduction, cacheTheme, cacheTitle, cacheWorkshop } from ".";
import type { Content } from '../content/data/content';

export const cacheContent = (content: Content) => {
  switch (content.type) {
    case 'production':
      cacheProduction(content.value);
      break;
    case 'interview':
      cacheInterview(content.value);
      break;
    case 'workshop':
      cacheWorkshop(content.value);
      break;
    case 'guide':
      cacheGuide(content.value);
      break;
    case 'lessonPlan':
      cacheLessonPlan(content.value);
      break;
    case 'title':
      cacheTitle(content.value);
      break;
    case 'person':
      cachePerson(content.value);
      break;
    case 'genre':
      cacheGenre(content.value);
      break;
    case 'theme':
      cacheTheme(content.value);
      break;
    default:
      break;
  }
};
