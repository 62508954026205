import {
  newGetGenreFromContentful,
  getGenresWithCuratedTitlesFromContentful,
  newGetGenreNameFromContentful,
  newGetGenresForMultipleSlugsFromContentful,
  newGetAllGenresFromContentful,
} from './adapters/contentful-genre-content-api';
import { getContentfulClient } from '../../../config/contentful-config';
import type {
  GetGenre,
  GetGenres,
  GetGenresForMultipleSlugs,
  GetGenresWithCuratedTitles,
} from './types';
import type { GetContentNameBySlug } from '../../../auth/utils/authentication';

export const getGenre: GetGenre = newGetGenreFromContentful(
  getContentfulClient()
);

export const getGenreName: GetContentNameBySlug = newGetGenreNameFromContentful(
  getContentfulClient()
);

export const getGenres: GetGenres = newGetAllGenresFromContentful(
  getContentfulClient()
);

export const getGenresWithCuratedTitles: GetGenresWithCuratedTitles =
  getGenresWithCuratedTitlesFromContentful;

export const getGenresForMultipleSlugs: GetGenresForMultipleSlugs =
  newGetGenresForMultipleSlugsFromContentful(getContentfulClient());
