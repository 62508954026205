import toNaturalOrdering from '../../../../../utils/to-natural-ordering';
import type { Theme } from '../../../../data/theme';
import type { ThemeType } from '../../../../data/theme-type';

const mapToThemeType = (themes: Theme[]): ThemeType[] => {
  const themeTypes = [...new Set(themes.map(({ themeType }) => themeType))];

  return toNaturalOrdering(
    themeTypes.map((themeType) => {
      const themesInThemeType = themes.filter(
        (theme) => theme.themeType === themeType
      );

      return {
        name: themeType,
        slug: themeType.toLowerCase(),
        themes: themesInThemeType,
        total: themesInThemeType.length,
      };
    })
  );
};

export default mapToThemeType;
