import { useRouter } from 'next/router';
import { useState, useRef, useEffect, useMemo, ReactNode } from 'react';
import type { FC, KeyboardEvent } from 'react';
import { trackEvent } from '../../../analytics/api';
import type { Category } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import tickIcon from '../../../assets/svg/sprites/tick.svg';
import { createFilterSelectClickEvent } from '../../../analytics/events/create-filter-select-click-event';
import { createFilterSelectAllEvent } from '../../../analytics/events/create-filter-select-all-event';
import { setChildInteractivity } from '../../../utils/dom-interactivity';
import { filterSelect } from '../../../utils/filter-checkbox-click-handler';

export interface Option {
  value: string;
  element: ReactNode;
}

interface Props {
  id: string;
  title: string;
  options: Option[];
  className?: string;
  selectedOption?: number;
  expanded?: boolean;
  listboxClassName?: string;
  category?: Category.SORT | Category.SECTIONS | Category.RESULTS;
  align?: 'left' | 'right';
  inBanner?: boolean;
  isSelected?: boolean;
  selectAllFilters?: () => void;
  clearAllFilters?: () => void;
  selectChildren?: (parent: string) => void;
}

const isCollapseKey = (e): boolean => e.key === 'Enter' || e.key === ' ';
const isExpandKey = (e): boolean =>
  e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown';
const isCancelKey = (e): boolean => e.key === 'Escape';
const isAcceptKey = (e): boolean => e.key === 'Enter' || e.key === ' ';
const isNavigateDownKey = (e): boolean => e.key === 'ArrowDown';
const isNavigateUpKey = (e): boolean => e.key === 'ArrowUp';
const isTabKey = (e): boolean => e.key === 'Tab' && !e.shiftKey;
const isShiftTabKey = (e): boolean => e.key === 'Tab' && e.shiftKey;

function mapOptionIndex(index: number, options: Option[]): string {
  if (index === 0) {
    return 'select-all';
  }
  if (index === 1) {
    return 'clear-all';
  }
  return options[index - 2] ? options[index - 2].value : '0';
}

const FilterSelect: FC<Props> = ({
  id,
  title,
  options,
  className,
  selectedOption = 0,
  expanded = false,
  listboxClassName,
  category,
  align = 'left',
  inBanner = false,
  isSelected = false,
  selectAllFilters = () => {},
  clearAllFilters = () => {},
  selectChildren = () => {},
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [activeDescendantOptionIndex, setActiveDescendantOptionIndex] =
    useState(selectedOption);
  const button = useRef<HTMLButtonElement>(null);
  const listbox = useRef<HTMLUListElement>(null);
  const scrollBox = useRef<HTMLDivElement>(null);
  const { pageType, pageTitle } = useAnalyticsContext();
  const router = useRouter();

  useEffect(() => {
    setActiveDescendantOptionIndex(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    // Collapsed → expanded
    if (isExpanded && listbox && listbox.current) {
      listbox.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const trackExpandEvents = useMemo(() => {
    return (expand) =>
      trackEvent(
        createFilterSelectClickEvent({
          pageType,
          pageTitle,
          expanded: expand,
          title,
          routerAsPath: router.asPath,
        })
      );
  }, [pageTitle, pageType, title, router.asPath]);

  function handleButtonClick(): void {
    trackExpandEvents(isExpanded);
    setIsExpanded(!isExpanded);
  }

  function handleButtonKeyDown(e: KeyboardEvent): void {
    if (isCollapseKey(e) && isExpanded) {
      trackExpandEvents(isExpanded);
      e.preventDefault();
      setIsExpanded(false);
    }

    if (isExpandKey(e) && !isExpanded) {
      trackExpandEvents(isExpanded);
      e.preventDefault();
      setIsExpanded(true);
    }
  }

  function handleButtonKeyUp(e: KeyboardEvent): void {
    if (isCollapseKey(e)) {
      e.preventDefault();
    }
  }

  function handleListboxKeyDown(e: KeyboardEvent): void {
    if (isAcceptKey(e) && button.current) {
      if (activeDescendantOptionIndex >= 2) {
        // Our router drives our checkboxes, so use the same checkbox handler as a checkbox to drive change
        const checkBoxes =
          e.currentTarget.querySelectorAll<HTMLInputElement>('input');
        const input = checkBoxes[activeDescendantOptionIndex - 2];
        const isParent = input.getAttribute('data-parent');
        if (isParent) {
          if (input.checked) {
            selectChildren(input.value);
          } else {
            selectChildren(input.value);
          }
        } else {
          filterSelect({
            name: input.name,
            value: input.value,
            checked: !input.checked,
            router,
          });
        }
        e.preventDefault();
      } else if (activeDescendantOptionIndex === 1) {
        trackEvent(
          createFilterSelectAllEvent({
            pageTitle,
            pageType,
            title,
            routerAsPath: router.asPath,
            clearing: true,
          })
        );
        clearAllFilters();
        e.preventDefault();
      } else if (activeDescendantOptionIndex === 0) {
        trackEvent(
          createFilterSelectAllEvent({
            pageTitle,
            pageType,
            title,
            routerAsPath: router.asPath,
          })
        );
        selectAllFilters();
        e.preventDefault();
      }
    }

    if (isCancelKey(e) && button.current) {
      trackExpandEvents(isExpanded);
      setIsExpanded(false);
      button.current.focus();
    }

    if (isNavigateDownKey(e) || isTabKey(e)) {
      e.preventDefault();

      if (activeDescendantOptionIndex < options.length + 1) {
        setActiveDescendantOptionIndex(activeDescendantOptionIndex + 1);
        const interactiveElements =
          e.currentTarget.querySelectorAll<HTMLInputElement>('input, button');
        const element = interactiveElements[activeDescendantOptionIndex];
        const distance =
          element.getBoundingClientRect().top -
          scrollBox.current!.getBoundingClientRect().top;
        if (
          distance >
          scrollBox.current!.getBoundingClientRect().height - 200
        ) {
          scrollBox.current?.scrollTo({
            top:
              distance -
              scrollBox.current!.getBoundingClientRect().height +
              200,
          });
        }
      }

      if (
        isTabKey(e) &&
        activeDescendantOptionIndex === options.length + 1 &&
        button.current
      ) {
        setIsExpanded(false);
        button.current.focus();
      }
    }

    if (isNavigateUpKey(e) || isShiftTabKey(e)) {
      e.preventDefault();

      if (activeDescendantOptionIndex > 0) {
        setActiveDescendantOptionIndex(activeDescendantOptionIndex - 1);
        const interactiveElements =
          e.currentTarget.querySelectorAll<HTMLInputElement>('input, button');
        const element = interactiveElements[activeDescendantOptionIndex];
        const distance =
          element.getBoundingClientRect().top -
          scrollBox.current!.getBoundingClientRect().top;
        if (
          distance <
          scrollBox.current!.scrollHeight -
            scrollBox.current!.getBoundingClientRect().height +
            200
        ) {
          scrollBox.current!.scrollTo({
            top: distance - 200,
          });
        }
      }

      if (
        isShiftTabKey(e) &&
        activeDescendantOptionIndex === 0 &&
        button.current
      ) {
        setIsExpanded(false);
        button.current.focus();
      }
    }
  }

  function handleListboxKeyUp(e: KeyboardEvent): void {
    if (isCollapseKey(e) && button.current) {
      e.preventDefault();
    }
  }

  function handleListboxBlur(e): void {
    if (e.relatedTarget === button.current) {
      return;
    }

    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      trackExpandEvents(isExpanded);
      setIsExpanded(false);
    }
  }

  const listItems = options
    .filter((o) => o.value !== 'placeholder')
    .map((item) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <li
        id={`${id}-option-${item.value}`}
        key={item.value}
        role="option"
        aria-selected={
          activeDescendantOptionIndex - 2 ===
          options.findIndex((o) => o.value === item.value)
        }
        onClick={() => {
          if (category) {
            trackExpandEvents(isExpanded);
          }
        }}
      >
        {item.element}
      </li>
    ));

  useEffect(() => {
    if (options.length > 0) {
      setChildInteractivity(listbox.current as HTMLElement, isExpanded);
    }
  }, [isExpanded, options.length]);

  let buttonBorderRadius = '';
  if (inBanner) {
    buttonBorderRadius = isExpanded ? '0.25rem 0 0 0' : '0.25rem 0 0 0.25rem';
  } else {
    buttonBorderRadius = isExpanded ? '0.25rem 0.25rem 0 0' : '0.25rem';
  }

  return (
    <div
      style={{
        background: 'white',
        borderRadius: '0.25rem 0 0 0.25rem',
        cursor: options.length === 0 ? 'no-drop' : '',
      }}
    >
      <div
        className={`c-filter-select${className ? ` ${className}` : ''} ${
          !inBanner
            ? 'u-border-default u-border-gray-light'
            : 'c-filter-select--in-banner'
        } ${options.length === 0 ? 'c-filter-select--disabled' : ''} ${
          isSelected ? 'c-filter-select--selected' : ''
        }`}
        style={{
          borderRadius: buttonBorderRadius,
        }}
        id={id}
      >
        <button
          id={`${id}-button`}
          data-testid="custom-select-button"
          type="button"
          onClick={handleButtonClick}
          onKeyDown={handleButtonKeyDown}
          onKeyUp={handleButtonKeyUp}
          className="c-filter-select__button"
          aria-expanded={isExpanded}
          aria-controls={`${id}-listbox`}
          ref={button}
          style={{
            borderRadius: buttonBorderRadius,
          }}
        >
          {isSelected && (
            <svg
              viewBox={tickIcon.viewBox}
              height="28"
              width="28"
              className="u-mr-1"
            >
              <use href={tickIcon} />
            </svg>
          )}
          <span>{title}</span>
        </button>

        <ul
          id={`${id}-listbox`}
          data-testid="custom-select-listbox"
          className={`c-filter-select__listbox${
            listboxClassName ? ` ${listboxClassName}` : ''
          } ${
            align === 'left'
              ? 'c-filter-select__listbox--left'
              : 'c-filter-select__listbox--right'
          }`}
          aria-hidden={!isExpanded}
          role="listbox"
          onKeyDown={handleListboxKeyDown}
          onKeyUp={handleListboxKeyUp}
          onClick={() => {}}
          onBlur={handleListboxBlur}
          ref={listbox}
          aria-label={title}
          tabIndex={isExpanded ? 0 : -1}
          aria-activedescendant={`${id}-option-${mapOptionIndex(
            activeDescendantOptionIndex,
            options
          )}`}
        >
          <div
            style={{ overflowY: 'auto', maxHeight: '50vh' }}
            tabIndex={-1}
            ref={scrollBox}
          >
            <div className="c-filter-select__select-all">
              <button
                id={`${id}-option-select-all`}
                aria-label="Select all"
                data-testid="filter-select-select-all"
                className="u-underline"
                type="button"
                role="option"
                aria-selected={activeDescendantOptionIndex === 0}
                onClick={() => {
                  trackEvent(
                    createFilterSelectAllEvent({
                      pageTitle,
                      pageType,
                      title,
                      routerAsPath: router.asPath,
                    })
                  );
                  selectAllFilters();
                }}
                tabIndex={isExpanded ? 0 : -1}
                style={{ fontSize: '14px' }}
              >
                Select all
              </button>
              <button
                id={`${id}-option-clear-all`}
                type="button"
                aria-label="Clear all"
                data-testid="filter-select-clear-all"
                role="option"
                aria-selected={activeDescendantOptionIndex === 1}
                className="u-underline"
                onClick={() => {
                  trackEvent(
                    createFilterSelectAllEvent({
                      pageTitle,
                      pageType,
                      title,
                      routerAsPath: router.asPath,
                      clearing: true,
                    })
                  );
                  clearAllFilters();
                }}
                tabIndex={isExpanded ? 0 : -1}
                style={{ fontSize: '14px' }}
              >
                Clear all
              </button>
            </div>
            {listItems}
          </div>
        </ul>
      </div>
    </div>
  );
};

export default FilterSelect;
