import Link from 'next/link';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { trackEvent } from '../../../analytics/api';
import dtpLogo from '../../../assets/svg/sprites/dtp.svg';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';

interface Props {
  onHover?: () => void;
  onLeave?: () => void;
}

const HeaderLogo = ({ onHover, onLeave }: Props) => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();

  const logoClickedEvent = useMemo(
    () =>
      createNavigationClickEvent(
        Action.HEADER,
        'home-logo',
        pageType,
        pageTitle,
        router.asPath
      ),
    [pageType, pageTitle, router.asPath]
  );

  return (
    <div
      className="c-header__section c-header__section--border-r u-flex-shrink-0"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <Link href="/">
        <a
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
          role="link"
          tabIndex={0}
          className="c-header-button c-header-button--home u-focus-visible-outline"
          data-testid="header-logo-link-from-sm"
          aria-label="DT+ Home"
          title="DT+ Home"
          onClick={() => {
            trackEvent(logoClickedEvent);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              trackEvent(logoClickedEvent);
            }
          }}
        >
          <svg
            className="u-w-12 u-h-5 u--mb-px"
            viewBox={dtpLogo.viewBox}
            aria-hidden="true"
          >
            <use href={dtpLogo} />
          </svg>
        </a>
      </Link>
    </div>
  );
};

export default HeaderLogo;
