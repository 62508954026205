import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import type { Collection } from '../../../../data/collection';

export function mapSdkResponseToCollection(
  contentfulCollection: any,
  isUs?: boolean
): Collection {
  const {
    sys: { id },
    fields: {
      name,
      slug,
      type,
      usSubjectName,
      mainImage,
      shortIntroduction,
      longIntroduction,
      relatedContent,
    },
  } = contentfulCollection;

  const chosenName = isUs && type === 'subject' ? usSubjectName : name;

  return {
    name: chosenName,
    slug,
    type,
    id,
    image: mapSdkToImage(mainImage),
    shortIntroduction: shortIntroduction ?? null,
    longIntroduction: longIntroduction ?? null,
    relatedContent: relatedContent
      ? relatedContent.map(({ fields, sys }) => ({
          slug: fields.slug,
          id: sys.id,
          name: fields.name,
          type: sys.contentType.sys.id,
        }))
      : [],
  };
}
