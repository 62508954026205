import { cacheEducationLevel } from "../../cache";
import { userContentForMultipleSlugsFactory } from "../../content/hooks/use-multiple-slugs";
import { getEducationLevelsForMultipleNames } from "../api";
import type { EducationLevel } from "../data/education-level";

export const useEducationLevelsForMultipleNames =
  userContentForMultipleSlugsFactory<EducationLevel>(
    getEducationLevelsForMultipleNames,
    'educationLevel',
    cacheEducationLevel
  );