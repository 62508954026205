import React, { ReactNode, useCallback, useMemo } from 'react';
import pluralize from 'pluralize';
import { nanoid } from '@reduxjs/toolkit';
import type { Filter, FilterTreeNode } from '../../data/filter';
import FilterSelect from '../form/filter-select';
import groupFiltersByParent from '../../utils/group-filters-by-parent';
import Checkbox from '../form/checkbox';
import { buildFilterTree } from '../../utils/build-filter-tree';
import { useAppDispatch } from '../../../store/hooks/use-app-dispatch';
import {
  removeAllSearchQueries,
  removeSingleSearchQuery,
  setSearchQuery,
  setSingleSearchQuery,
} from '../../../store/search/search.slice';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';

export interface Option {
  value: string;
  element: ReactNode;
}

interface Props {
  filters: Array<Filter>;
}

const checkboxClickHandler = (e, dispatch): void => {
  e.persist();
  const { name, value, checked } = e.target;

  if (checked) {
    dispatch(setSingleSearchQuery({ key: pluralize(name), value }));
  } else {
    dispatch(removeSingleSearchQuery({ key: pluralize(name), value }));
  }
};

const FilterSelectForResourcesWithoutUrlQueryParams: React.FC<Props> = ({
  filters,
}) => {
  const filterSelectName = 'subType';
  const [selectedParents, setSelectedParents] = React.useState<string[]>([]);
  const [indeterminateParents, setIndeterminateParents] = React.useState<
    string[]
  >([]);

  const dispatch = useAppDispatch();

  const selectedTypeFilters = useTypedSelector(
    (state) => state.search.searchQuery.types
  );
  const selectedSubtypeFilters = useTypedSelector(
    (state) => state.search.searchQuery.subTypes
  );

  const selectedFilters = useMemo(
    () => [...selectedTypeFilters, ...selectedSubtypeFilters],
    [selectedSubtypeFilters, selectedTypeFilters]
  );

  const salt = useMemo(() => {
    return nanoid(8);
  }, []);

  const filtersGroupedByParents = React.useMemo(
    () => groupFiltersByParent(filters.filter((f) => 'parent' in f)),
    [filters]
  );

  const filterTree: FilterTreeNode[] = useMemo(() => {
    return buildFilterTree(filters);
  }, [filters]);

  const isSelected =
    selectedFilters.length > 0 ||
    selectedParents.some((parent) => parent in filtersGroupedByParents);

  const selectAllFilters = () => {
    const types: string[] = [];
    const subTypes: string[] = [];

    filterTree.forEach((node) => {
      if ('children' in node) {
        node.children?.forEach((filter) => {
          if (filter.count > 0) {
            subTypes.push(filter.value);
          }
        });
      } else if (node.count > 0) {
        types.push(node.value);
      }
    });

    dispatch(setSearchQuery({ types, subTypes }));
  };

  const clearAllFilters = () => {
    dispatch(removeAllSearchQueries());
  };

  // Track selection of those items with children
  React.useEffect(() => {
    const newSelectedParents: string[] = [];
    const newIndeterminateParents: string[] = [];

    Object.entries(
      filtersGroupedByParents as Record<
        string,
        { value: string; [key: string]: any }[]
      >
    ).forEach(([parent, children]) => {
      const childrenWithCounts = children.filter((child) => child.count > 0);
      const selectedChildren = childrenWithCounts.filter((child) =>
        selectedFilters.includes(child.value)
      );

      if (
        selectedChildren.length > 0 &&
        selectedChildren.length < childrenWithCounts.length
      ) {
        newIndeterminateParents.push(parent);
      }

      const allChildrenSelected =
        selectedChildren.length === childrenWithCounts.length;
      if (selectedChildren.length > 0 && allChildrenSelected) {
        newSelectedParents.push(parent);
      }
    });

    setSelectedParents(newSelectedParents);
    setIndeterminateParents(newIndeterminateParents);
  }, [
    filtersGroupedByParents,
    selectedFilters,
    setIndeterminateParents,
    setSelectedParents,
  ]);

  const selectChildren = useCallback(
    (parent: string) => {
      const children = filtersGroupedByParents[parent];

      if (!selectedParents.includes(parent)) {
        children.forEach((child) => {
          dispatch(
            setSingleSearchQuery({
              key: pluralize(filterSelectName),
              value: child.value,
            })
          );
        });
      } else {
        children.forEach((child) => {
          dispatch(
            removeSingleSearchQuery({
              key: pluralize(filterSelectName),
              value: child.value,
            })
          );
        });
      }
    },
    [filtersGroupedByParents, selectedParents, dispatch]
  );

  const options = useMemo(() => {
    const filterOptions: Option[] = [];
    filterTree.forEach((filterNode) => {
      if ('children' in filterNode) {
        const { parent, children } = filterNode;
        const activeChildren = children!.filter((f) => f.count > 0);
        if (activeChildren.length > 0) {
          filterOptions.push({
            value: parent!,
            element: (
              <Checkbox
                id={`${parent!}-${salt}`}
                name={filterNode.type}
                value={parent}
                onChangeHandler={() => selectChildren(parent!)}
                checked={selectedParents.includes(parent!)}
                noBorder
                fullWidth
                indeterminate={indeterminateParents.includes(parent!)}
                isParent
              >
                <span className="u-mr-1 u-font-bold">{parent}</span>
              </Checkbox>
            ),
          });

          activeChildren.forEach((child) => {
            filterOptions.push({
              value: child.value,
              element: (
                <Checkbox
                  id={`${child.id}-${salt}`}
                  name="subType"
                  value={child.value}
                  onChangeHandler={(e) => checkboxClickHandler(e, dispatch)}
                  checked={selectedFilters.includes(child.value)}
                  noBorder
                  fullWidth
                  isChild
                >
                  <div className="u-flex u-w-full u-justify-between">
                    <span
                      className="u-mr-1"
                      style={{ width: '80%', whiteSpace: 'break-spaces' }}
                    >
                      {child.label}
                    </span>
                    <span className="u-flex u-items-center">
                      ({child.count})
                    </span>
                  </div>
                </Checkbox>
              ),
            });
          });
        }
      } else if (filterNode.count > 0) {
        const f = filterNode;
        filterOptions.push({
          value: f.value,
          element: (
            <Checkbox
              id={`${f.id}-${salt}`}
              name={f.type}
              value={f.value}
              onChangeHandler={(e) => checkboxClickHandler(e, dispatch)}
              checked={selectedFilters.includes(f.value)}
              noBorder
              fullWidth
            >
              <div className="u-flex u-w-full u-justify-between">
                <span
                  className="u-mr-1"
                  style={{ width: '80%', whiteSpace: 'break-spaces' }}
                >
                  {f.label}
                </span>
                <span>({f.count})</span>
              </div>
            </Checkbox>
          ),
        });
      }
    });
    return filterOptions;
  }, [
    salt,
    filterTree,
    indeterminateParents,
    selectedFilters,
    selectedParents,
    selectChildren,
    dispatch,
  ]);

  return (
    <FilterSelect
      key={filterSelectName}
      id={filterSelectName}
      title="Resource types"
      selectAllFilters={() => {
        selectAllFilters();
      }}
      clearAllFilters={() => {
        clearAllFilters();
      }}
      selectChildren={selectChildren}
      options={options}
      className="c-filter-grid__item"
      isSelected={isSelected}
      inBanner
    />
  );
};

export default FilterSelectForResourcesWithoutUrlQueryParams;
