import { getEducationLevel } from '../../../../auth/utils/get-education-level';
import { useTypedSelector } from '../../../../store/hooks/use-typed-selector';
import {
  organisationCategorySelector,
  organisationCountrySelector,
} from '../../../../store/user/selectors';

export const usePersonalisedMenu = () => {
  let showEnglish = false;
  let showDrama = false;
  let showEducationLevels = false;

  const organisationCategory = useTypedSelector(organisationCategorySelector);
  const organisationCountry = useTypedSelector(organisationCountrySelector);

  const educationLevel = getEducationLevel(organisationCategory);

  if (educationLevel === 'Secondary' || educationLevel === 'Middle School') {
    showEnglish = true;
    showDrama = true;
    showEducationLevels = ['US', 'GB'].includes(organisationCountry);
  }

  const isUS = organisationCountry === 'US';
  const isUK = organisationCountry === 'GB';

  return { showEnglish, showDrama, showEducationLevels, isUS, isUK };
};
