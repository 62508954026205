import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { typeMapper } from '../../../utils/typeMapper';
import type { Filter } from '../../data/filter';
import type { AggregationCount } from '../../../elastic-search/data/aggregations';
import FilterSelectForResources from '../vertical-filter-panel/filter-select-for-resources';
import FilterSelectForResourcesWithoutUrlQueryParams from '../vertical-filter-panel/filter-select-for-resources-without-url-query-params';

interface Props {
  types?: Filter[];
  selectedTypes?: string[];
  subTypes?: Filter[];
  selectedSubTypes?: string[];
  typesWithSubtypes?: { type: string; subTypes: AggregationCount[] }[] | [];
  hideUrlQueryParams?: boolean;
}

const BannerSearchDropdown: FC<Props> = ({
  types,
  selectedTypes,
  subTypes,
  selectedSubTypes,
  typesWithSubtypes,
  hideUrlQueryParams = false,
}) => {
  const [filters, setFilters] = useState<Filter[]>(subTypes || []);

  useEffect(() => {
    if (typesWithSubtypes && subTypes) {
      const filtersWithChildren = typesWithSubtypes
        .map((typeWithSubType) => {
          const childSubTypeValues = typeWithSubType.subTypes.map(
            (aggCount) => aggCount.key
          );
          const childSubTypeFilter = subTypes.filter((subType) =>
            childSubTypeValues.includes(subType.value)
          );
          return {
            type: typeWithSubType.type,
            subTypes: childSubTypeFilter,
          };
        })
        .map((subTypeFilters) => {
          return subTypeFilters.subTypes.map((filter) => {
            return {
              ...filter,
              parent: typeMapper[pluralize.singular(subTypeFilters.type)],
            };
          });
        })
        .flat();

      // Detect filter without parents
      const typesWithoutChildren = typesWithSubtypes
        .filter((f) => f.subTypes.length === 0)
        .map((f) => f.type);
      const filtersWithoutChildren =
        types?.filter((t) => typesWithoutChildren.includes(t.value)) || [];

      const allFilters = [...filtersWithChildren, ...filtersWithoutChildren];

      setFilters(allFilters);
    }
  }, [types, selectedTypes, subTypes, typesWithSubtypes]);

  const joinedSelectedFilters = useMemo(() => {
    return selectedSubTypes?.concat(selectedTypes || []) || [];
  }, [selectedSubTypes, selectedTypes]);

  if (hideUrlQueryParams) {
    return <FilterSelectForResourcesWithoutUrlQueryParams filters={filters} />;
  }
  return (
    <FilterSelectForResources
      filters={filters}
      selectedFilters={joinedSelectedFilters}
    />
  );
};

export default BannerSearchDropdown;
