/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import { CARDS_PER_PAGE } from '../../config/pagination';
import type { DirectoryOptions } from '../../shared/data/directory';
import type { GenericGetContents } from '../data/generics/generic-get-content';
import type { GenericHookResponse } from '../data/generics/generic-hook-response';

const useContentDirectoryFactory = <T>(
  getFunc: GenericGetContents<T>,
  cacheFunc
) => {
  return (
    page,
    sortDirection,
    selectedFilters,
    searchTerm?,
    limit = CARDS_PER_PAGE
  ): GenericHookResponse<T> => {
    const [data, setData] = useState<Array<T>>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
      const options: DirectoryOptions = {
        limit,
        skip: (page - 1) * limit,
        order: sortDirection,
        filters: [],
      };

      if (selectedFilters.genres.length > 0) {
        options.filters.push({
          field: 'genre',
          values: selectedFilters.genres,
        });
      }

      if (selectedFilters.courses.length > 0) {
        options.filters.push({
          field: 'course',
          values: selectedFilters.courses,
        });
      }

      if (selectedFilters.forms.length > 0) {
        options.filters.push({
          field: 'form',
          values: selectedFilters.forms,
        });
      }

      if (selectedFilters.themes.length > 0) {
        options.filters.push({
          field: 'theme',
          values: selectedFilters.themes,
        });
      }

      if (selectedFilters.types.length > 0) {
        options.filters.push({
          field: 'type',
          values: selectedFilters.types,
        });
      }

      if (selectedFilters.subTypes.length > 0) {
        options.filters.push({
          field: 'subType',
          values: selectedFilters.subTypes,
        });
      }

      if (selectedFilters.companies.length > 0) {
        options.filters.push({
          field: 'company',
          values: selectedFilters.companies,
        });
      }

      if (selectedFilters.periods.length > 0) {
        options.filters.push({
          field: 'period',
          values: selectedFilters.periods,
        });
      }

      if (selectedFilters.roles.length > 0) {
        options.filters.push({
          field: 'role',
          values: selectedFilters.roles,
        });
      }

      if (selectedFilters.englishSkills.length > 0) {
        options.filters.push({
          field: 'englishSkill',
          values: selectedFilters.englishSkills,
        });
      }

      if (selectedFilters.theatreSkills.length > 0) {
        options.filters.push({
          field: 'theatreSkill',
          values: selectedFilters.theatreSkills,
        });
      }

      if (selectedFilters.levels.length > 0) {
        options.filters.push({
          field: 'level',
          values: selectedFilters.levels,
        });
      }

      if (searchTerm) {
        options.searchTerm = searchTerm;
      }

      setLoading(true);
      getFunc(options)
        .then((response) => {
          setData(response.items);
          setTotal(response.total);
          setLoading(false);
          setError(undefined);
          response.items.forEach(cacheFunc);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    }, [page, sortDirection, selectedFilters, searchTerm, limit]);

    return {
      data,
      total,
      loading,
      error,
    } as GenericHookResponse<T>;
  };
};

export default useContentDirectoryFactory;
