import type { ReactElement, ReactNode, ReactNodeArray } from 'react';

import ProgressBar from '../progress-bar/progress-bar';
import Layout from './layout';

interface Props {
  children: ReactNode | ReactNodeArray;
}

const ComposedPageLayout = ({ children }: Props): ReactElement => {
  return (
    <Layout>
      <ProgressBar />
      {children}
    </Layout>
  );
};

export default ComposedPageLayout;
