import { Action, Category, SearchClicked } from '../data/events';

export const createSearchClickEvent = (
  pageType: string,
  pageTitle: string,
  routerAsPath: string,
  action: Action.START_SEARCH | Action.SUBMIT | Action.CLEAR,
  name?: 'submit' | 'start-search' | 'clear'
): SearchClicked => ({
  category: Category.SEARCH,
  action,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl: routerAsPath,
});
