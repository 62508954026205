import dayjs from 'dayjs';
import { HE_NEW_PILL_EXPIRY_DATE } from '../ed-nav';

const options: MenuOption = {
  type: 'menu-option',
  title: 'Higher Education',
  isNew: HE_NEW_PILL_EXPIRY_DATE.isAfter(dayjs()),
  subOptions: [
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=technical-theatre',
      title: 'Technical Theatre',
      category: 'Technical Theatre',
    },
    {
      type: 'menu-option',
      url: '/content/study-units/higher-education/technical-theatre',
      title: 'Study Units',
      category: 'Technical Theatre',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=acting-and-actor-training',
      title: 'Acting',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&form=applied-theatre',
      title: 'Applied Theatre',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=contemporary-performance',
      title: 'Contemporary Performance',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&form=physical-theatre&form=dance',
      title: 'Dance and Physical Theatre',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=directing-and-dramaturgy',
      title: 'Directing',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=disability&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate',
      title: 'Disability and Performance',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=gender-and-performance',
      title: 'Gender and Performance',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&genre=musical-theatre',
      title: 'Musical Theatre',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=playwriting',
      title: 'Playwriting',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&form=political-theatre',
      title: 'Politics and Performance',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/search?q=&level=uk_undergraduate_year_1&level=uk_undergraduate_year_2&level=uk_undergraduate_year_3&level=uk_postgraduate&course=race-and-performance',
      title: 'Race and Performance',
      category: 'Theatre Studies',
    },
    {
      type: 'menu-option',
      url: '/content/study-units/higher-education/theatre-studies',
      title: 'Study Units',
      category: 'Theatre Studies',
    },
  ],
};

export default options;
