import URI from 'urijs';
import { useRouter } from 'next/router';
import type { AutocompleteSuggestion } from '../data/autocomplete';
import searchIcon from '../../assets/svg/sprites/search.svg';

const splitSuggestionName = (name: string, searchTerm: string) =>
  name.split(new RegExp(`(${searchTerm})`, 'gi'));

const addTrailingSpace = (str: string) => {
  if (str[str.length - 1] === ' ') {
    return <>{str.substr(0, str.length - 1)}&nbsp;</>;
  }

  if (str[0] === ' ') {
    return <>&nbsp;{str.substr(1, str.length - 1)}</>;
  }

  return str;
};

interface Props {
  suggestions: AutocompleteSuggestion[];
  searchTerm: string;
}

const Suggestions: React.FC<Props> = ({ suggestions, searchTerm }) => {
  const router = useRouter();

  const onSuggestionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const target: HTMLButtonElement = event.currentTarget;

    const url = URI(router.asPath);
    url.setQuery('q', target.getAttribute('data-value') || '');
    router.push(url.toString());
  };

  return (
    <div className="c-suggestions">
      <div className="c-suggestions__title">Search suggestions</div>
      {suggestions.map((suggestion) => {
        return (
          <button
            data-testid="suggestion-item"
            type="button"
            key={suggestion.name}
            data-value={suggestion.name}
            className="c-suggestions__item"
            onClick={onSuggestionClick}
          >
            <div className="c-suggestions__item-icon">
              <svg viewBox={searchIcon.viewBox} aria-hidden="true">
                <use href={searchIcon} />
              </svg>
            </div>
            {splitSuggestionName(suggestion.name, searchTerm).map((part, i) =>
              part.toLowerCase() === searchTerm.toLowerCase() ? (
                // eslint-disable-next-line react/no-array-index-key
                <span key={i} className="c-suggestions__highlight">
                  {addTrailingSpace(part)}
                </span>
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <span key={i}>{addTrailingSpace(part)}</span>
              )
            )}
          </button>
        );
      })}
    </div>
  );
};

export default Suggestions;
