import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import HeaderDropdown from './header-dropdown';
import toKebabCase from '../../../utils/to-kebab-case';
import { trackEvent } from '../../../analytics/api';
import { createEducationNavigationClickEvent } from '../../../analytics/events/create-education-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import Pill from '../pills/pill';
import ExpandableOption from './expandable-option';
import TourStepWrapper from '../tour/tour-step-wrapper';
import { useTour } from '../../contexts/tour/use-tour';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import { usePersonalisedMenu } from './hooks/use-personalised-menu';
import { getGroupedOptionsByCategory } from '../../../utils/get-grouped-options-by-category';
import { getEducationNavigationClickEventTitle } from '../../../utils/get-education-navigation-click-event-title';

interface Props {
  options: Array<MenuOption | SkeletonOption>;
  label: string;
  onDropdownExpanded?: () => void;
  ariaLabel?: string;
  isNew?: boolean;
  initialExpanded?: boolean;
}

const ExpandableDropdown: FC<Props> = ({
  label,
  options,
  onDropdownExpanded,
  ariaLabel,
  isNew,
  initialExpanded,
}: Props) => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();
  const { isUS } = usePersonalisedMenu();
  const [expandedOptionIndex, setExpandedOptionIndex] = useState<number | null>(
    null
  );
  // This value is helping tablet event since sometimes the hover event is not raised,
  // depending on the browser used. If hover is not used, then we allow click event.
  const [hasHovered, setHasHovered] = useState(false);
  const hasAnOptionExpanded = expandedOptionIndex !== null;

  const breakpoint = useMediaBreakpoint();

  const onOptionSelected = (index: number) => {
    const selectedOption = options[index];

    if (selectedOption.type === 'menu-option' && selectedOption.subOptions) {
      if (expandedOptionIndex === index) {
        return;
      }
      trackEvent(
        createEducationNavigationClickEvent(
          Action.EDUCATION_NAVIGATION_BROWSE,
          getEducationNavigationClickEventTitle(selectedOption.title, isUS),
          pageType,
          pageTitle,
          router.asPath
        )
      );
      setExpandedOptionIndex((prevIndex) => {
        return prevIndex === index ? null : index;
      });
    } else {
      // close the expanded dropdown
      setExpandedOptionIndex(null);
    }
  };

  const { currentStep } = useTour();

  return (
    <div style={{ height: '64px' }}>
      <HeaderDropdown
        id={`${toKebabCase(label)}-dropdown`}
        buttonContent={
          <>
            {isNew && (
              <Pill
                className="u-mr-2"
                variant="green"
                size="rel-sm"
                style={{
                  padding: '2px 5px 0 5px',
                }}
              >
                NEW
              </Pill>
            )}
            <span>{label}</span>
          </>
        }
        align="left"
        onDropdownExpanded={() => {
          onDropdownExpanded?.();
        }}
        onDropdownCollapsed={() => {
          setExpandedOptionIndex(null);
        }}
        ariaLabel={ariaLabel}
        displayGreenDot={options
          .filter((o): o is MenuOption => o.type === 'menu-option')
          .some((option) => option.isNew)}
        showActiveUnderline
        initialExpanded={initialExpanded}
      >
        <div
          className={`u-flex c-top-level-links-container ${
            hasAnOptionExpanded ? 'expanded-bg' : ''
          }`}
          tabIndex={-1}
        >
          <ul
            id="menu-options"
            className="c-top-level-links c-top-level-links--dropdown c-top-level-links--expandable u-flex-1"
            data-testid="explore-dropdown-options"
            role="listbox"
            tabIndex={-1}
          >
            <TourStepWrapper
              key="tour-step-1"
              open={
                currentStep === 1 &&
                breakpoint >= MediaBreakpoints.SM &&
                breakpoint < MediaBreakpoints.LG
              }
              placement="right"
            >
              <div>
                {Object.entries(
                  getGroupedOptionsByCategory(
                    options.filter(
                      (o): o is MenuOption => o.type === 'menu-option'
                    )
                  )
                ).map(([category, options]) => (
                  <div key={category}>
                    {category !== 'Undefined' ? (
                      <>
                        <span className="u-text-gray-dark u-proximanova u-font-bold">
                          {category}
                        </span>
                        <hr className="u-text-gray-light u-mt-1" />
                      </>
                    ) : null}
                    <ul>
                      {options.map((option, index) =>
                        // eslint-disable-next-line no-nested-ternary
                        option.type === 'menu-option' ? (
                          option.title === 'Browse' ? (
                            <TourStepWrapper
                              key="tour-step-2"
                              open={
                                currentStep === 2 &&
                                breakpoint >= MediaBreakpoints.SM &&
                                breakpoint < MediaBreakpoints.LG
                              }
                              placement="right"
                            >
                              <ExpandableOption
                                key={toKebabCase(option.title)}
                                menuOption={option}
                                onClickCallback={() => {
                                  if (!hasHovered) {
                                    onOptionSelected(index);
                                  }
                                }}
                                onHoverCallback={() => {
                                  setHasHovered(true);
                                  onOptionSelected(index);
                                }}
                                isCurrentOptionExpanded={
                                  index === expandedOptionIndex
                                }
                                dropdownTitle={label}
                              />
                            </TourStepWrapper>
                          ) : (
                            <ExpandableOption
                              key={toKebabCase(option.title)}
                              menuOption={option}
                              onClickCallback={() => {
                                if (!hasHovered) {
                                  onOptionSelected(index);
                                }
                              }}
                              onHoverCallback={() => {
                                setHasHovered(true);
                                onOptionSelected(index);
                              }}
                              isCurrentOptionExpanded={
                                index === expandedOptionIndex
                              }
                              dropdownTitle={label}
                            />
                          )
                        ) : (
                          <div
                            key={(option as unknown as SkeletonOption).id}
                            className="u-flex u-p-1 u-m-1 u-items-center"
                            style={{ height: '44px' }}
                          >
                            <span className="c-skeleton type-h2 u-h-full u-w-full">
                              Loading
                            </span>
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </TourStepWrapper>
          </ul>
        </div>
      </HeaderDropdown>
    </div>
  );
};

export default ExpandableDropdown;
