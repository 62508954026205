import pluralize from 'pluralize';
import type { DirectoryOptions } from '../../shared/data/directory';

export const generateFilterOptions = (
  selectedFilters,
  usedFilters: string[],
  aggregationsField: string,
  searchTerm?: string
) => {
  const options: DirectoryOptions = {
    limit: 0,
    skip: 0,
    order: 'name-asc',
    filters: [],
    aggregations: [aggregationsField],
  };

  usedFilters.forEach((filter) => {
    if (selectedFilters[filter].length > 0) {
      options.filters.push({
        field: pluralize.singular(filter),
        values: selectedFilters[filter],
      });
    }
  });

  if (searchTerm) {
    options.searchTerm = searchTerm;
  }

  return options;
};
