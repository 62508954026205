import { useQuery } from 'react-query';
import { cacheTheme, getSlugCacheKey } from '../../cache';
import { userContentForMultipleSlugsFactory } from '../../content/hooks/use-multiple-slugs';
import {
  getTheme,
  getThemes,
  getThemesForMultipleSlugs,
} from '../api/frontend';
import type { Theme } from '../data/theme';

export const useTheme = (slug?: string) =>
  useQuery<Theme, Error>(
    getSlugCacheKey('theme', slug as string),
    () => getTheme(slug as string),
    { enabled: Boolean(slug) }
  );

export const useThemes = () => {
  return useQuery<Theme[], Error>(
    'themes',
    getThemes
    // Ticket site#826
    // { onSuccess: (themes) => themes.forEach(cacheTheme) },
  );
};

export const useThemesForMultipleSlugs =
  userContentForMultipleSlugsFactory<Theme>(
    getThemesForMultipleSlugs,
    'theme',
    cacheTheme
  );
