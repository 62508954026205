import type { FC } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { trackEvent } from '../../../analytics/api';
import searchIcon from '../../../assets/svg/sprites/search.svg';
import { Dropdown } from '../dropdown/dropdown';
import { AutocompleteSuggestions } from '../../../search/components/autocomplete-suggestions';
import { setSearchTerm } from '../../../store/search/search.slice';
import { createSearchClickEvent } from '../../../analytics/events/create-search-click-event';
import { Action } from '../../../analytics/data/events';
import { useSearchAutocompleteFeature } from '../../hooks/use-autocomplete-feature';
import { useAppDispatch } from '../../../store/hooks/use-app-dispatch';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { searchTermSelector } from '../../../store/search/selectors';

import * as breakpoints from '../../../styles/settings/screens';

interface Props {
  toggleMobileSearch?: () => void;
  size?: 'md' | 'lg';
  searchBarId: string;
  searchBarWrapperRef?: React.RefObject<HTMLDivElement>;
  rounded?: boolean;
  hideUrlQueryParams?: boolean;
}

const BannerSearchInput: FC<Props> = ({
  toggleMobileSearch,
  size = 'md',
  searchBarId,
  searchBarWrapperRef,
  rounded,
  hideUrlQueryParams = false,
}) => {
  const showSearchAutocompleteFeature = useSearchAutocompleteFeature();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { pageType, pageTitle } = useAnalyticsContext();
  const inputElement = useRef<HTMLInputElement>(null);
  const breakpointSm = parseInt(breakpoints['upto-sm'].max, 10);
  const breakpointMd = parseInt(breakpoints['upto-md'].max, 10);
  const windowWidth = window.innerWidth;
  const placeholder = `Search ${
    breakpointSm < windowWidth && windowWidth < breakpointMd
      ? 'DT'
      : 'Digital Theatre'
  }+`;
  const isSearchPage = router && router.pathname === '/search';
  const searchTerm: string = useTypedSelector(searchTermSelector);
  const [isFocused, setIsFocused] = useState(false);

  const selectedTypeFilters = useTypedSelector(
    (state) => state.search.searchQuery.types
  );
  const selectedSubtypeFilters = useTypedSelector(
    (state) => state.search.searchQuery.subTypes
  );

  useEffect(() => {
    if (!isSearchPage) {
      dispatch(setSearchTerm(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    if (router.query.q) {
      dispatch(setSearchTerm(router.query.q as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.q]);

  const submitSearchClickedEvent = useMemo(() => {
    return createSearchClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      Action.SUBMIT,
      'submit'
    );
  }, [pageTitle, pageType, router.asPath]);

  const clearSearchClickedEvent = useMemo(() => {
    return createSearchClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      Action.CLEAR,
      'clear'
    );
  }, [pageTitle, pageType, router.asPath]);

  const handleClearClick = (): void => {
    dispatch(setSearchTerm(''));
    trackEvent(clearSearchClickedEvent);

    if (inputElement.current) {
      inputElement.current.focus();
    }
  };

  const handleInputBlur = (e): void => {
    if (toggleMobileSearch && searchTerm === '' && e.relatedTarget === null) {
      toggleMobileSearch();
    }

    setTimeout(() => setIsFocused(false), 100);
  };

  const focusSearchClickedEvent = useMemo(() => {
    return createSearchClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      Action.START_SEARCH,
      'start-search'
    );
  }, [pageTitle, pageType, router.asPath]);

  const handleSubmit = (e): void => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: isSearchPage ? 'smooth' : 'auto' });
    trackEvent(submitSearchClickedEvent);

    const value = searchTerm.trim();
    const url = new URL(window.location.href);
    if (url.pathname !== '/search') {
      url.pathname = '/search';
    }
    if (hideUrlQueryParams) {
      selectedTypeFilters.forEach((type) => {
        url.searchParams.append('type', type);
      });
      selectedSubtypeFilters.forEach((subType) => {
        url.searchParams.append('subType', subType);
      });
    }
    url.searchParams.set('q', value);
    // Delete 'redirect' param if it exists
    url.searchParams.delete('redirect');
    // Go back to the first page
    url.searchParams.delete('page');
    router.push(url.toString());
  };

  const handleChange = (e): void => {
    dispatch(setSearchTerm(e.target.value));
  };

  return (
    <div
      ref={searchBarWrapperRef}
      className={`c-banner-search-input c-banner-search-input--${size}`}
    >
      <form
        className="c-banner-search-input__form"
        onSubmit={handleSubmit}
        aria-labelledby={`${searchBarId}-label`}
        style={rounded ? { borderRadius: '0.25rem' } : {}}
      >
        <input
          type="search"
          id={`${searchBarId}-input`}
          className="c-banner-search-input__input"
          onChange={handleChange}
          placeholder={placeholder}
          ref={inputElement}
          value={searchTerm}
          onBlur={handleInputBlur}
          onFocus={(e) => {
            if (!e.target.value) {
              trackEvent(focusSearchClickedEvent);
            }
            setIsFocused(true);
          }}
          autoComplete="off"
          data-testid="search-bar-input"
        />
        {searchTerm && (
          <button
            type="button"
            className="c-banner-search-input__button c-round-button c-round-button--cancel c-round-button--dark u-mx-2"
            onClick={handleClearClick}
            aria-label="Clear search text"
            data-testid="clear-search-input"
          >
            <div />
            <div />
          </button>
        )}
        <button
          type="button"
          className="c-banner-search-input__search-button"
          onClick={handleSubmit}
          aria-label="Search"
        >
          <svg
            className="c-search-bar__svg"
            viewBox={searchIcon.viewBox}
            aria-hidden="true"
          >
            <use href={searchIcon} />
          </svg>
        </button>
      </form>
      {showSearchAutocompleteFeature && (
        <Dropdown isOpen={isFocused && searchTerm !== ''}>
          <AutocompleteSuggestions />
        </Dropdown>
      )}
    </div>
  );
};

export default BannerSearchInput;
