import dayjs from 'dayjs';
import { UK_ED_NAV_NEW_PILL_EXPIRY_DATE } from '../ed-nav';

const option: MenuOption = {
  type: 'menu-option',
  title: 'Key stage 3',
  subOptions: [
    {
      type: 'menu-option',
      url: '/content/study-units/ks3/english',
      title: 'English',
      category: 'Study units',
    },
    {
      type: 'menu-option',
      url: '/content/study-units/ks3/drama',
      title: 'Drama',
      category: 'Study units',
    },
    {
      type: 'menu-option',
      url: '/search?q=&type=title&level=uk_year_07&level=uk_year_08&level=uk_year_09&sort_by=name-asc',
      title: 'Titles',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&type=person&level=uk_year_07&level=uk_year_08&level=uk_year_09',
      title: 'People',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&subType=Poetry+recital&level=uk_year_07&level=uk_year_08&level=uk_year_09',
      title: 'Poetry',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/collections',
      title: 'Collections',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&subType=Audio+interview&subType=Documentary&subType=Interview&subType=Audio+essay&subType=E-learning&subType=Lecture&subType=Workshop&subType=Lesson+plan&subType=Teaching+resource&subType=Essay&subType=Graphic+novel&subType=Play+text&subType=Poem&subType=Story&subType=Study+guide&subType=Workbook&level=uk_year_07&level=uk_year_08&level=uk_year_09&englishSkill=character&englishSkill=close-reading&englishSkill=comparing-and-contrasting&englishSkill=context&englishSkill=language-and-literary-devices&englishSkill=setting&englishSkill=structure&englishSkill=theme&englishSkill=collaborating&englishSkill=presenting',
      title: 'English skills',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&subType=Audio+interview&subType=Documentary&subType=Interview&subType=Audio+essay&subType=E-learning&subType=Lecture&subType=Workshop&subType=Lesson+plan&subType=Teaching+resource&subType=Essay&subType=Graphic+novel&subType=Play+text&subType=Poem&subType=Story&subType=Study+guide&subType=Workbook&theatreSkill=acting-methods-and-techniques&theatreSkill=costume-and-make-up&theatreSkill=design-process&theatreSkill=devising&theatreSkill=directing&theatreSkill=evaluating-theatre&theatreSkill=lighting&theatreSkill=movement-and-physicality&theatreSkill=playwriting&theatreSkill=rehearsal-and-collaboration&theatreSkill=script-analysis&theatreSkill=set-and-props&theatreSkill=sound&theatreSkill=theatre-history&theatreSkill=theatre-management&theatreSkill=understanding-context&theatreSkill=voice&level=uk_year_07&level=uk_year_08&level=uk_year_09',
      title: 'Drama skills',
      category: 'Browse',
    },
  ],
  isNew: UK_ED_NAV_NEW_PILL_EXPIRY_DATE.isAfter(dayjs()),
  isEducationLevel: true,
};

export default option;
