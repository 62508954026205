/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { type FC, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import ReactDOM from 'react-dom';
import { isEqual } from 'lodash';
import UserMenu from './user-menu';
import { setChildInteractivity } from '../../../utils/dom-interactivity';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { useDirectoryParams } from '../../hooks/use-directory-params';
import { CONTENTFUL_SORT } from '../../api/adapters/types';
import { useSearchedContentFilterOptions } from '../../hooks/search';
import useFilterCounts from '../../../content/hooks/use-filter-counts';
import { searchContent } from '../../api';
import { useFilters } from '../../hooks/use-filters';
import useTypesWithSubtypes from '../../../content/hooks/use-types-with-subtypes';
import BannerSearchBar from '../search-bar/banner-search-bar';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { useTour } from '../../contexts/tour/use-tour';
import { usePersonalisedMenu } from './hooks/use-personalised-menu';
import HeaderMenuDesktop from './header-menu-desktop';
import HeaderMenuTablet from './header-menu-tablet';
import HeaderLogo from './header-logo';

interface Props {
  onMenuEnter?: () => void;
  onMenuLeave?: () => void;
  options: Array<MenuOption | SkeletonOption>;
  isHeaderTransparent?: boolean;
}

const HeaderContentsFromSe: FC<Props> = ({
  onMenuEnter,
  onMenuLeave,
  options,
  isHeaderTransparent = false,
}: Props) => {
  const searchWrapperElement = useRef<HTMLDivElement>(null);
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();
  const searchHidden = router.pathname === '/search';
  const { isUS } = usePersonalisedMenu();

  const selectedTypeFilters = useTypedSelector(
    (state) => state.search.searchQuery.types
  );
  const selectedSubtypeFilters = useTypedSelector(
    (state) => state.search.searchQuery.subTypes
  );
  const { selectedFilters } = useDirectoryParams(CONTENTFUL_SORT.RELEVANCE);

  const newSelectedFilters = useMemo(() => {
    return {
      ...selectedFilters,
      types: selectedTypeFilters,
      subTypes: selectedSubtypeFilters,
    };
  }, [selectedFilters, selectedTypeFilters, selectedSubtypeFilters]);

  const allFilterSlugs = useSearchedContentFilterOptions();
  const filterCounts = useFilterCounts(
    newSelectedFilters,
    ['types', 'subTypes'],
    searchContent
  );
  const filters = useFilters(allFilterSlugs, filterCounts);
  const typesWithSubtypes = useTypesWithSubtypes(allFilterSlugs.types);

  useEffect(() => {
    if (searchWrapperElement && searchWrapperElement.current) {
      setChildInteractivity(searchWrapperElement.current, !searchHidden);
    }
  }, [searchHidden]);

  const steps = [
    {
      title: 'Our site navigation has changed',
      text: 'Our site navigation has been updated to help you more easily find content tailored to your needs as an educator.',
    },
    {
      title: 'Browse content by stage and subject',
      text: 'Access curated units of content and lesson plans for Middle School and High School.',
    },
    {
      title: 'The previous navigation is located here',
      text: "You can still access the previous navigation options to browse 'Titles', 'People', etc across all subjects and grade levels",
    },
  ];

  const { tourSteps, startTour, started, setUpTour, currentStep } = useTour();

  const prevOptionsRef = useRef<(MenuOption | SkeletonOption)[]>([]);

  useEffect(() => {
    if (!isEqual(options, prevOptionsRef.current)) {
      if (
        options.length < 3 ||
        options.some((option) => option.type === 'skeleton-option')
      ) {
        return;
      }

      if (!started && isUS) {
        setUpTour(steps, pageTitle, pageType);
        startTour();
      }
    }

    prevOptionsRef.current = options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const darkOverlay = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 99,
      }}
    />
  );

  return (
    <div className="c-header__inner c-header__inner--from-sm u-flex u-w-full u-h-xl upto-sm:u-hidden">
      {tourSteps.length > 0 &&
        started &&
        ReactDOM.createPortal(darkOverlay, document.body)}
      <nav
        className="c-header__nav"
        onMouseEnter={onMenuEnter}
        onMouseLeave={() => {
          if (currentStep === 0) {
            onMenuLeave?.();
          }
        }}
      >
        <HeaderLogo />
        <HeaderMenuDesktop isHeaderTransparent={isHeaderTransparent} />
        <HeaderMenuTablet />
      </nav>
      <div
        className="c-header__section c-header__section--search c-header__section--border-r u-px-4"
        aria-hidden={searchHidden}
        ref={searchWrapperElement}
      >
        <BannerSearchBar
          searchBarId="search-bar-from-sm"
          types={filters.types}
          subTypes={filters.subTypes}
          selectedTypes={selectedTypeFilters}
          selectedSubTypes={selectedSubtypeFilters}
          typesWithSubtypes={typesWithSubtypes}
          searchBarWrapperRef={searchWrapperElement}
          style={{ width: '100%', display: searchHidden ? 'none' : 'flex' }}
          hideUrlQueryParams
        />
      </div>
      <div className="c-header__section">
        <UserMenu onDropdownEnter={onMenuEnter} onDropdownLeave={onMenuLeave} />
      </div>
    </div>
  );
};

export default HeaderContentsFromSe;
