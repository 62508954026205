import Head from 'next/head';
import type { FC, ReactNode } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Modal from 'react-modal';
import Footer from '../footer/footer';
import Header from '../header/header';

dayjs.extend(localizedFormat);
interface Props {
  children: ReactNode;
}

Modal.setAppElement('body');

const Layout: FC<Props> = ({ children }: Props) => {
  return (
    <div id="layout">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Digital Theatre+</title>
        <meta
          name="description"
          content=" Digital Theatre+ is the world's leading educational platform for the performing arts."
        />
      </Head>
      <Header />
      <main id="main">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
