/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { FC } from 'react';
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useMemo, useRef } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import ReactDOM from 'react-dom';
import { setChildInteractivity } from '../../../utils/dom-interactivity';
import dtpLogo from '../../../assets/svg/sprites/dtp.svg';
import searchIcon from '../../../assets/svg/sprites/search.svg';
import SearchBar from '../search-bar/search-bar';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { trackEvent } from '../../../analytics/api';
import { useTour } from '../../contexts/tour/use-tour';

interface Props {
  mobileMenuId: string;
  toggleMobileMenu: () => void;
  mobileSearchIsExpanded: boolean;
  toggleMobileSearch: () => void;
  displayGreenDot?: boolean;
}

const HeaderContentsUptoSe: FC<Props> = ({
  mobileMenuId,
  toggleMobileMenu,
  mobileSearchIsExpanded,
  toggleMobileSearch,
  displayGreenDot,
}: Props) => {
  const headerLower = useRef<HTMLDivElement>(null);
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();

  const { started, tourSteps, currentStep } = useTour();

  const onRouterChange = (url: string): void => {
    if (!url.includes('/search')) {
      toggleMobileSearch();
    }
  };

  useEffect(() => {
    if (mobileSearchIsExpanded) {
      Router.events.on('routeChangeStart', onRouterChange);
    }

    return (): void => {
      Router.events.off('routeChangeStart', onRouterChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileSearchIsExpanded]);

  useEffect(() => {
    if (headerLower.current) {
      if (mobileSearchIsExpanded) {
        const searchInput = headerLower.current.querySelector('input');

        if (searchInput) {
          searchInput.focus();
        }
      }
      setChildInteractivity(headerLower.current, mobileSearchIsExpanded);
    }
  }, [mobileSearchIsExpanded]);

  const darkOverlay = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 99,
      }}
    />
  );

  const menuButton = (
    <button
      type="button"
      id="menu-button"
      data-testid="menu-button"
      aria-label="Menu"
      className={`c-square-button c-square-button--dark c-square-button--menu u--ml-1 ${
        displayGreenDot && 'c-square-button--new-items'
      }`}
      onClick={toggleMobileMenu}
      aria-controls={mobileMenuId}
    >
      <div />
      <div />
      <div />
    </button>
  );

  const logoClickedEvent = useMemo(
    () =>
      createNavigationClickEvent(
        Action.HEADER,
        'home-logo',
        pageType,
        pageTitle,
        router.asPath
      ),
    [pageType, pageTitle, router.asPath]
  );

  return (
    <div className="c-header__section u-w-full u-overflow-hidden u-flex u-flex-col">
      {tourSteps.length > 0 &&
        currentStep === 0 &&
        started &&
        ReactDOM.createPortal(darkOverlay, document.body)}
      <div className="o-container u-h-xl u-mx-auto u-flex u-items-center">
        {menuButton}

        <Link href="/">
          <a
            className="c-square-button c-square-button--dark u-ml-2 u-mr-auto"
            data-testid="header-logo-link-to-sm"
            aria-label="DT+ Home"
            title="DT+ Home"
            onClick={() => trackEvent(logoClickedEvent)}
          >
            <svg
              className="u-w-12 u-h-5 u--mb-px"
              viewBox={dtpLogo.viewBox}
              aria-hidden="true"
            >
              <use href={dtpLogo} />
            </svg>
          </a>
        </Link>

        <button
          type="button"
          className="c-mobile-search-button"
          onClick={toggleMobileSearch}
        >
          <span className="u-sr-only">Toggle Search Input</span>
          <svg
            className="u-h-7 u-w-7"
            viewBox={searchIcon.viewBox}
            aria-hidden="true"
            style={{ width: '40px', height: '40px' }}
          >
            <use href={searchIcon} />
          </svg>
        </button>
      </div>

      <div
        className="o-header-lower"
        aria-hidden={!mobileSearchIsExpanded}
        ref={headerLower}
      >
        <div className="o-header-lower__inner">
          <SearchBar
            toggleMobileSearch={toggleMobileSearch}
            searchBarId="search-bar-upto-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderContentsUptoSe;
