/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { ReactElement } from 'react';

import type LinkDetails from './link-details';
import { VERSION } from '../../../config/version';
import YourIpAddress from './your-ip-address';
import BottomBarLink from './bottom-bar-link';
import toKebabCase from '../../../utils/to-kebab-case';

const Links = (): React.ReactElement => {
  const links: Array<LinkDetails> = [
    { url: '/terms', text: 'Licence and Terms of Use' },
    { url: '/privacy-policy', text: 'Privacy Policy' },
    { url: '/accessibility-statement', text: 'Accessibility Statement' },
    { url: '/copyright', text: 'Copyright Notice' },
    { url: '/cookies', text: 'Cookie Policy' },
  ];
  return (
    <ul>
      {links.map((link, i) => (
        <BottomBarLink
          key={toKebabCase(`generic-${link.text}`)}
          link={link}
          lastLink={i === links.length}
        />
      ))}
    </ul>
  );
};

const ShowVersion = (): ReactElement | null => {
  if (VERSION === null) {
    return null;
  }
  return (
    <span>
      Version: <span className="version-number">{VERSION?.slice(0, 8)}</span>
    </span>
  );
};

const BottomBar = (): ReactElement => {
  return (
    <div className="c-footer__bottom">
      <div className="o-link-group">
        <Links />
      </div>
      <div className="se:u-ml-auto">
        <div className="c-footer__bottom__version">
          <ShowVersion />
          <YourIpAddress />
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
