import type { FC, ReactNode } from 'react';

interface Props {
  variant:
    | 'blue'
    | 'green'
    | 'pink'
    | 'white'
    | 'midnight'
    | 'dark-grey'
    | 'light-grey';
  size?: 'sm' | 'md' | 'lg' | 'rel-xs' | 'rel-sm' | 'rel-md' | 'custom';
  children: ReactNode | string;
  style?: React.CSSProperties;
  className?: string;
}

const Pill: FC<Props> = ({
  variant,
  size = 'sm',
  style,
  className,
  children,
}: Props) => {
  const pillSize = size !== 'custom' ? `c-pill--${size}` : '';

  return (
    <div
      style={style}
      className={`c-pill c-pill--${variant} ${pillSize} ${className || ''}`}
      data-testid="pill"
    >
      {children}
    </div>
  );
};

export default Pill;
