import { useRouter } from 'next/router';
import TourStepWrapper from '../tour/tour-step-wrapper';
import ExpandableDropdown from './expandable-dropdown';
import toKebabCase from '../../../utils/to-kebab-case';
import { trackEvent } from '../../../analytics/api';
import { createEducationNavigationClickEvent } from '../../../analytics/events/create-education-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import { useTour } from '../../contexts/tour/use-tour';
import { useMenuOptions } from './hooks/use-menu-options';
import { ProductTourRestartButton } from '../tour/product-tour-restart-button';
import { usePersonalisedMenu } from './hooks/use-personalised-menu';
import { getEducationNavigationClickEventTitle } from '../../../utils/get-education-navigation-click-event-title';

interface Props {
  isHeaderTransparent: boolean;
}

const HeaderMenuDesktop = ({ isHeaderTransparent }: Props) => {
  const router = useRouter();
  const { pageType, pageTitle } = useAnalyticsContext();
  const breakpoint = useMediaBreakpoint();
  const options = useMenuOptions();
  const { isUS } = usePersonalisedMenu();

  const { currentStep } = useTour();

  if (breakpoint < MediaBreakpoints.LG) {
    return null;
  }

  return (
    <>
      <TourStepWrapper key="tour-step-0" open={currentStep === 0} />
      <TourStepWrapper key="tour-step-1" open={currentStep === 1}>
        <div className="upto-lg:u-hidden c-header__section c-header__section--border-r u-flex">
          {options.map((option) =>
            // eslint-disable-next-line no-nested-ternary
            option.type === 'menu-option' ? (
              option.title === 'Browse' ? (
                <TourStepWrapper key="tour-step-2" open={currentStep === 2}>
                  <ExpandableDropdown
                    key={toKebabCase(option.title)}
                    label={option.title}
                    onDropdownExpanded={() => {
                      trackEvent(
                        createEducationNavigationClickEvent(
                          Action.EDUCATION_NAVIGATION_BROWSE,
                          getEducationNavigationClickEventTitle(
                            option.title,
                            isUS
                          ),
                          pageType,
                          pageTitle,
                          router.asPath
                        )
                      );
                    }}
                    options={option.subOptions ?? []}
                    ariaLabel={option.ariaLabel ?? option.title}
                    isNew={option.isNew}
                  />
                </TourStepWrapper>
              ) : (
                <ExpandableDropdown
                  key={toKebabCase(option.title)}
                  label={option.title}
                  onDropdownExpanded={() => {
                    trackEvent(
                      createEducationNavigationClickEvent(
                        Action.EDUCATION_NAVIGATION_BROWSE,
                        getEducationNavigationClickEventTitle(
                          option.title,
                          isUS
                        ),
                        pageType,
                        pageTitle,
                        router.asPath
                      )
                    );
                  }}
                  options={option.subOptions ?? []}
                  ariaLabel={option.ariaLabel ?? option.title}
                  isNew={option.isNew}
                />
              )
            ) : (
              <div
                key={option.id}
                className="u-flex u-h-full u-p-1 u-items-center"
              >
                <span
                  className={`c-skeleton ${
                    isHeaderTransparent ? 'c-skeleton--dark' : ''
                  } type-h2 u-h-1/2`}
                  style={{ width: '180px' }}
                >
                  Loading
                </span>
              </div>
            )
          )}
          <ProductTourRestartButton />
        </div>
      </TourStepWrapper>
    </>
  );
};

export default HeaderMenuDesktop;
