import type { NextRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import URI from 'urijs';
import type AppAuthService from '../services/auth-service';
import { trackEvent } from '../../analytics/api';
import { Category } from '../../analytics/data/events';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

function getRedirectUrl(query: ParsedUrlQuery): string | undefined {
  if (query.redirect) {
    return URI(query.redirect as string).toString();
  }

  return undefined;
}

export const onLogin = (
  appAuthService: AppAuthService,
  router: NextRouter
): void => {
  if (inIframe()) {
    if (document.requestStorageAccess) {
      const result = document.requestStorageAccess();

      result.then(
        () => {
          const uri = new URI(window.location.origin)
            .filename('login')
            .addQuery({ iframe: true })
            .toString();
          window.open(uri);

          const newURI = new URI(window.location.href)
            .filename('callback')
            .addQuery({ waiting: true, redirect: getRedirectUrl(router.query) })
            .toString();
          window.location.href = newURI;
        },
        () => {
          const newURI = new URI(window.location.href)
            .filename('iframe-callback')
            .addQuery({ denied: true, redirect: getRedirectUrl(router.query) })
            .toString();
          window.location.href = newURI;
        }
      );
    } else {
      const uri = new URI(window.location.origin)
        .filename('login')
        .addQuery({ iframe: true })
        .toString();
      window.open(uri);

      const newURI = new URI(window.location.href)
        .filename('callback')
        .addQuery({ waiting: true, redirect: getRedirectUrl(router.query) })
        .toString();
      window.location.href = newURI;
    }
  } else {
    appAuthService.login(getRedirectUrl(router.query)).catch((e: any) => {
      if ('name' in e && e.name === 'AuthApiError') {
        router.push(
          '/login?loginError="An error occurred fetching the authentication configuration. This may be due to a network restriction. Please contact your IT support for assistance."'
        );
        trackEvent({
          category: Category.AUTHENTICATION,
          action: 'LOGIN DEBUG',
          name: 'An error occurred fetching the authentication configuration',
          value: 1,
        });
      }
    });
  }
};
