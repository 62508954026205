module.exports = {
  'upto-sm': { max: '500px' },
  'sm': '501px',
  'upto-se': { max: '568px' },
  'se': '569px',
  'im': '768px', // An intermediate breakpoint between sm and md screens
  'upto-im': { max: '767px' },
  'sm-to-md': { min: '501px', max: '1023px' },
  'upto-md': { max: '1023px' },
  'md': '1024px',
  'upto-lg': { max: '1295px' },
  'lg': '1296px',
  'upto-xl': { max: '1439px' },
  'xl': '1440px',
};
