/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo } from 'react';
import type { ReactElement } from 'react';

import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { trackEvent } from '../../../analytics/api';
import { Action } from '../../../analytics/data/events';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import toKebabCase from '../../../utils/to-kebab-case';
import type LinkDetails from './link-details';

interface Props {
  header: string;
  link: LinkDetails;
  groupContainsUnderlined?: boolean;
}

const ContentLink = ({
  header,
  link,
  groupContainsUnderlined = false,
}: Props): ReactElement => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();

  const linkClicked = useMemo(
    () =>
      createNavigationClickEvent(
        Action.FOOTER,
        link.text,
        pageType,
        pageTitle,
        router.asPath
      ),
    [link.text, pageType, pageTitle, router.asPath]
  );

  return (
    <li
      className={`${
        !link.underline && groupContainsUnderlined ? 'upto-sm:u-hidden' : ''
      }`}
    >
      <NextLink href={link.url}>
        <a
          data-testid={toKebabCase(`${header}-${link.text}`)}
          className={`type-inline-link type-inline-link--light u-inline-block u-px-px u--mx-px u-my-px ${
            link.underline ? '' : 'u-no-underline'
          }`}
          onClick={() => trackEvent(linkClicked)}
        >
          {link.text}
        </a>
      </NextLink>
    </li>
  );
};

export default ContentLink;
