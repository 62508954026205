import { useRouter } from 'next/router';
import ExpandableDropdown from './expandable-dropdown';
import { trackEvent } from '../../../analytics/api';
import { createEducationNavigationClickEvent } from '../../../analytics/events/create-education-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import TourStepWrapper from '../tour/tour-step-wrapper';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import { useTour } from '../../contexts/tour/use-tour';
import { useMenuOptions } from './hooks/use-menu-options';
import { ProductTourRestartButton } from '../tour/product-tour-restart-button';

const HeaderMenuTablet = () => {
  const router = useRouter();
  const { pageType, pageTitle } = useAnalyticsContext();
  const breakpoint = useMediaBreakpoint();
  const options = useMenuOptions();

  const { currentStep } = useTour();

  const tabletOptions = (): Array<MenuOption | SkeletonOption> => {
    if (options.length === 1) {
      const option = options[0] as MenuOption;
      return option.subOptions ?? [];
    }

    return options;
  };

  const tabletMenuTitle = options.length > 1 ? 'Explore' : 'Browse';

  if (breakpoint < MediaBreakpoints.SM || breakpoint > MediaBreakpoints.MD) {
    return null;
  }

  return (
    <div className="lg:u-hidden c-header__section c-header__section--border-r u-flex">
      <TourStepWrapper key="tour-step-0" open={currentStep === 0} />
      <ExpandableDropdown
        ariaLabel={tabletMenuTitle}
        label={tabletMenuTitle}
        options={tabletOptions()}
        onDropdownExpanded={() => {
          trackEvent(
            createEducationNavigationClickEvent(
              Action.EDUCATION_NAVIGATION_BROWSE,
              tabletMenuTitle,
              pageType,
              pageTitle,
              router.asPath
            )
          );
        }}
        initialExpanded
      />
      <ProductTourRestartButton />
    </div>
  );
};

export default HeaderMenuTablet;
