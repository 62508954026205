import type { ContentfulClientApi } from 'contentful';
import type { EducationLevel } from '../../data/education-level';
import { mapSdkToEducationLevel } from './mappers/map-sdk-to-education-level';
import { CONTENTFUL_CONTENT_TYPE } from '../../../shared/api/adapters/types';
import toContentfulOrdering from '../../../utils/to-contentful-ordering';
import { getEntriesIsomorphic } from '../../../respositories/isomorphic/contentful-repository';

export const newGetEducationLevelsForMultipleNames = (
  contentfulClient: ContentfulClientApi
) => {
  return async (names: string[]): Promise<EducationLevel[]> => {
    const response = await getEntriesIsomorphic(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.EDUCATION_LEVEL,
        'fields.name[in]': names.join(),
      },
      contentfulClient
    );

    const { items } = response;

    const mappedEducationLevels = items.map(mapSdkToEducationLevel);

    // We map name to slug, so can use the default ordering function
    return toContentfulOrdering(names, mappedEducationLevels);
  };
};

export const newGetEducationLevelsForLevel = (
  contentfulClient: ContentfulClientApi
) => {
  return async (level: string): Promise<EducationLevel[]> => {
    const response = await getEntriesIsomorphic(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.EDUCATION_LEVEL,
        'fields.level': level,
      },
      contentfulClient
    );

    const { items } = response;

    return items.map(mapSdkToEducationLevel);
  };
};

type EducationLevelCountry = 'USA' | 'GBR';

export const newGetEducationLevelsForUnifiedLevelStage = (
  contentfulClient: ContentfulClientApi
) => {
  return async (
    stage: string,
    countryCode: EducationLevelCountry
  ): Promise<EducationLevel[]> => {
    const response = await getEntriesIsomorphic(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.EDUCATION_LEVEL,
        'fields.unifiedLevelStage': stage,
        'fields.countryCode': countryCode,
      },
      contentfulClient
    );

    const { items } = response;

    return items.map(mapSdkToEducationLevel);
  };
};
