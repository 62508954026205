/* eslint-disable react/jsx-no-bind */
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import MobileMenu from './mobile-menu';
import HeaderContentsUptoSe from './header-contents-upto-se';
import HeaderContentsFromSe from './header-contents-from-se';
import HeaderNotificationArea from '../notifications/header-notification-banner';
import { useMenuOptions } from './hooks/use-menu-options';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import { useTour } from '../../contexts/tour/use-tour';

const Header: FC = () => {
  const router = useRouter();
  const { q: searchTerm } = router.query;
  const options = useMenuOptions();
  const [mobileMenuIsExpanded, setMobileMenuIsExpanded] = useState(false);
  const [mobileSearchIsExpanded, setMobileSearchIsExpanded] = useState<boolean>(
    router.route === '/search' &&
      (searchTerm !== null || searchTerm !== undefined || searchTerm !== '')
  );
  const { started } = useTour();

  const breakpoint = useMediaBreakpoint();

  const mobileMenuId = 'mobile-menu';
  const isHomepage = router.pathname === '/';

  function toggleMobileMenu(): void {
    setMobileSearchIsExpanded(false);
    setMobileMenuIsExpanded(!mobileMenuIsExpanded);
  }

  function toggleMobileSearch(): void {
    setMobileMenuIsExpanded(false);
    setMobileSearchIsExpanded(!mobileSearchIsExpanded);
  }

  useEffect(() => {
    if (router.route === '/search' && !searchTerm && !mobileSearchIsExpanded) {
      setMobileSearchIsExpanded(true);
    }
  }, [searchTerm, router.route, mobileSearchIsExpanded]);

  const [isTransparent, setIsTransparent] = useState(isHomepage);

  const setScrollTopClassName = () => {
    const hiddenDropdowns = document.querySelectorAll(
      '.c-header-dropdown__box[aria-hidden="true"]'
    );
    if (hiddenDropdowns.length === 0) {
      setIsTransparent(window.scrollY === 0);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTopClassName();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`c-header-wrapper${
        isHomepage ? ' c-header-wrapper--homepage' : ''
      } ${
        started && breakpoint >= MediaBreakpoints.LG
          ? 'u-z-above-tooltip'
          : 'u-z-header'
      }`}
    >
      <HeaderNotificationArea />
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <header
        onClick={() => setIsTransparent(false)}
        className={`c-header ${
          isHomepage
            ? `c-header${isTransparent ? ' c-header--transparent' : ''}`
            : ''
        }`}
        data-testid="header"
      >
        <a href="#main" className="c-skip-to-main">
          Skip to main content
        </a>

        {breakpoint >= MediaBreakpoints.SE && (
          <HeaderContentsFromSe
            onMenuEnter={() => setIsTransparent(false)}
            onMenuLeave={() => {
              setIsTransparent(true);
            }}
            options={options}
            isHeaderTransparent={isTransparent}
          />
        )}

        {breakpoint <= MediaBreakpoints.SM && (
          <HeaderContentsUptoSe
            mobileMenuId={mobileMenuId}
            toggleMobileMenu={toggleMobileMenu}
            mobileSearchIsExpanded={mobileSearchIsExpanded}
            toggleMobileSearch={toggleMobileSearch}
            displayGreenDot={options.some(
              (option) => option.type === 'menu-option' && option.isNew
            )}
          />
        )}
      </header>

      <MobileMenu
        expanded={mobileMenuIsExpanded}
        toggleCallback={toggleMobileMenu}
        id={mobileMenuId}
        options={options}
      />
    </div>
  );
};

export default Header;
