import { useEffect, useRef } from 'react';
import { setChildInteractivity } from '../../../utils/dom-interactivity';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}
export const Dropdown: React.FC<Props> = ({ children, isOpen }) => {
  const dropdownContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChildInteractivity(dropdownContent.current as HTMLElement, isOpen);
  }, [isOpen]);

  return (
    <div className="c-dropdown">
      <div
        ref={dropdownContent}
        className="c-dropdown__content"
        aria-hidden={!isOpen}
      >
        {children}
      </div>
    </div>
  );
};
