import type React from 'react';
import type { ReactNode } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/tooltip';
import { StaticTooltip } from '../tooltip/static-tooltip';
import { useShowTour } from './use-show-tour';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import * as colors from '../../../styles/settings/colors';
import { useTour } from '../../contexts/tour/use-tour';
import Button from '../button/button';
import crossIcon from '../../../assets/svg/sprites/cross.svg';

interface TourStepWrapperProps {
  open: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children?: ReactNode;
}

const TourStepWrapper: React.FC<TourStepWrapperProps> = ({
  open,
  placement = 'bottom',
  children,
}) => {
  const {
    handleRemindNextWeek,
    handleDontShowAgain,
    handleDismiss,
    handleFinish,
  } = useShowTour();

  const { nextStep, started, currentStep, prevStep, tourSteps } = useTour();

  const breakpoint = useMediaBreakpoint();

  if (!started) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const bottomOptions = () => {
    const remindNextWeekButton = (
      <div className="o-tooltip__option">
        <span
          tabIndex={0}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleRemindNextWeek(tourSteps[currentStep].title);
            }
          }}
          onClick={() => handleRemindNextWeek(tourSteps[currentStep].title)}
          className="o-tooltip__label u-focus-visible-outline"
        >
          Remind me next week
        </span>
      </div>
    );

    const dontShowAgainButton = (
      <div className="o-tooltip__option">
        <span
          className="o-tooltip__label u-focus-visible-outline"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleDontShowAgain();
            }
          }}
          onClick={handleDontShowAgain}
        >
          Don&apos;t show me again
        </span>
      </div>
    );

    return (
      <div className="u-bg-gray-light u-w-full u-mt-1 u-py-3 u-px-1 u-flex">
        {currentStep > 0 ? (
          <>
            {remindNextWeekButton}
            <div className="u-flex-1 u-text-center">
              {currentStep} of {tourSteps.length - 1}
            </div>
          </>
        ) : (
          <>
            {remindNextWeekButton}
            {dontShowAgainButton}
          </>
        )}
      </div>
    );
  };

  const backButton = (
    <Button
      handleButtonClick={prevStep}
      variant="secondary-dark"
      size="md"
      className="u-px-4 u-py-2 u-mr-3"
      disabled={currentStep === 0}
    >
      Back
    </Button>
  );

  const nextButton = (
    <Button
      id={`tour-next-${currentStep}`}
      handleButtonClick={nextStep}
      variant="primary"
      size="md"
      className="u-px-4 u-py-2"
      disabled={currentStep === tourSteps.length - 1}
    >
      {currentStep === 0 ? 'Show me how it works' : 'Next'}
    </Button>
  );

  const finishButton = (
    <button
      id={`tour-next-${currentStep}`}
      type="button"
      className="c-button c-button--md c-button--primary u-px-4 u-py-2"
      onClick={handleFinish}
    >
      Finish
    </button>
  );

  if (!children) {
    return (
      <StaticTooltip
        open={started && open}
        onDismiss={() => handleDismiss(tourSteps[currentStep].title)}
      >
        <div
          className="o-tooltip-content-static u-flex-col u-p-0"
          style={{ width: '400px' }}
        >
          <div className="u-p-4">
            <p className="u-mb-2">
              <b>{tourSteps[currentStep].title}</b>
            </p>
            <div className="u-mb-4">{tourSteps[currentStep].text}</div>
            <div>
              {currentStep > 0 && backButton}
              {currentStep < tourSteps.length - 1 && nextButton}
            </div>
            <button
              type="button"
              style={{ position: 'absolute', right: '10px', top: '8px' }}
              className="u-text-black u-p-1 u-focus-visible-outline"
              onClick={() => handleDismiss(tourSteps[currentStep].title)}
            >
              <svg
                viewBox={crossIcon.viewBox}
                height="24"
                width="24"
                className="u-inline"
              >
                <use href={crossIcon} />
              </svg>
            </button>
          </div>
          {bottomOptions()}
        </div>
      </StaticTooltip>
    );
  }

  return (
    <Tooltip
      open={started && open}
      onDismiss={() => handleDismiss(tourSteps[currentStep].title)}
      placement={placement}
    >
      <TooltipTrigger
        style={{
          zIndex: started && open ? 950 : 'auto',
          width: breakpoint < MediaBreakpoints.LG ? '100%' : 'auto',
          borderTop:
            breakpoint < MediaBreakpoints.SM
              ? `1px solid ${colors.gray.light}`
              : 'none',
        }}
      >
        {children}
      </TooltipTrigger>
      <TooltipContent>
        <div
          className="o-tooltip-content u-flex-col u-p-0"
          style={{ width: '400px' }}
        >
          <div className="u-p-4">
            <p className="u-mb-2">
              <b>{tourSteps[currentStep].title}</b>
            </p>
            <div className="u-mb-4">{tourSteps[currentStep].text}</div>
            <div>
              {currentStep > 0 && backButton}
              {currentStep < tourSteps.length - 1 && nextButton}
              {currentStep === tourSteps.length - 1 && finishButton}
            </div>
            <button
              type="button"
              style={{ position: 'absolute', right: '10px', top: '8px' }}
              className="u-text-black u-p-1 u-focus-visible-outline"
              onClick={() => handleDismiss(tourSteps[currentStep].title)}
            >
              <svg
                viewBox={crossIcon.viewBox}
                height="24"
                width="24"
                className="u-inline"
              >
                <use href={crossIcon} />
              </svg>
            </button>
          </div>
          {bottomOptions()}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

export default TourStepWrapper;
