import { useQuery } from 'react-query';
import { getSlugCacheKey, cacheGenre } from '../../cache';
import { userContentForMultipleSlugsFactory } from '../../content/hooks/use-multiple-slugs';
import { getGenre, getGenresForMultipleSlugs } from '../api/frontend';
import type { Genre } from '../data/genre';

export const useGenre = (slug?: string) =>
  useQuery<Genre, Error>(
    getSlugCacheKey('genre', slug as string),
    () => getGenre(slug as string),
    { enabled: Boolean(slug) }
  );

export const useGenresForMultipleSlugs =
  userContentForMultipleSlugsFactory<Genre>(
    getGenresForMultipleSlugs,
    'genre',
    cacheGenre
  );
