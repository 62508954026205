import type { AllFilterSlugs } from '../../shared/data/filter';
import naturalSort from '../../utils/natural-sort';

const getFilterOptionsFactory = (getContentFunc: any) => {
  return async (): Promise<AllFilterSlugs> => {
    const { aggregations } = await getContentFunc({
      limit: 0,
      filters: [],
      aggregations: [
        'relatedGenres.slug',
        'relatedThemes.slug',
        'relatedProductionCompanies.slug',
        'relatedPeriods.slug',
        'subType',
        'relatedRoles.slug',
        'relatedSkills.slug',
        'relatedEducationLevels.name',
        'contentType.type',
      ],
    });

    const genres = aggregations['relatedGenres.slug']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const themes = aggregations['relatedThemes.slug']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const companies = aggregations['relatedProductionCompanies.slug']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const periods = aggregations['relatedPeriods.slug']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const subTypes = aggregations.subType
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const roles = aggregations['relatedRoles.slug']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const skills = aggregations['relatedSkills.slug']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const levels = aggregations['relatedEducationLevels.name']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    const types = aggregations['contentType.type']
      .filter((x) => x.count > 0)
      .map(({ key }) => key)
      .sort(naturalSort);

    return {
      genres,
      themes,
      companies,
      periods,
      subTypes,
      roles,
      skills,
      levels,
      types,
    };
  };
};

export default getFilterOptionsFactory;
