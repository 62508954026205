import {
  Action,
  Category,
  EducationNavigationClicked,
  EducationNavigationOptionClicked,
} from '../data/events';

//  User opens an education navigation option
//  (e.g. Middle, High, Higher Education, Browse)
export const createEducationNavigationClickEvent = (
  action: Action.EDUCATION_NAVIGATION_BROWSE,
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): EducationNavigationClicked => ({
  category: Category.BROWSE,
  action,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});

// User selects an option within a navigation option
export const createEducationNavigationOptionClickEvent = (
  action:
    | Action.MIDDLE_SCHOOL
    | Action.HIGH_SCHOOL
    | Action.KEY_STAGE_3
    | Action.GCSE
    | Action.HIGHER_EDUCATION_US
    | Action.HIGHER_EDUCATION_UK
    | Action.BROWSE,
  name: string,
  pageType: string,
  pageTitle: string,
  clickedFromUrl: string
): EducationNavigationOptionClicked => ({
  category: Category.EDUCATION_NAVIGATION,
  action,
  name,
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl,
});
