import { useEffect, useMemo, useState } from 'react';
import { useGenresForMultipleSlugs } from '../../genres/hooks/genres';
import { useThemesForMultipleSlugs } from '../../themes/hooks/themes';
import { useCompaniesForMultipleSlugs } from './companies';
import { usePeriodsForMultipleSlugs } from './periods';
import { useSkillsForMultipleSlugs } from '../../skills/hooks/use-skills-by-multiple-slugs';
import { useEducationLevelsForMultipleNames } from '../../education-levels/hooks/use-education-levels-for-multiple-names';
import { getCollections } from '../../collection/api/frontend';
import type { Collection } from '../../collection/data/collection';
import { mapGenericContentToFilter } from '../utils/map-generic-content-to-filter';
import { ThemeEnum } from '../../themes/data/theme';
import type { AllFilters, Filter } from '../data/filter';
import toKebabCase from '../../utils/to-kebab-case';
import { useTypedSelector } from '../../store/hooks/use-typed-selector';
import { organisationCountryAlpha3Selector } from '../../store/user/selectors';
import { compareEntryAgeOrYearName } from '../../education-levels/utils/compare-education-levels';
import { typeMapper } from '../../utils/typeMapper';

export const useFilters = (filterSlugs, filterCounts) => {
  const [collections, setCollections] = useState<Collection[]>([]);
  const [typeFilters, setTypes] = useState<Filter[]>([]);
  const [subTypeFilters, setSubTypes] = useState<Filter[]>([]);

  const genres = useGenresForMultipleSlugs(filterSlugs.genres);
  const themes = useThemesForMultipleSlugs(filterSlugs.themes);
  const companies = useCompaniesForMultipleSlugs(filterSlugs.companies);
  const periods = usePeriodsForMultipleSlugs(filterSlugs.periods);
  const skills = useSkillsForMultipleSlugs(filterSlugs.skills);
  const levels = useEducationLevelsForMultipleNames(filterSlugs.levels);

  const countryAlpha3Code =
    useTypedSelector(organisationCountryAlpha3Selector) === 'USA'
      ? 'USA'
      : 'GBR';

  useEffect(() => {
    getCollections('subject').then((collectionsResponse) => {
      setCollections(collectionsResponse.collections);
    });
  }, []);

  const genreFilters = useMemo(() => {
    return genres.map((genre) =>
      mapGenericContentToFilter(genre, filterCounts.genreCounts)
    );
  }, [genres, filterCounts.genreCounts]);

  const courseFilters = useMemo(() => {
    return themes
      .filter(({ themeType }) => themeType === ThemeEnum.COURSE)
      .map((course) =>
        mapGenericContentToFilter(course, filterCounts.courseCounts)
      );
  }, [themes, filterCounts.courseCounts]);

  const formFilters = useMemo(() => {
    return themes
      .filter(({ themeType }) => themeType === ThemeEnum.FORM)
      .map((form) => mapGenericContentToFilter(form, filterCounts.formCounts));
  }, [themes, filterCounts.formCounts]);

  const themeFilters = useMemo(() => {
    return themes
      .filter(({ themeType }) => themeType === ThemeEnum.THEME)
      .map((theme) =>
        mapGenericContentToFilter(theme, filterCounts.themeCounts)
      );
  }, [themes, filterCounts.themeCounts]);

  useEffect(() => {
    if (filterSlugs.types?.length > 0) {
      setTypes(
        filterSlugs.types.map((value) => ({
          id: `type-${toKebabCase(value)}`,
          label: typeMapper[value],
          value,
          count:
            filterCounts.typeCounts.find((item) => item.key === value)?.count ||
            0,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSlugs.types, filterCounts.typeCounts]);

  useEffect(() => {
    if (filterSlugs.subTypes.length > 0) {
      setSubTypes(
        filterSlugs.subTypes.map((value) => ({
          id: `sub-type-${toKebabCase(value)}`,
          label: value,
          value,
          count:
            filterCounts.subTypeCounts.find((item) => item.key === value)
              ?.count || 0,
        }))
      );
    }
  }, [filterSlugs.subTypes, filterCounts.subTypeCounts]);

  const companyFilters = useMemo(() => {
    return companies.map((company) =>
      mapGenericContentToFilter(company, filterCounts.companyCounts)
    );
  }, [companies, filterCounts.companyCounts]);

  const periodFilters = useMemo(() => {
    return periods.map((period) =>
      mapGenericContentToFilter(period, filterCounts.periodCounts)
    );
  }, [periods, filterCounts.periodCounts]);

  const englishSkillFilters = useMemo(() => {
    const englishSubject = collections.find(
      (collection) => collection.slug === 'english'
    );
    const englishSkillSlugs = englishSubject?.relatedContent.map(
      (content) => content.slug
    );
    return skills
      .filter((skill) => englishSkillSlugs?.includes(skill.slug))
      .map((skill) => {
        const filter = mapGenericContentToFilter(
          skill,
          filterCounts.englishSkillCounts
        );
        return {
          ...filter,
          parent: skill.skillCategory,
        };
      });
  }, [skills, filterCounts.englishSkillCounts, collections]);

  const theatreSkillFilters = useMemo(() => {
    const theatreSubject = collections.find(
      (collection) => collection.slug === 'drama'
    );
    const theatreSkillSlugs = theatreSubject?.relatedContent.map(
      (content) => content.slug
    );
    return skills
      .filter((skill) => theatreSkillSlugs?.includes(skill.slug))
      .map((skill) => {
        const filter = mapGenericContentToFilter(
          skill,
          filterCounts.theatreSkillCounts
        );
        return {
          ...filter,
          parent: skill.skillCategory,
        };
      });
  }, [skills, filterCounts.theatreSkillCounts, collections]);

  const educationLevelFilters = useMemo(() => {
    return levels
      .filter((level) => level.countryCode === countryAlpha3Code)
      .sort(compareEntryAgeOrYearName)
      .map((level) => ({
        id: level.id,
        label: level.yearOrGrade,
        parent: level.level,
        value: level.name,
        count:
          filterCounts.levelCounts.find((item) => item.key === level.name)
            ?.count || 0,
      }));
  }, [levels, filterCounts.levelCounts, countryAlpha3Code]);

  return useMemo(() => {
    return {
      genres: genreFilters,
      courses: courseFilters,
      forms: formFilters,
      themes: themeFilters,
      types: typeFilters,
      subTypes: subTypeFilters,
      companies: companyFilters,
      periods: periodFilters,
      englishSkills: englishSkillFilters,
      theatreSkills: theatreSkillFilters,
      levels: educationLevelFilters,
    } as AllFilters;
  },[
      companyFilters, 
      courseFilters, 
      educationLevelFilters, 
      englishSkillFilters, 
      formFilters, 
      genreFilters, 
      periodFilters, 
      subTypeFilters, 
      theatreSkillFilters, 
      themeFilters, 
      typeFilters
    ]);

};
