/* eslint-disable jsx-a11y/label-has-associated-control */
import type { FC } from 'react';
import type { Filter } from '../../data/filter';
import type { AggregationCount } from '../../../elastic-search/data/aggregations';
import BannerSearchInput from './banner-search-input';
import BannerSearchDropdown from './banner-search-dropdown';

interface Props {
  toggleMobileSearch?: () => void;
  size?: 'md';
  searchBarId: string;
  searchBarWrapperRef?: React.RefObject<HTMLDivElement>;
  types?: Filter[];
  selectedTypes?: string[];
  subTypes?: Filter[];
  selectedSubTypes?: string[];
  typesWithSubtypes?: { type: string; subTypes: AggregationCount[] }[] | [];
  style?: React.CSSProperties;
  withoutDropdown?: boolean;
  hideUrlQueryParams?: boolean;
}

const BannerSearchBar: FC<Props> = ({
  toggleMobileSearch,
  size = 'md',
  searchBarId,
  searchBarWrapperRef,
  types,
  selectedTypes,
  subTypes,
  selectedSubTypes,
  typesWithSubtypes,
  style,
  withoutDropdown,
  hideUrlQueryParams = false,
}: Props) => {
  return (
    <div
      className="c-banner-search-bar u-flex u-justify-center u-align-center"
      style={{
        ...style,
      }}
    >
      {!withoutDropdown && (
        <BannerSearchDropdown
          types={types}
          selectedTypes={selectedTypes}
          selectedSubTypes={selectedSubTypes}
          subTypes={subTypes}
          typesWithSubtypes={typesWithSubtypes}
          hideUrlQueryParams={hideUrlQueryParams}
        />
      )}
      <BannerSearchInput
        searchBarId={searchBarId}
        toggleMobileSearch={toggleMobileSearch}
        size={size}
        searchBarWrapperRef={searchBarWrapperRef}
        rounded={withoutDropdown}
        hideUrlQueryParams={hideUrlQueryParams}
      />
    </div>
  );
};

export default BannerSearchBar;
