import { useEffect, useState } from 'react';
import { getPlaceholderData } from '../../cache';
import type { CoreContent } from '../../shared/data/generic';

export const userContentForMultipleSlugsFactory = <T extends CoreContent>(
  getMultipleSlugsFunc,
  contentType,
  cacheFunc
): ((slugs: string[]) => T[]) => {
  return (slugs: string[]): T[] => {
    try {
      const [content, setContent] = useState<T[]>([]);
      const [fetching, setFetching] = useState(true);

      useEffect(() => {
        if (slugs.length === 0) {
          return;
        }
        const cachedContent = getPlaceholderData<T>(contentType, slugs);
        if (content.length !== cachedContent.length) {
          setContent(cachedContent);
          return;
        }
        if (!fetching) {
          return;
        }

        const missingSlugs = slugs.filter(
          (slug) => !cachedContent.some((c) => c.slug === slug)
        );

        if (missingSlugs.length > 0) {
          getMultipleSlugsFunc(missingSlugs).then((missingContent) => {
            missingContent.forEach(cacheFunc);
            setFetching(false);
          });
        }
      }, [slugs, fetching, content]);

      return content;
    } catch (error) {
      throw new Error(`Error fetching ${contentType} for slugs: ${slugs}`);
      return [];
    }
  };
};
