import type { FC } from 'react';
import NotificationAreaContainer from '../../../ui/notifications/components/notification-area-container';
import SubscriptionExpiryBanner from './subscription-expiry-banner';

const HeaderNotificationArea: FC = () => {
  return (
    <NotificationAreaContainer name="header" maxNotifications={1}>
      <SubscriptionExpiryBanner />
    </NotificationAreaContainer>
  );
};

export default HeaderNotificationArea;
