/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import type { AggregationCount } from '../../elastic-search/data/aggregations';
import { generateFilterOptions } from '../utils/generate-filter-options';

const useFilterCounts = (
  selectedFilters,
  filterSlugs,
  getFunc,
  searchTerm?: string
): {
  typeCounts: AggregationCount[];
  subTypeCounts: AggregationCount[];
  courseCounts: AggregationCount[];
  roleCounts: AggregationCount[];
  periodCounts: AggregationCount[];
  formCounts: AggregationCount[];
  companyCounts: AggregationCount[];
  themeCounts: AggregationCount[];
  genreCounts: AggregationCount[];
  englishSkillCounts: AggregationCount[];
  theatreSkillCounts: AggregationCount[];
  levelCounts: AggregationCount[];
} => {
  const [typeCounts, setTypeCounts] = useState<AggregationCount[]>([]);
  const [subTypeCounts, setSubTypeCounts] = useState<AggregationCount[]>([]);
  const [genreCounts, setGenreCounts] = useState<AggregationCount[]>([]);
  const [courseCounts, setCourseCounts] = useState<AggregationCount[]>([]);
  const [formCounts, setFormCounts] = useState<AggregationCount[]>([]);
  const [themeCounts, setThemeCounts] = useState<AggregationCount[]>([]);
  const [companyCounts, setCompanyCounts] = useState<AggregationCount[]>([]);
  const [periodCounts, setPeriodCounts] = useState<AggregationCount[]>([]);
  const [roleCounts, setRoleCounts] = useState<AggregationCount[]>([]);
  const [englishSkillCounts, setEnglishSkillCounts] = useState<
    AggregationCount[]
  >([]);
  const [theatreSkillCounts, setTheatreSkillCounts] = useState<
    AggregationCount[]
  >([]);
  const [levelCounts, setLevelCounts] = useState<AggregationCount[]>([]);

  useEffect(() => {
    const filter = 'types';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter && slug !== 'subTypes'),
          'contentType.type',
          searchTerm
        )
      ).then((res) => {
        setTypeCounts(res.aggregations['contentType.type']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'subTypes';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter && slug !== 'types'),
          'subType',
          searchTerm
        )
      ).then((res) => {
        setSubTypeCounts(res.aggregations.subType);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'genres';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedGenres.slug',
          searchTerm
        )
      ).then((res) => {
        setGenreCounts(res.aggregations['relatedGenres.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'courses';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedThemes.slug',
          searchTerm
        )
      ).then((res) => {
        setCourseCounts(res.aggregations['relatedThemes.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'forms';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedThemes.slug',
          searchTerm
        )
      ).then((res) => {
        setFormCounts(res.aggregations['relatedThemes.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'themes';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedThemes.slug',
          searchTerm
        )
      ).then((res) => {
        setThemeCounts(res.aggregations['relatedThemes.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'companies';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedProductionCompanies.slug',
          searchTerm
        )
      ).then((res) => {
        setCompanyCounts(res.aggregations['relatedProductionCompanies.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'periods';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedPeriods.slug',
          searchTerm
        )
      ).then((res) => {
        setPeriodCounts(res.aggregations['relatedPeriods.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'roles';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedRoles.slug',
          searchTerm
        )
      ).then((res) => {
        setRoleCounts(res.aggregations['relatedRoles.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'englishSkills';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedSkills.slug',
          searchTerm
        )
      ).then((res) => {
        setEnglishSkillCounts(res.aggregations['relatedSkills.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'theatreSkills';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedSkills.slug',
          searchTerm
        )
      ).then((res) => {
        setTheatreSkillCounts(res.aggregations['relatedSkills.slug']);
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const filter = 'levels';
    if (filterSlugs.includes(filter)) {
      getFunc(
        generateFilterOptions(
          selectedFilters,
          filterSlugs.filter((slug) => slug !== filter),
          'relatedEducationLevels.name',
          searchTerm
        )
      ).then((res) => {
        setLevelCounts(res.aggregations['relatedEducationLevels.name']);
      });
    }
  }, [selectedFilters]);

  return {
    typeCounts,
    subTypeCounts,
    genreCounts,
    courseCounts,
    formCounts,
    themeCounts,
    companyCounts,
    periodCounts,
    roleCounts,
    englishSkillCounts,
    theatreSkillCounts,
    levelCounts,
  };
};

export default useFilterCounts;
