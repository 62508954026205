import { useContext } from 'react';
import { MediaBreakpointContext } from './media-breakpoint-context';
import type { MediaBreakpoints } from './types/media-breakpoints';

export function useWindowSize(): {
  mediaBreakpoint: MediaBreakpoints;
  windowWidth: number;
  windowHeight: number;
} {
  return useContext(MediaBreakpointContext);
}

export function useMediaBreakpoint(): MediaBreakpoints {
  const { mediaBreakpoint } = useWindowSize();
  return mediaBreakpoint;
}
