import { userContentForMultipleSlugsFactory } from '../../content/hooks/use-multiple-slugs';
import { cacheCompany } from '../../cache';
import { getCompaniesForMultipleSlugs } from '../api';
import type { Company } from '../data/company';

export const useCompaniesForMultipleSlugs =
  userContentForMultipleSlugsFactory<Company>(
    getCompaniesForMultipleSlugs,
    'company',
    cacheCompany
  );
