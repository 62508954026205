import { transformSlugsToIds } from '..';
import { CONTENTFUL_RELATED_CONTENT_TYPE } from './types';

type InputType = {
  field: string;
  values: string[];
};

const mapFilterIds = async ({
  field,
  values,
}: InputType): Promise<{ ids: string[]; fieldName: string }> => {
  const contentTypes: { [key: string]: string | undefined } = {
    genre: CONTENTFUL_RELATED_CONTENT_TYPE.GENRES,
    theme: CONTENTFUL_RELATED_CONTENT_TYPE.THEMES,
    role: CONTENTFUL_RELATED_CONTENT_TYPE.ROLES,
    type: 'fields.type',
    slug: 'fields.slug',
  };

  const contentType = contentTypes[field];

  if (contentType) {
    const ids =
      contentType !== 'fields.type' && contentType !== 'fields.slug'
        ? await transformSlugsToIds(field, values)
        : values;
    return {
      ids,
      fieldName: `${contentType}[in]`,
    };
  }

  throw Error('field type not recognised');
};

export default mapFilterIds;
