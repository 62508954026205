import {
  newGetThemeFromContentfulSdk,
  getThemesWithCuratedTitlesFromContentful,
  newGetThemeNameFromContentful,
  newGetThemesForMultipleSlugsFromContentful,
  newGetAllThemesFromContentfulSdk,
} from './adapters/contentful-theme-content-api';
import { getContentfulClient } from '../../../config/contentful-config';
import type {
  GetTheme,
  GetThemes,
  GetThemesForMultipleSlugs,
  GetThemesWithCuratedTitles,
} from './types';
import type { GetContentNameBySlug } from '../../../auth/utils/authentication';

export const getTheme: GetTheme = newGetThemeFromContentfulSdk(
  getContentfulClient()
);

export const getThemeName: GetContentNameBySlug = newGetThemeNameFromContentful(
  getContentfulClient()
);

export const getThemes: GetThemes = newGetAllThemesFromContentfulSdk(
  getContentfulClient()
);

export const getThemesWithCuratedTitles: GetThemesWithCuratedTitles =
  getThemesWithCuratedTitlesFromContentful;

export const getThemesForMultipleSlugs: GetThemesForMultipleSlugs =
  newGetThemesForMultipleSlugsFromContentful(getContentfulClient());
