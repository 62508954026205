export const getEducationNavigationClickEventTitle = (
  optionTitle: string,
  isUS: boolean
) => {
  if (optionTitle === 'Higher Education') {
    if (isUS) {
      return 'US Higher Education';
    }
    return 'UK Higher Education';
  }
  return optionTitle;
};
