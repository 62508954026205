import { request } from 'graphql-request';
import type { ContentfulClientApi } from 'contentful';

import type { Genre } from '../../../data/genre';
import type { GetGenres } from '../types';
import {
  CONTENTFUL_CONTENT_TYPE,
  CONTENTFUL_RELATED_CONTENT_TYPE,
} from '../../../../shared/api/adapters/types';
import { getNumberOfCuratedTitlesAssociatedWithContent } from '../../../../titles/api/frontend/adapters/contentful-title-content-api';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';

import mapSdkResponseToGenre from './mappers/map-sdk-response-to-genre';
import { mapToGenre } from './mappers/map-to-genre';
import { CONTENTFUL_URL } from '../../../../config/contentful-config';
import toContentfulOrdering from '../../../../utils/to-contentful-ordering';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';

export const newGetGenreFromContentful = (client: ContentfulClientApi) => {
  return async (slug: string): Promise<Genre> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GENRE,
        'fields.slug': slug,
      },
      client
    );

    const item = response.items[0];
    return mapSdkResponseToGenre(item);
  };
};

export const newGetAllGenresFromContentful = (client: ContentfulClientApi) => {
  return async (): Promise<Genre[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GENRE,
        order: 'fields.name',
      },
      client
    );

    return response.items.map(mapSdkResponseToGenre);
  };
};

// eslint-disable-next-line import/prefer-default-export
export const getGenresFromContentful: GetGenres = async () => {
  const query = `query GET_GENRES {
  genreCollection(order: name_ASC) {
    items {
      sys {
        id
      }
      name
      slug
      linkedFrom {
        titleCollection {
          total
        }
      }
      mainImage {
        altText
        focus
        relatedImage {
          url
        }
      }
    }
  }
}`;

  const response = await request(CONTENTFUL_URL, query);
  const { items } = response.genreCollection;

  return items.map(mapToGenre);
};

export const newGetGenreNameFromContentful = (
  client: ContentfulClientApi
): GetContentNameBySlug => {
  return async (slug: string, filters: Filters): Promise<string> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GENRE,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        select: 'fields.name',
      },
      client
    );

    return response.items[0].fields.name;
  };
};

type GetNumberOfContentAssociatedWithGenre = (
  content: Genre,
  associatedContentType: CONTENTFUL_RELATED_CONTENT_TYPE
) => Promise<number>;

const getGenresWithContent = async (
  getNumberOfContentAssociatedWithGenre: GetNumberOfContentAssociatedWithGenre
): Promise<Genre[]> => {
  const genres = await getGenresFromContentful();

  const genresWithNumberOfAssociatedContent = await Promise.all(
    genres.map(async (genre) => {
      const numberOfContentAssociatedWithGenre =
        await getNumberOfContentAssociatedWithGenre(
          genre,
          CONTENTFUL_RELATED_CONTENT_TYPE.GENRES
        );
      return { genre, numberOfContentAssociatedWithGenre };
    })
  );

  return genresWithNumberOfAssociatedContent
    .filter(
      ({ numberOfContentAssociatedWithGenre }) =>
        numberOfContentAssociatedWithGenre > 0
    )
    .map(({ genre }) => genre);
};

export const getGenresWithCuratedTitlesFromContentful = async (): Promise<
  Genre[]
> => {
  return getGenresWithContent(getNumberOfCuratedTitlesAssociatedWithContent);
};

export function newGetGenresForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: any): Promise<Genre[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.GENRE,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const { items } = response;

    const mappedProductions = items.map(mapSdkResponseToGenre);

    return toContentfulOrdering(slugs, mappedProductions);
  };
}
