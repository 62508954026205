export const parseRouterQuery = (
  query?: string | string[]
): undefined | string => {
  switch (typeof query) {
    case 'undefined':
      return undefined;
    case 'string':
      return query;
    default:
      return query[0];
  }
};

export const parseRouterQueryIntoArray = (
  query?: string | string[]
): undefined | string[] => {
  switch (typeof query) {
    case 'undefined':
      return undefined;
    case 'string':
      return [query];
    default:
      return query;
  }
};
