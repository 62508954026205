import dayjs from 'dayjs';
import { ED_NAV_NEW_PILL_EXPIRY_DATE } from '../ed-nav';

const option: MenuOption = {
  type: 'menu-option',
  title: 'High School',
  subOptions: [
    {
      type: 'menu-option',
      url: '/content/study-units/high-school/ela',
      title: 'ELA',
      ariaLabel: 'E L A',
      category: 'Study units',
    },
    {
      type: 'menu-option',
      url: '/content/study-units/high-school/theatre',
      title: 'Theatre',
      category: 'Study units',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&type=title&level=us_grade_09&level=us_grade_10&level=us_grade_11&level=us_grade_12',
      title: 'Titles',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&type=person&sort_by=name-asc&level=us_grade_09&level=us_grade_10&level=us_grade_11&level=us_grade_12',
      title: 'People',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&subType=Poetry+recital&sort_by=name-asc&level=us_grade_09&level=us_grade_10&level=us_grade_11&level=us_grade_12',
      title: 'Poetry',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/collections',
      title: 'Collections',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&subType=Essay&subType=Graphic+novel&subType=Play+text&subType=Poem&subType=Story&subType=Study+guide&subType=Workbook&subType=Audio+essay&subType=E-learning&subType=Lecture&subType=Workshop&subType=Audio+interview&subType=Documentary&subType=Interview&subType=Lesson+plan&subType=Teaching+resource&englishSkill=character&englishSkill=close-reading&englishSkill=collaborating&englishSkill=comparing-and-contrasting&englishSkill=context&englishSkill=language-and-literary-devices&englishSkill=presenting&englishSkill=setting&englishSkill=structure&englishSkill=theme&sort_by=name-asc&level=us_grade_09&level=us_grade_10&level=us_grade_11&level=us_grade_12',
      title: 'ELA skills',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&subType=Essay&subType=Graphic+novel&subType=Play+text&subType=Poem&subType=Story&subType=Study+guide&subType=Workbook&subType=Audio+essay&subType=E-learning&subType=Lecture&subType=Workshop&subType=Audio+interview&subType=Documentary&subType=Interview&subType=Lesson+plan&subType=Teaching+resource&sort_by=name-asc&level=us_grade_09&level=us_grade_10&level=us_grade_11&level=us_grade_12&theatreSkill=acting-methods-and-techniques&theatreSkill=costume-and-make-up&theatreSkill=design-process&theatreSkill=devising&theatreSkill=directing&theatreSkill=evaluating-theatre&theatreSkill=lighting&theatreSkill=movement-and-physicality&theatreSkill=playwriting&theatreSkill=rehearsal-and-collaboration&theatreSkill=script-analysis&theatreSkill=set-and-props&theatreSkill=sound&theatreSkill=theatre-history&theatreSkill=theatre-management&theatreSkill=understanding-context&theatreSkill=voice',
      title: 'Theatre skills',
      category: 'Browse',
    },
  ],
  isNew: ED_NAV_NEW_PILL_EXPIRY_DATE.isAfter(dayjs()),
  isEducationLevel: true,
};

export default option;
