import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import type { Genre } from '../../../../data/genre';

export default function mapSdkResponseToGenre(item: any): Genre {
  const { name, slug, shortIntroduction, longIntroduction, mainImage } =
    item.fields;

  const { id } = item.sys;

  return {
    name,
    slug,
    id,
    shortIntroduction,
    longIntroduction: longIntroduction ?? null,
    image: mapSdkToImage(mainImage),
    totalTitles: 0,
  };
}
