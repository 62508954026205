import type { ContentfulClientApi } from 'contentful';
import { request } from 'graphql-request';

import type { Theme } from '../../../data/theme';
import type { ThemeType } from '../../../data/theme-type';

import type { GetThemes } from '../types';
import {
  CONTENTFUL_CONTENT_TYPE,
  CONTENTFUL_RELATED_CONTENT_TYPE,
} from '../../../../shared/api/adapters/types';
import { getNumberOfCuratedTitlesAssociatedWithContent } from '../../../../titles/api/frontend/adapters/contentful-title-content-api';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';

import { mapToTheme } from './mappers/map-to-theme';
import mapToThemeType from './mappers/map-to-theme-type';
import mapSdkResponseToTheme from './mappers/map-sdk-to-theme';

import { CONTENTFUL_URL } from '../../../../config/contentful-config';
import toContentfulOrdering from '../../../../utils/to-contentful-ordering';
import type {
  Filters,
  GetContentNameBySlug,
} from '../../../../auth/utils/authentication';

export function newGetThemeFromContentfulSdk(
  contentfulClient: ContentfulClientApi
) {
  return async (slug: string): Promise<Theme> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.THEME,
        'fields.slug': slug,
      },
      contentfulClient
    );

    return mapSdkResponseToTheme(response.items[0]);
  };
}

export function newGetAllThemesFromContentfulSdk(
  contentfulClient: ContentfulClientApi
) {
  return async (): Promise<Theme[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.THEME,
        order: 'fields.name',
      },
      contentfulClient
    );

    return response.items.map(mapSdkResponseToTheme);
  };
}

export const getThemesFromContentful: GetThemes = async () => {
  const query = `query GET_THEMES {
      themeCollection(order: name_ASC) {
        items {
          sys {
            id
          }
          slug
          name
          linkedFrom {
            titleCollection {
              total
            }
          }
          mainImage {
            altText
            focus
            relatedImage {
              url
            }
          }
          type
        }
      }
    }`;

  const response = await request(CONTENTFUL_URL, query);
  const { items } = response.themeCollection;

  return items.map((item) => mapToTheme(item));
};

export const newGetThemeNameFromContentful = (
  client: ContentfulClientApi
): GetContentNameBySlug => {
  return async (slug: string, filters: Filters): Promise<string> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.THEME,
        'fields.slug': slug,
        'fields.relatedContentProfiles.sys.id[nin]':
          filters.blockedProfiles.join(','),
        select: 'fields.name',
      },
      client
    );

    return response.items[0].fields.name;
  };
};

type GetNumberOfContentAssociatedWithTheme = (
  content: Theme,
  associatedContentType: CONTENTFUL_RELATED_CONTENT_TYPE
) => Promise<number>;

const getThemesWithContent = async (
  getNumberOfContentAssociatedWithTheme: GetNumberOfContentAssociatedWithTheme
): Promise<ThemeType[]> => {
  const themes = await getThemesFromContentful();

  const themesWithNumberOfAssociatedContent = await Promise.all(
    themes.map(async (theme) => {
      const numberOfContentAssociatedWithTheme =
        await getNumberOfContentAssociatedWithTheme(
          theme,
          CONTENTFUL_RELATED_CONTENT_TYPE.THEMES
        );
      return { theme, numberOfContentAssociatedWithTheme };
    })
  );

  const themesWithContent = themesWithNumberOfAssociatedContent
    .filter(
      ({ numberOfContentAssociatedWithTheme }) =>
        numberOfContentAssociatedWithTheme > 0
    )
    .map(({ theme }) => theme);

  return mapToThemeType(themesWithContent);
};

export const getThemesWithCuratedTitlesFromContentful = async (): Promise<
  ThemeType[]
> => {
  return getThemesWithContent(getNumberOfCuratedTitlesAssociatedWithContent);
};

export function newGetThemesForMultipleSlugsFromContentful(
  contentfulClient: ContentfulClientApi
) {
  return async (slugs: any): Promise<Theme[]> => {
    const response = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.THEME,
        'fields.slug[in]': slugs.join(),
      },
      contentfulClient
    );

    const { items } = response;

    const mappedProductions = items.map(mapSdkResponseToTheme);

    return toContentfulOrdering(slugs, mappedProductions);
  };
}
