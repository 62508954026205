import { useContext } from 'react';
import { TourContext, type TourContextValue } from './tour-context';

export function useTourContext() {
  return useContext(TourContext);
}

export function useTour(): TourContextValue {
  return useTourContext();
}
