/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useMemo, useRef } from 'react';
import type { FC } from 'react';
import { useRouter } from 'next/router';
import URI from 'urijs';
import searchIcon from '../../../assets/svg/sprites/search.svg';
import * as breakpoints from '../../../styles/settings/screens';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { searchTermSelector } from '../../../store/search/selectors';
import { useAppDispatch } from '../../../store/hooks/use-app-dispatch';
import { setSearchTerm } from '../../../store/search/search.slice';
import { trackEvent } from '../../../analytics/api';
import { Action } from '../../../analytics/data/events';
import { createSearchClickEvent } from '../../../analytics/events/create-search-click-event';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';

interface Props {
  toggleMobileSearch?: () => void;
  size?: 'md' | 'lg';
  searchBarId: string;
}

const SearchBar: FC<Props> = ({
  toggleMobileSearch,
  size = 'md',
  searchBarId,
}: Props) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { pageType, pageTitle } = useAnalyticsContext();
  const inputElement = useRef<HTMLInputElement>(null);
  const breakpointSm = parseInt(breakpoints['upto-sm'].max, 10);
  const breakpointMd = parseInt(breakpoints['upto-md'].max, 10);
  const windowWidth = window.innerWidth;
  const placeholder = `Search ${
    breakpointSm < windowWidth && windowWidth < breakpointMd
      ? 'DT'
      : 'Digital Theatre'
  }+`;
  const isSearchPage = router && router.pathname === '/search';
  const searchTerm: string = useTypedSelector(searchTermSelector);

  useEffect(() => {
    if (!isSearchPage) {
      dispatch(setSearchTerm(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    if (router.query.q) {
      dispatch(setSearchTerm(router.query.q as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.q]);

  const submitSearchClickedEvent = useMemo(() => {
    return createSearchClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      Action.SUBMIT,
      'submit'
    );
  }, [pageTitle, pageType, router.asPath]);

  const handleSubmit = (e): void => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: isSearchPage ? 'smooth' : 'auto' });
    trackEvent(submitSearchClickedEvent);

    const value = searchTerm.trim();
    if (value) {
      const query = URI('/search').addQuery('q', value);
      router.push(query.toString());
    }
  };

  const handleChange = (e): void => {
    dispatch(setSearchTerm(e.target.value));
  };

  const clearSearchClickedEvent = useMemo(() => {
    return createSearchClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      Action.CLEAR,
      'clear'
    );
  }, [pageTitle, pageType, router.asPath]);

  const handleClearClick = (): void => {
    dispatch(setSearchTerm(''));
    trackEvent(clearSearchClickedEvent);

    if (inputElement.current) {
      inputElement.current.focus();
    }
  };

  const handleInputBlur = (e): void => {
    if (toggleMobileSearch && searchTerm === '' && e.relatedTarget === null) {
      toggleMobileSearch();
    }
  };

  const focusSearchClickedEvent = useMemo(() => {
    return createSearchClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      Action.START_SEARCH,
      'start-search'
    );
  }, [pageTitle, pageType, router.asPath]);

  return (
    <form
      className={`c-search-bar c-search-bar--${size}`}
      onSubmit={handleSubmit}
      aria-labelledby={`${searchBarId}-label`}
    >
      <input
        type="search"
        id={`${searchBarId}-input`}
        className="c-search-bar__input"
        onChange={handleChange}
        placeholder={placeholder}
        ref={inputElement}
        value={searchTerm}
        onBlur={handleInputBlur}
        onFocus={(e) => !e.target.value && trackEvent(focusSearchClickedEvent)}
        autoComplete="off"
        data-testid="search-bar-input"
      />
      <label
        htmlFor={`${searchBarId}-input`}
        className="c-search-bar__label"
        id={`${searchBarId}-label`}
      >
        <svg
          className="c-search-bar__svg"
          viewBox={searchIcon.viewBox}
          aria-hidden="true"
        >
          <use href={searchIcon} />
        </svg>
        <span className="u-sr-only">Search Digital Theatre+</span>
      </label>
      <button
        type="button"
        className="c-search-bar__button c-round-button c-round-button--cancel c-round-button--dark"
        onClick={handleClearClick}
        disabled={searchTerm === ''}
        aria-label="Clear search text"
        data-testid="clear-search-input"
      >
        <div />
        <div />
      </button>
    </form>
  );
};

export default SearchBar;
