import { mapGqlToImage } from '../../../../../shared/api/adapters/map-to-image';
import type { Theme, ThemeEnum } from '../../../../data/theme';

export function mapToTheme(contentfulTheme: any): Theme {
  const { sys, slug, name, shortIntroduction, linkedFrom, mainImage, type } =
    contentfulTheme;

  const totalTitles = linkedFrom.titleCollection.total;

  return {
    id: sys.id,
    slug,
    name,
    shortIntroduction: shortIntroduction || '',
    longIntroduction: null,
    totalTitles,
    image: mapGqlToImage(mainImage),
    themeType: type as ThemeEnum,
  };
}
