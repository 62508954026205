/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo } from 'react';
import type { ReactElement } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import dtpLogo from '../../../assets/svg/sprites/dtp.svg';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { trackEvent } from '../../../analytics/api';

const FooterLogo = (): ReactElement => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();

  const linkClicked = useMemo(
    () =>
      createNavigationClickEvent(
        Action.FOOTER,
        'home-logo',
        pageType,
        pageTitle,
        router.asPath
      ),
    [pageType, pageTitle, router.asPath]
  );

  return (
    <Link href="/">
      <a
        className="u--ml-2 u-mr-4 c-square-button c-square-button--light c-square-button--lg"
        data-testid="footer-logo-link-from-sm"
        aria-label="DT+ Home"
        title="DT+ Home"
        onClick={() => trackEvent(linkClicked)}
      >
        <svg
          className="u-w-12 u-h-5 u-mx-px u--mb-px"
          viewBox={dtpLogo.viewBox}
          aria-hidden="true"
        >
          <use href={dtpLogo} />
        </svg>
      </a>
    </Link>
  );
};

export default FooterLogo;
