import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';

import { getMyIpAddress } from '../../api';

const ipTooltip =
  'We do not store your IP address, it is included in case you need to contact us about IP Authentication.';

const YourIpAddress = (): ReactElement | null => {
  const [ipAddress, setIpAddress] = useState<string>('');

  useEffect(() => {
    getMyIpAddress().then(setIpAddress);
  }, []);

  if (!ipAddress) {
    return null;
  }

  return (
    <span title={ipTooltip}>
      Your IP address: <span className="version-number">{ipAddress}</span>
    </span>
  );
};

export default YourIpAddress;
