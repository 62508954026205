import type { NextRouter } from 'next/router';
import URI from 'urijs';

export const filterSelect = ({ name, value, checked, router }) => {
  const currentUrl = URI(router.asPath);
  currentUrl.removeSearch('page');

  if (checked) {
    currentUrl.addSearch(name, value);
  } else {
    currentUrl.removeSearch(name, value);
  }

  router.push(currentUrl.toString(), undefined, { shallow: true });
};

export const checkboxClickHandler = (e, router: NextRouter): void => {
  e.persist();
  const { name, value, checked } = e.target;

  filterSelect({ name, value, checked, router });
};
