import { userContentForMultipleSlugsFactory } from '../../content/hooks/use-multiple-slugs';
import { cachePeriod } from '../../cache';
import { getPeriodsForMultipleSlugs } from '../api';
import type { Period } from '../data/period';

export const usePeriodsForMultipleSlugs =
  userContentForMultipleSlugsFactory<Period>(
    getPeriodsForMultipleSlugs,
    'period',
    cachePeriod
  );
