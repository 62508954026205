import dayjs from 'dayjs';

// This is the date of the public release of the ed nav feature
export const ED_NAV_RELEASE_DATE = '2024-05-10';
export const ED_NAV_NEW_PILL_EXPIRY_DATE = dayjs(ED_NAV_RELEASE_DATE).add(
  6,
  'week'
);

export const UK_ED_NAV_RELEASE_DATE = '2024-08-10';
export const UK_ED_NAV_NEW_PILL_EXPIRY_DATE = dayjs(ED_NAV_RELEASE_DATE).add(
  6,
  'week'
);

export const HE_RELEASE_DATE = '2024-06-10';
export const HE_NEW_PILL_EXPIRY_DATE = dayjs(HE_RELEASE_DATE).add(6, 'week');
