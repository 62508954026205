export const typeMapper = {
  production: 'Productions',
  interview: 'Interviews',
  title: 'Titles',
  series: 'Series',
  person: 'People',
  workshop: 'Workshops',
  guide: 'Guides',
  lessonPlan: 'Lesson Plans',
};
