import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { trackEvent } from '../../../analytics/api';
import {
  createTourBeRemindedEvent,
  createTourDisabledEvent,
  createTourDismissedEvent,
  createTourFinishedEvent,
  createTourRestartedEvent,
} from '../../../analytics/events/create-tour-event';
import toKebabCase from '../../../utils/to-kebab-case';
import { useTour } from '../../contexts/tour/use-tour';

export const useShowTour = () => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();
  const { quitTour } = useTour();

  const handleDismiss = (stepName: string) => {
    trackEvent(
      createTourDismissedEvent(
        `education-navigation-${toKebabCase(stepName)}`,
        pageType,
        pageTitle,
        router.asPath
      )
    );
    const tomorrow = dayjs().add(1, 'day').toISOString();
    localStorage.setItem('showTour', tomorrow);
    quitTour();
  };

  const handleRemindNextWeek = (stepName: string) => {
    trackEvent(
      createTourBeRemindedEvent(
        `education-navigation-${toKebabCase(stepName)}`,
        pageType,
        pageTitle,
        router.asPath
      )
    );
    const nextWeek = dayjs().add(1, 'week').toISOString();
    localStorage.setItem('showTour', nextWeek);
    quitTour();
  };

  const handleDontShowAgain = () => {
    trackEvent(
      createTourDisabledEvent(
        'education-navigation',
        pageType,
        pageTitle,
        router.asPath
      )
    );
    localStorage.setItem('showTour', 'false');
    quitTour();
  };

  const handleFinish = () => {
    trackEvent(
      createTourFinishedEvent(
        'education-navigation',
        pageType,
        pageTitle,
        router.asPath
      )
    );
    localStorage.setItem('showTour', 'false');
    quitTour();
  };

  const handleReset = () => {
    trackEvent(
      createTourRestartedEvent(
        'education-navigation',
        pageType,
        pageTitle,
        router.asPath
      )
    );
    localStorage.removeItem('showTour');
  };

  return {
    handleRemindNextWeek,
    handleDontShowAgain,
    handleDismiss,
    handleReset,
    handleFinish,
  };
};
