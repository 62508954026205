import { mapSdkToImage } from '../../../../../shared/api/adapters/map-to-image';
import { Theme, ThemeEnum } from '../../../../data/theme';

export default function mapSdkResponseToTheme(item: any): Theme {
  const { name, slug, shortIntroduction, longIntroduction, mainImage, type } =
    item.fields;

  const { id } = item.sys;

  const themeType = ThemeEnum[type.toUpperCase()];
  return {
    name,
    slug,
    id,
    shortIntroduction: shortIntroduction || '',
    longIntroduction: longIntroduction ?? null,
    image: mapSdkToImage(mainImage),
    totalTitles: 0,
    themeType,
  };
}
