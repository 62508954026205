import { useState, useEffect } from 'react';
import { searchContent } from '../../shared/api';
import type { AggregationCount } from '../../elastic-search/data/aggregations';

const useTypesWithSubtypes = (uniqueTypes: string[]) => {
  const [typesWithSubtypes, setTypesWithSubtypes] = useState<
    { type: string; subTypes: AggregationCount[] }[] | []
  >([]);

  useEffect(() => {
    if (!uniqueTypes.length) return;

    const sortedUniqueTypes = uniqueTypes.sort();
    const typesData: {
      type: string;
      subTypes: AggregationCount[];
    }[] = [];
    const promises: Promise<any>[] = [];

    sortedUniqueTypes.forEach((type) => {
      const promise = searchContent({
        limit: 0,
        order: 'name-asc',
        filters: [{ field: 'type', values: [type] }],
        aggregations: ['subType'],
      }).then((data) => {
        typesData.push({ type, subTypes: data.aggregations.subType });
      });
      promises.push(promise);
    });
    Promise.all(promises).then(() => {
      const uniqueTypesSet = new Set(typesData);
      const filteredTypes = Array.from(uniqueTypesSet);
      setTypesWithSubtypes(filteredTypes);
    });
  }, [uniqueTypes]);

  return typesWithSubtypes;
};

export default useTypesWithSubtypes;
