import type { ContentfulClientApi } from 'contentful';
import type {
  GetCollections,
  GetCollectionsResponse,
  GetSubjectCollection,
} from '../collection-types';
import getEntriesFromContentfulViaSdk from '../../../../shared/api/adapters/get-entries-from-contentful';
import {
  CONTENTFUL_CONTENT_TYPE,
  CONTENTFUL_SORT,
} from '../../../../shared/api/adapters/types';
import { mapSdkResponseToCollection } from './mappers/map-sdk-to-collection';
import { getEntries } from '../../../../respositories/frontend/contentful-repository';

export const getCollectionsFromContentfulAdapter = (
  client: ContentfulClientApi
): GetCollections => {
  return async (collectionType): Promise<GetCollectionsResponse> => {
    const { items } = await getEntriesFromContentfulViaSdk(
      CONTENTFUL_CONTENT_TYPE.COLLECTION,
      client,
      {
        order: CONTENTFUL_SORT.ASCENDING,
        filters: [{ field: 'type', values: [collectionType] }],
      }
    );

    return {
      collections: items.map(mapSdkResponseToCollection),
    };
  };
};

export const getSubjectCollectionFromContentfulAdapter = (
  client: ContentfulClientApi
): GetSubjectCollection => {
  return async (slug, isUs) => {
    const { items } = await getEntriesFromContentfulViaSdk(
      CONTENTFUL_CONTENT_TYPE.COLLECTION,
      client,
      {
        order: CONTENTFUL_SORT.ASCENDING,
        filters: [
          { field: 'slug', values: [slug] },
          { field: 'type', values: ['subject'] },
        ],
      }
    );

    return items.map((i) => mapSdkResponseToCollection(i, isUs))[0] || null;
  };
};

export const getSubjectCollectionByUsNameFromContentfulAdapter = (
  client: ContentfulClientApi
): GetSubjectCollection => {
  return async (usSubjectName, isUs) => {
    const { items } = await getEntries(
      {
        content_type: CONTENTFUL_CONTENT_TYPE.COLLECTION,
        'fields.usSubjectName[match]': usSubjectName,
      },
      client
    );

    const matchingItem = items.find(
      (i) =>
        i.fields.usSubjectName?.toLowerCase() === usSubjectName.toLowerCase()
    );

    return mapSdkResponseToCollection(matchingItem, isUs) || null;
  };
};
