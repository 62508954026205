import toKebabCase from "../../utils/to-kebab-case";
import { Action, Category, ClickedFromLocation, FilterSelectAll } from "../data/events";

interface CreateFilterSelectAllEventInput {
  pageType: string,
  pageTitle: string,
  title: string,
  routerAsPath: string;
  clearing?: boolean,
}
export const createFilterSelectAllEvent = ({
  pageType,
  pageTitle,
  title,
  routerAsPath,
  clearing=false,
}: CreateFilterSelectAllEventInput): FilterSelectAll => ({
  category: Category.FILTER,
  action: !clearing ? Action.FILTER_SELECT_ALL : Action.FILTER_CLEAR_ALL,
  name: toKebabCase(title),
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl: routerAsPath,
  clickedFromLocation: ClickedFromLocation.VERTICAL_FILTERS,
});
