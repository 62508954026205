import { getSkillsFromBff } from '../../../collection/api/frontend/get-skills-from-bff';
import { ConsoleLogger } from '../../../shared/services/logging/console-logger';
import type { SkillCardData } from '../../data/Skill';

const logger = new ConsoleLogger();

export const getSkills = async (skillSlugs: string[]) => {
  const response = await getSkillsFromBff(skillSlugs);
  const { items, error } = response;

  if (error) {
    logger.error(error);
    return [];
  }

  return items as SkillCardData[];
};

export const getSkill = async (skillSlug: string) => {
  const response = await getSkillsFromBff([skillSlug]);
  const { items, error } = response;

  if (error) {
    logger.error(error);
    return [];
  }

  return items[0] as SkillCardData;
};
