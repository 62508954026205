import { getContentfulClient } from '../../config/contentful-config';
import {
  newGetEducationLevelsForLevel,
  newGetEducationLevelsForMultipleNames,
  newGetEducationLevelsForUnifiedLevelStage,
} from './adapters/contentful-api';

export const getEducationLevelsForMultipleNames =
  newGetEducationLevelsForMultipleNames(getContentfulClient());

export const getEducationLevelsForLevel = newGetEducationLevelsForLevel(
  getContentfulClient()
);

export const getEducationLevelsForUnifiedLevelStage =
  newGetEducationLevelsForUnifiedLevelStage(getContentfulClient());
