import type { ReactElement } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import settings from '../../../assets/svg/sprites/settings.svg';
import { useMediaBreakpoint } from '../../contexts/media-breakpoints/use-media-breakpoint';
import { MediaBreakpoints } from '../../contexts/media-breakpoints/types/media-breakpoints';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { isAdminSelector } from '../../../store/user/selectors';

interface Props {
  children: ReactElement;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export function SettingsLinkWrapper({ children }: Props): ReactElement {
  const mobileMenu = useMediaBreakpoint() <= MediaBreakpoints.XS;
  const isAdmin = useTypedSelector(isAdminSelector);

  return isAdmin ? (
    <Wrapper>
      {!mobileMenu && (
        <div
          className="c-header__section c-header__section--border-r"
          style={{ height: 'inherit' }}
        >
          <Link href="/account/content-controls">
            <a
              data-testid="settings-link"
              className={mobileMenu ? 'u-pl-2 u-pr-1' : 'c-header-button'}
            >
              <svg
                className="u-w-9 u-h-9"
                viewBox={settings.viewBox}
                aria-hidden="true"
              >
                <use href={settings} />
              </svg>
            </a>
          </Link>
        </div>
      )}
      {mobileMenu && (
        <Link href="/account/content-controls">
          <a
            data-testid="settings-link"
            className={mobileMenu ? 'u-pl-2 u-pr-1' : 'c-header-button'}
          >
            <svg
              className="u-w-9 u-h-9"
              viewBox={settings.viewBox}
              aria-hidden="true"
            >
              <use href={settings} />
            </svg>
          </a>
        </Link>
      )}
      {children}
    </Wrapper>
  ) : (
    children
  );
}
