import toKebabCase from '../../utils/to-kebab-case';
import { Action, Category, ClickedFromLocation, FilterSelectClicked } from '../data/events';

interface CreateFilterSelectClickEventInput {
  pageType: string,
  pageTitle: string,
  expanded: boolean,
  title: string,
  routerAsPath: string;
}

export const createFilterSelectClickEvent = ({
  pageType,
  pageTitle,
  expanded,
  title,
  routerAsPath
}: CreateFilterSelectClickEventInput): FilterSelectClicked => ({
  category: Category.FILTER,
  action: !expanded ? Action.FILTER_EXPAND : Action.FILTER_COLLAPSE,
  name: toKebabCase(title),
  value: 1,
  clickedFromPageType: pageType,
  clickedFromPageTitle: pageTitle,
  clickedFromUrl: routerAsPath,
  clickedFromLocation: ClickedFromLocation.VERTICAL_FILTERS,
});
