/* eslint-disable react/jsx-props-no-spreading */
import { useRouter } from 'next/router';
import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import type { FC, ReactNode, SyntheticEvent } from 'react';

import { trackEvent } from '../../../analytics/api';
import { createCheckboxClickEvent } from '../../../analytics/events/create-checkbox-click-event';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';

interface Props {
  id: string;
  name?: string;
  value?: string;
  inline?: boolean;
  block?: boolean;
  defaultChecked?: boolean;
  controls?: string;
  expanded?: boolean;
  onChangeHandler?: (e: SyntheticEvent) => void;
  children?: ReactNode;
  checked?: boolean;
  postMatomoEvent?: boolean;
  ariaLabel?: string;
  noBorder?: boolean;
  fullWidth?: boolean;
  isChild?: boolean;
  indeterminate?: boolean;
  isParent?: boolean;
}

interface InputProps {
  id: string;
  name: string;
  defaultChecked?: boolean;
  'aria-controls'?: string;
  'aria-expanded'?: boolean;
  onChange?: (e: SyntheticEvent) => void;
  value?: string;
  checked?: boolean;
  'data-parent'?: boolean;
}

const Checkbox: FC<Props> = ({
  id,
  name,
  value,
  inline,
  block,
  defaultChecked,
  controls,
  expanded,
  onChangeHandler,
  children,
  checked,
  postMatomoEvent = true,
  ariaLabel,
  noBorder = false,
  fullWidth = false,
  isChild = false,
  indeterminate = false,
  isParent = false,
}: Props) => {
  const router = useRouter();
  const { pageTitle, pageType } = useAnalyticsContext();

  const prevIndeterminateState = useRef(false);
  const [noTransitionClass, setNoTransitionClass] = useState('');

  const inputProps: InputProps = {
    id,
    name: name || id,
    defaultChecked,
    'aria-controls': controls,
    'aria-expanded': expanded,
  };

  const checkboxRef: RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate ?? false;

      if (prevIndeterminateState.current && !(indeterminate ?? false)) {
        setNoTransitionClass('no-transition');
      } else {
        setNoTransitionClass('');
      }

      prevIndeterminateState.current = indeterminate ?? false;
    }
  }, [indeterminate]);

  const wrappedChildren =
    children && typeof children === 'string' ? (
      <span>{children}</span>
    ) : (
      children
    );

  // If there is no value, the value attribute doesn't get included in the DOM.
  if (value !== undefined) {
    inputProps.value = value;
  }

  if (checked !== undefined) {
    inputProps.checked = checked;
  }

  if (onChangeHandler !== undefined) {
    inputProps.onChange = onChangeHandler;
  }

  if (isParent) {
    inputProps['data-parent'] = true;
  }

  const checkboxClickedEvent = useMemo(() => {
    return createCheckboxClickEvent(
      pageType,
      pageTitle,
      router.asPath,
      defaultChecked,
      name,
      value
    );
  }, [pageTitle, pageType, router.asPath, defaultChecked, name, value]);

  return (
    <div
      data-testid="checkbox"
      className={`c-checkbox ${inline ? 'u-inline-flex' : ''}`}
      style={isChild ? { marginLeft: '1rem' } : {}}
    >
      <input
        ref={checkboxRef}
        type="checkbox"
        className={noTransitionClass}
        onClick={() => {
          if (postMatomoEvent) {
            trackEvent(checkboxClickedEvent);
          }
        }}
        {...inputProps}
      />
      <label
        aria-label={ariaLabel}
        htmlFor={id}
        className={`${block ? 'u-flex' : 'u-inline-flex'} ${
          noBorder ? 'u-border-0' : 'u-border-2'
        } ${fullWidth ? 'u-w-full' : ''}`}
        style={{ padding: '0' }}
      >
        {wrappedChildren}
      </label>
    </div>
  );
};

export default Checkbox;
