import type { FC, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useAuthContext } from '../../../auth/providers/auth-context';
import LoggedInMenuDropdown from './logged-in-menu-dropdown';
import HeaderDropdown from './header-dropdown';
import accountIcon from '../../../assets/svg/sprites/account-outline.svg';
import { onLogin } from '../../../auth/utils/login';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { userNameSelector } from '../../../store/user/selectors';
import { SettingsLinkWrapper } from './settings-link-wrapper';
// This shows an account-icon-svg-only element that looks like the user button while the user info is being loaded.
const LoadingIndicator: FC = (): ReactElement => (
  <div className="u-px-5 u-flex u-h-full u-items-center">
    <svg
      className="u-h-12 u-w-12 u--m-2 u-flex-shrink-0"
      viewBox={accountIcon.viewBox}
      aria-hidden="true"
    >
      <use href={accountIcon} />
    </svg>
  </div>
);

interface Props {
  onDropdownEnter?: () => void;
  onDropdownLeave?: () => void;
}

const UserDetails: FC<Props> = ({
  onDropdownEnter,
  onDropdownLeave,
}: Props): ReactElement => {
  const userName = useTypedSelector(userNameSelector);
  const nameArray = userName.split(' ');
  const firstNameInitial = nameArray[0].charAt(0);
  const lastNameInitial = nameArray[nameArray.length - 1].charAt(0);

  // Circle avatar
  let userInfo = (
    <div className="c-user-info u-ml-3">
      <div className="c-user-info__text">
        <div className="c-user-info__avatar" data-matomo-mask>
          {firstNameInitial + lastNameInitial}
        </div>
        <div className="c-user-info__role" />
      </div>
    </div>
  );

  if (nameArray.length < 2) {
    userInfo = (
      <svg
        className="u-h-12 u-w-12 u--m-2 u-flex-shrink-0"
        viewBox={accountIcon.viewBox}
        aria-hidden="true"
      >
        <use href={accountIcon} />
      </svg>
    );
  }

  return (
    <SettingsLinkWrapper>
      <div
        className="u-h-full"
        onMouseEnter={onDropdownEnter}
        onMouseLeave={onDropdownLeave}
      >
        <HeaderDropdown
          id="user-dropdown"
          buttonContent={userInfo}
          align="right"
        >
          <LoggedInMenuDropdown />
        </HeaderDropdown>
      </div>
    </SettingsLinkWrapper>
  );
};

const SignInButton: FC = (): ReactElement => {
  const router = useRouter();
  const { appAuthService } = useAuthContext();

  return (
    <button
      type="button"
      className="c-header-button u-w-24"
      onClick={() => onLogin(appAuthService!, router)}
    >
      Sign in
    </button>
  );
};

const UserMenu: FC<Props> = ({
  onDropdownEnter,
  onDropdownLeave,
}: Props): ReactElement => {
  const { appAuthState } = useAuthContext();
  const userName = useTypedSelector(userNameSelector);

  if (appAuthState?.isAuthenticated) {
    if (!userName) {
      return <LoadingIndicator />;
    }

    return (
      <UserDetails
        onDropdownEnter={onDropdownEnter}
        onDropdownLeave={onDropdownLeave}
      />
    );
  }

  return <SignInButton />;
};

export default UserMenu;
