import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

const SHOW_DELAY = 80;

const ProgressBar = () => {
  const router = useRouter();
  const [state, setState] = useState<'initialised' | 'started' | 'finished'>(
    'initialised'
  );

  useEffect(() => {
    let routeChangeStartTimerId: ReturnType<typeof setTimeout>;
    let routeChangeCompleteTimerId: ReturnType<typeof setTimeout>;

    const onRouteChangeStart = () => {
      setState('initialised');

      routeChangeStartTimerId = setTimeout(
        () => setState((s) => (s === 'initialised' ? 'started' : s)),
        SHOW_DELAY
      );
    };

    const onRouteChangeComplete = (url: string) => {
      const isThemeIndex = url.match(/\/themes\/?[^/]*$/);
      const isContentIndex = url.match(/\/content\/?[^/]*$/);

      routeChangeCompleteTimerId = setTimeout(
        () => setState('finished'),
        isThemeIndex || isContentIndex ? 200 : 0
      );
    };

    const onRouteChangeError = () => setState('finished');

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      clearTimeout(routeChangeStartTimerId);
      clearTimeout(routeChangeCompleteTimerId);
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeError);
    };
  }, [router.events]);

  return <div className={`c-progress-bar c-progress-bar--${state}`} />;
};

export default ProgressBar;
