import { useState, useRef, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { getInteractiveElements } from '../../../utils/dom-interactivity';
import { useTour } from '../../contexts/tour/use-tour';

function focusPrev(interactiveElements): void {
  const currentlyFocused = document.activeElement;

  if (currentlyFocused && currentlyFocused instanceof HTMLElement) {
    const index = interactiveElements.indexOf(currentlyFocused);

    if (index > 0) {
      interactiveElements[index - 1].focus();
    }
  }
}

function focusNext(interactiveElements): void {
  const currentlyFocused = document.activeElement;

  if (currentlyFocused && currentlyFocused instanceof HTMLElement) {
    const index = interactiveElements.indexOf(currentlyFocused);

    if (index < interactiveElements.length - 1) {
      interactiveElements[index + 1].focus();
    }
  }
}

interface Props {
  id: string;
  buttonContent: ReactNode | string;
  children: ReactNode;
  align: 'right' | 'left';
  padX?: boolean;
  ariaLabel?: string;
  onDropdownCollapsed?: () => void;
  onDropdownExpanded?: () => void;
  displayGreenDot?: boolean;
  showActiveUnderline?: boolean;
  initialExpanded?: boolean;
}

const HeaderDropdown: FC<Props> = ({
  id,
  buttonContent,
  children,
  align,
  padX,
  ariaLabel,
  onDropdownCollapsed,
  onDropdownExpanded,
  displayGreenDot,
  showActiveUnderline = false,
  initialExpanded,
}: Props) => {
  // null by default, indicates the component was rendered but not opened
  const [isExpanded, setIsExpanded] = useState<boolean | null>(null);
  // We need to track if the mouse is currently over the dropdown
  // for the tablet click to work correctly (avoid updating isExpanded twice)
  const [mouseEntered, setMouseEntered] = useState(false);

  const button = useRef<HTMLButtonElement>(null);
  const box = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  let interactiveElements: Array<HTMLElement> = [];

  const { currentStep, started } = useTour();

  function handleInteractiveChildKeyDown(e): void {
    if (e.key === 'ArrowUp') {
      focusPrev(interactiveElements);
      e.preventDefault();
    }

    if (e.key === 'ArrowDown') {
      focusNext(interactiveElements);
      e.preventDefault();
    }
  }

  function setChildInteractivity(
    parentElement: HTMLElement,
    isInteractive: boolean
  ): void {
    parentElement
      .querySelectorAll<HTMLElement>('li:not(.c-second-level-links li)')
      .forEach((interactiveElement) => {
        if (interactiveElement instanceof HTMLElement) {
          if (isInteractive) {
            interactiveElement.setAttribute('tabIndex', '0');
          } else {
            interactiveElement.setAttribute('tabIndex', '-1');
          }
        }
      });
  }

  useEffect(() => {
    if (box.current) {
      setChildInteractivity(box.current, isExpanded ?? false);
    }
  }, [isExpanded, children]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    interactiveElements = getInteractiveElements(container.current!);

    interactiveElements.forEach((element) => {
      element.addEventListener('keydown', handleInteractiveChildKeyDown);
    });

    // Remove event listeners if/when this component gets removed(?)
    return (): void => {
      interactiveElements.forEach((element) => {
        element.removeEventListener('keydown', handleInteractiveChildKeyDown);
      });
    };
  }, []);

  useEffect(() => {
    // This is helping us to understand if we are opening the
    // dropdown or just rendering it for the first time.
    if (isExpanded === null) {
      return;
    }

    if (isExpanded) {
      onDropdownExpanded?.();
    } else {
      onDropdownCollapsed?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const alignR = align === 'right';

  function handleBlur(e): void {
    if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
      setIsExpanded(false);
    }
  }

  useEffect(() => {
    if (initialExpanded && currentStep === 1) {
      setIsExpanded(true);
    }
    if (!started || currentStep === 0) {
      setIsExpanded(false);
    }
  }, [initialExpanded, currentStep, started]);

  return (
    <div
      className="c-header-dropdown"
      data-testid="header-dropdown"
      ref={container}
      onBlur={handleBlur}
      onMouseEnter={() => {
        // We need to defer slightly in order to allow tablet users
        // to open/close the dropdown with a click
        setTimeout(() => {
          setMouseEntered(true);
          setIsExpanded(true);
        }, 5);
      }}
      onMouseLeave={() => {
        if (!initialExpanded || currentStep === 0) {
          setMouseEntered(false);
          setIsExpanded(false);
        }
      }}
    >
      <div className="c-header-dropdown__button-wrapper u-flex u-items-center u-flex-col u-h-full">
        <button
          type="button"
          id={`${id}-button`}
          className="c-header-dropdown__button u-w-full u-mx-3"
          aria-controls={`${id}-box`}
          aria-expanded={isExpanded ?? false}
          ref={button}
          aria-label={ariaLabel}
          data-testid={`${id}-button`}
          tabIndex={0}
          onClick={() => {
            // Enable support for tablet users that want to close the menu
            // by clicking on it since they do not have a mouse to leave the menu
            if (mouseEntered) {
              if (isExpanded) {
                setIsExpanded(false);
                setMouseEntered(false);
              }
            } else {
              setIsExpanded(!isExpanded);
            }
          }}
        >
          {buttonContent}
          <span
            className={`c-header-dropdown__new-icon ${
              !displayGreenDot && 'u-invisible'
            }`}
          />
        </button>
        {showActiveUnderline && (
          <div
            className="c-header-dropdown__line"
            style={{
              visibility: isExpanded ? 'visible' : 'hidden',
            }}
          />
        )}
      </div>

      <div
        id={`${id}-box`}
        className={`c-header-dropdown__box ${
          alignR ? 'u-right-0' : 'u-left-0'
        } ${padX ? 'sm:u-px-5' : ''}`}
        aria-hidden={!isExpanded}
        ref={box}
        tabIndex={-1}
        style={{ zIndex: 3001 }}
      >
        {children}
      </div>
    </div>
  );
};

export default HeaderDropdown;
