/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { FunctionComponent } from 'react';

import type LinkDetails from './link-details';
import BottomBar from './bottom-bar';
import ContentLinkGroup from './content-link-group';
import FooterLogo from './footer-logo';
import TocButton from './toc-button';
import QuickStartResourcesButton from './quick-start-resources-button';

const titlesLinkData: Array<LinkDetails> = [
  { url: '/titles?genre=comedy', text: 'Comedy' },
  { url: '/titles?genre=drama', text: 'Drama' },
  { url: '/titles?genre=history', text: 'History' },
  { url: '/titles?genre=romance', text: 'Romance' },
  { url: '/titles?genre=satire', text: 'Satire' },
  { url: '/titles?genre=tragedy', text: 'Tragedy' },
  { url: '/titles?genre=tragicomedy', text: 'Tragicomedy' },
  { url: '/titles', text: 'All Titles', underline: true },
];

const genresLinkData: Array<LinkDetails> = [
  { url: '/genres', text: 'All Genres', underline: true },
];

const themesLinkData: Array<LinkDetails> = [
  { url: '/themes', text: 'All Themes', underline: true },
];

const peopleLinkData: Array<LinkDetails> = [
  { url: '/people?role=actor', text: 'Actors' },
  { url: '/people?role=composer', text: 'Composers' },
  { url: '/people?role=director', text: 'Directors' },
  {
    url: '/people?role=production-departments',
    text: 'Production departments',
  },
  { url: '/people?role=writer', text: 'Writers' },
  { url: '/people', text: 'All People', underline: true },
];

const helpLinkData: Array<LinkDetails> = [
  {
    url: 'https://support.digitaltheatreplus.com/kb-tickets/new',
    text: 'Contact us',
  },
  { url: 'https://status.digitaltheatreplus.com', text: 'Service status' },
];

const libraryLinkData: Array<LinkDetails> = [
  { url: 'https://marc.digitaltheatreplus.com', text: 'MARC records' },
  { url: '/reports', text: 'COUNTER 5 reports' },
  { url: '/api/content/reports', text: 'Content reports' },
];

const Footer: FunctionComponent = () => {
  return (
    <footer className="c-footer">
      <div className="c-footer__top">
        <div className="c-footer__top__logo-container">
          <FooterLogo />
        </div>

        <div className="c-footer__top__button-container">
          <TocButton />
          <QuickStartResourcesButton />
        </div>
      </div>

      <div className="c-footer__middle">
        <ContentLinkGroup header="Titles" links={titlesLinkData} />
        <div>
          <ContentLinkGroup header="Genres" links={genresLinkData} />
          <ContentLinkGroup header="Themes" links={themesLinkData} />
        </div>
        <ContentLinkGroup header="People" links={peopleLinkData} />
        <div>
          <ContentLinkGroup header="Help and Support" links={helpLinkData} />
          <ContentLinkGroup header="Libraries" links={libraryLinkData} />
        </div>
      </div>
      <BottomBar />
    </footer>
  );
};

export default Footer;
