import type { Filter } from '../data/filter';

type Parent = string;
type Children = Filter[];
type FiltersGroupedByParent = Record<Parent, Children>;

const groupFiltersByParent = (array: Filter[]): FiltersGroupedByParent => {
  return array.reduce((result, current) => {
    // eslint-disable-next-line no-param-reassign
    (result[current.parent as string] =
      result[current.parent as string] || []).push(current);
    return result;
  }, {});
};

export default groupFiltersByParent;
