import useContentDirectoryFactory from '../../content/hooks/use-directory';
import type { Content } from '../../content/data/content';
import { searchContent } from '../api';
import useFilterOptionsFactory from '../../content/hooks/use-filter-options';
import { cacheContent } from '../../cache/content';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useSearchedContentDirectory = useContentDirectoryFactory<Content>(
  searchContent,
  cacheContent
);

export const useSearchedContentFilterOptions =
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useFilterOptionsFactory(searchContent);
