const parseFilterValues = (
  filterValues: string[] | string | undefined
): string[] => {
  const valuesArrayOrString = filterValues || [];
  const valuesArray =
    typeof valuesArrayOrString === 'string'
      ? [valuesArrayOrString]
      : valuesArrayOrString;
  return valuesArray;
};

export default parseFilterValues;
