import dayjs from 'dayjs';
import { UK_ED_NAV_NEW_PILL_EXPIRY_DATE } from '../ed-nav';

const option: MenuOption = {
  type: 'menu-option',
  title: 'GCSE',
  subOptions: [
    {
      type: 'menu-option',
      url: '/content/study-units/gcse/english',
      title: 'English',
      category: 'Study units',
    },
    {
      type: 'menu-option',
      url: '/content/study-units/gcse/drama',
      title: 'Drama',
      category: 'Study units',
    },
    {
      type: 'menu-option',
      url: '/search?q=&type=title&sort_by=name-asc&level=uk_year_10&level=uk_year_11',
      title: 'Titles',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&level=uk_year_10&level=uk_year_11&type=person',
      title: 'People',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&level=uk_year_10&level=uk_year_11&subType=Poetry+recital',
      title: 'Poetry',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/collections',
      title: 'Collections',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&subType=Audio+interview&subType=Documentary&subType=Interview&subType=Audio+essay&subType=E-learning&subType=Lecture&subType=Workshop&subType=Lesson+plan&subType=Teaching+resource&subType=Essay&subType=Graphic+novel&subType=Play+text&subType=Poem&subType=Story&subType=Study+guide&subType=Workbook&englishSkill=character&englishSkill=close-reading&englishSkill=comparing-and-contrasting&englishSkill=context&englishSkill=language-and-literary-devices&englishSkill=setting&englishSkill=structure&englishSkill=theme&englishSkill=collaborating&englishSkill=presenting&level=uk_year_10&level=uk_year_11',
      title: 'English skills',
      category: 'Browse',
    },
    {
      type: 'menu-option',
      url: '/search?q=&sort_by=name-asc&subType=Audio+interview&subType=Documentary&subType=Interview&subType=Audio+essay&subType=E-learning&subType=Lecture&subType=Workshop&subType=Lesson+plan&subType=Teaching+resource&subType=Essay&subType=Graphic+novel&subType=Play+text&subType=Poem&subType=Story&subType=Study+guide&subType=Workbook&level=uk_year_10&level=uk_year_11&theatreSkill=acting-methods-and-techniques&theatreSkill=costume-and-make-up&theatreSkill=design-process&theatreSkill=devising&theatreSkill=directing&theatreSkill=evaluating-theatre&theatreSkill=lighting&theatreSkill=movement-and-physicality&theatreSkill=playwriting&theatreSkill=rehearsal-and-collaboration&theatreSkill=script-analysis&theatreSkill=set-and-props&theatreSkill=sound&theatreSkill=theatre-history&theatreSkill=theatre-management&theatreSkill=understanding-context&theatreSkill=voice',
      title: 'Drama skills',
      category: 'Browse',
    },
  ],
  isNew: UK_ED_NAV_NEW_PILL_EXPIRY_DATE.isAfter(dayjs()),
  isEducationLevel: true,
};

export default option;
