import { useMenuOptions } from '../header/hooks/use-menu-options';
import { useAuthContext } from '../../../auth/providers/auth-context';
import { useShowTour } from './use-show-tour';
import { useTour } from '../../contexts/tour/use-tour';
import questionIcon from '../../../assets/svg/sprites/question.svg';
import { getEducationLevel } from '../../../auth/utils/get-education-level';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { organisationCategorySelector } from '../../../store/user/selectors';
import { usePersonalisedMenu } from '../header/hooks/use-personalised-menu';

export const ProductTourRestartButton: React.FC = () => {
  const options = useMenuOptions();
  const { appAuthState } = useAuthContext();
  const { handleReset } = useShowTour();
  const { startTour } = useTour();
  const educationLevel = getEducationLevel(
    useTypedSelector(organisationCategorySelector)
  );
  const { isUS } = usePersonalisedMenu();

  if (
    !(
      (educationLevel === 'Middle School' || educationLevel === 'Secondary') &&
      isUS
    )
  ) {
    return null;
  }

  const handleForceStartTour = () => {
    handleReset();
    startTour();
  };

  const hasProductTour =
    appAuthState?.isAuthenticated &&
    options.filter((option) => option.type !== 'skeleton-option').length > 2;

  return hasProductTour ? (
    <div
      onClick={handleForceStartTour}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          handleForceStartTour();
          setTimeout(() => {
            const next = document.querySelector<HTMLElement>('#tour-next-0');
            if (next) {
              next.focus();
            }
          }, 20);
        }
      }}
      tabIndex={0}
      role="button"
      className="u-flex u-px-3 u-cursor-pointer u-focus-visible-outline-inner"
    >
      <svg viewBox={questionIcon.viewBox} width={31}>
        <use href={questionIcon} />
      </svg>
    </div>
  ) : null;
};
