import type { ReactElement } from 'react';

import toKebabCase from '../../../utils/to-kebab-case';
import ContentLink from './content-link';
import type LinkDetails from './link-details';

interface Props {
  header: string;
  links: LinkDetails[];
}

const ContentLinkGroup = ({ header, links }: Props): ReactElement => {
  const groupContainsUnderlined = links.some((link) => link.underline);

  return (
    <div className="o-link-group u-flex-col">
      <h2
        id={`footer-links-${toKebabCase(header)}`}
        className="o-link-group__title type-h3"
      >
        {header}
      </h2>

      <ul aria-labelledby={`footer-links-${toKebabCase(header)}`}>
        {links.map((link) => (
          <ContentLink
            key={toKebabCase(`${header}-${link.text}`)}
            header={header}
            link={link}
            groupContainsUnderlined={groupContainsUnderlined}
          />
        ))}
      </ul>
    </div>
  );
};

export default ContentLinkGroup;
