import type { EducationLevel } from "../data/education-level";

export function compareEntryAgeOrYearName(
  a: EducationLevel,
  b: EducationLevel,
): number {
  const aYearEntryAge = a.entryAge;
  const bYearEntryAge = b.entryAge;

  if (aYearEntryAge !== bYearEntryAge) {
    return aYearEntryAge - bYearEntryAge;
  }

  return a.name.localeCompare(b.name);
}
