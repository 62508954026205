/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import NextLink from 'next/link';
import { useMemo } from 'react';
import type { ReactElement } from 'react';
import { useRouter } from 'next/router';

import type LinkDetails from './link-details';
import { trackEvent } from '../../../analytics/api';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import toKebabCase from '../../../utils/to-kebab-case';

interface Props {
  link: LinkDetails;
  lastLink?: boolean;
}
const BottomBarLink = ({ link, lastLink = false }: Props): ReactElement => {
  const { pageTitle, pageType } = useAnalyticsContext();
  const router = useRouter();

  const linkClicked = useMemo(
    () =>
      createNavigationClickEvent(
        Action.FOOTER,
        link.text,
        pageType,
        pageTitle,
        router.asPath
      ),
    [link.text, pageType, pageTitle, router.asPath]
  );

  return (
    <li className={lastLink ? 'u-mr-auto' : ''}>
      <NextLink href={link.url} prefetch={false}>
        <a
          data-testid={toKebabCase(`generic-${link.text}`)}
          className={`type-inline-link type-inline-link--light u-inline-block u-px-px u--mx-px u-my-px ${
            link.underline ? '' : ' u-no-underline'
          }`}
          onClick={() => trackEvent(linkClicked)}
        >
          {link.text}
        </a>
      </NextLink>
    </li>
  );
};

export default BottomBarLink;
