/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { FC, MouseEvent } from 'react';
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { createNavigationClickEvent } from '../../../analytics/events/create-navigation-click-event';
import { Action } from '../../../analytics/data/events';
import { useAnalyticsContext } from '../../../analytics/providers/analytics-context';
import { trackEvent } from '../../../analytics/api';
import Pill from '../pills/pill';
import { createEducationNavigationOptionClickEvent } from '../../../analytics/events/create-education-navigation-click-event';
import toKebabCase from '../../../utils/to-kebab-case';
import { usePersonalisedMenu } from './hooks/use-personalised-menu';

interface Props {
  url: string | undefined;
  title: string;
  onClickCallback: (() => void) | undefined;
  onHoverCallback?: (() => void) | undefined;
  isNew?: boolean;
  dropdownTitle?: string;
  expandable?: boolean;
  isCurrentOptionExpanded?: boolean;
  ariaLabel?: string;
  parentOptionTitle?: string;
}

const TopLevelLink: FC<Props> = ({
  url,
  title,
  onClickCallback,
  onHoverCallback,
  isNew = false,
  dropdownTitle,
  expandable = false,
  isCurrentOptionExpanded,
  ariaLabel,
  parentOptionTitle,
}: Props) => {
  const router = useRouter();
  const { pageTitle, pageType } = useAnalyticsContext();
  const { isUS } = usePersonalisedMenu();

  const isCurrentPage = (location): boolean =>
    `/${router.asPath.split('/')[1]}` === location;

  const event = useMemo(
    () =>
      createNavigationClickEvent(
        Action.HEADER,
        title,
        pageType,
        pageTitle,
        router.asPath
      ),
    [title, pageTitle, pageType, router.asPath]
  );

  const optionClickedEvent = useMemo(() => {
    const dropdownTitleToActionMap = {
      'Middle School': Action.MIDDLE_SCHOOL,
      'High School': Action.HIGH_SCHOOL,
      'Key stage 3': Action.KEY_STAGE_3,
      GCSE: Action.GCSE,
      'Higher Education': isUS
        ? Action.HIGHER_EDUCATION_US
        : Action.HIGHER_EDUCATION_UK,
      Browse: Action.BROWSE,
    };

    return () => {
      return dropdownTitle
        ? createEducationNavigationOptionClickEvent(
            dropdownTitleToActionMap[dropdownTitle],
            title,
            pageType,
            pageTitle,
            router.asPath
          )
        : undefined;
    };
  }, [isUS, dropdownTitle, title, pageType, pageTitle, router.asPath]);

  // This lets us style the link as soon as mouseUp happens, but sticks around
  // between pages, so we also have to remove it from the other not-clicked links
  function handleClick(
    e: MouseEvent | React.KeyboardEvent<HTMLAnchorElement>
  ): void {
    e.stopPropagation();

    const edNavClickedEvent = optionClickedEvent();
    trackEvent(edNavClickedEvent ?? event);

    document.querySelectorAll('.js-clicked').forEach((element) => {
      element.classList.remove('js-clicked');
    });
    if (e instanceof MouseEvent) {
      (e as MouseEvent).currentTarget.classList.add('js-clicked');
    }

    if (onClickCallback) {
      // Allow time for the user to see which link they pressed, instead of instantly closing
      setTimeout(onClickCallback, 200);
    }
  }

  const linkStyle =
    // eslint-disable-next-line no-nested-ternary
    expandable && isCurrentOptionExpanded
      ? { background: '#F5F6F7', borderRadius: '4px', marginBottom: 0 }
      : {};

  const linkItem = (
    <>
      {isNew && (
        <Pill
          className="u-mr-2"
          variant="green"
          size="rel-sm"
          style={{ lineHeight: 1.4 }} // Custom line-height just for top-level navigation links
        >
          NEW
        </Pill>
      )}
      <span className="c-link-item-text">{title}</span>
    </>
  );

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <li
      key={toKebabCase(title)}
      className={`u-focus-visible-outline ${
        expandable ? 'c-expandable-option' : ''
      }`}
      style={linkStyle}
      onMouseOver={() => onHoverCallback?.()}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowRight') {
          e.preventDefault(); // prevent scroll
          const subOptions = document.querySelector('.c-second-level-links');
          const lis = subOptions?.querySelectorAll('li');
          lis?.forEach((li) => {
            (li as HTMLElement).setAttribute('tabindex', '0');
          });
          const firstChild = subOptions?.firstElementChild;
          if (firstChild) {
            (firstChild as HTMLElement).focus();
          }
        }
      }}
      onFocus={() => onHoverCallback?.()}
      onBlur={(e) => {
        e.stopPropagation();
        if (!parentOptionTitle) {
          return;
        }
        const subOptions = document.querySelector('.c-second-level-links');
        const lis = subOptions?.querySelectorAll('li');

        if (lis && e.currentTarget === lis[lis.length - 1]) {
          const topLevelLinks = document.querySelectorAll('#menu-options');
          const tabletTopLevelLinks = topLevelLinks[topLevelLinks.length - 1];
          const topLevelOptions = tabletTopLevelLinks?.querySelectorAll('li');
          let targetElement: HTMLElement | null = null;
          topLevelOptions?.forEach((element) => {
            if (
              element.textContent?.trim().includes(parentOptionTitle.trim())
            ) {
              targetElement = element;
            }
          });
          if (targetElement) {
            (targetElement as HTMLElement).focus();
          }
        }
      }}
    >
      {url ? (
        <Link href={url}>
          <a
            data-testid={`${toKebabCase(title)}-link`}
            aria-current={isCurrentPage({ url }) ? 'page' : undefined}
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleClick(e);
                router.push(url);
              }
            }}
            aria-label={ariaLabel}
            role="link"
            tabIndex={0}
          >
            {linkItem}
          </a>
        </Link>
      ) : (
        <a
          data-testid={`${toKebabCase(title)}-link`}
          onClick={handleClick}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              handleClick(e);
            }
          }}
          style={{ cursor: 'pointer' }}
          aria-label={ariaLabel}
          role="link"
          tabIndex={0}
        >
          {linkItem}
        </a>
      )}
    </li>
  );
};

export default TopLevelLink;
