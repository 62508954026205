import URI from 'urijs';

export const getSkillsFromBff = async (skillSlugs: string[]) => {
  const uri = new URI(`${window.location.origin}/api/skills`);
  uri.addQuery('limit', 1000);

  try {
    const response = await fetch(uri.toString(), {
      method: 'POST',
      body: JSON.stringify({
        slug: [...skillSlugs],
      }),
    });

    const data = await response.json();
    return { items: data.items, error: null };
  } catch (error) {
    return { error, items: null };
  }
};
