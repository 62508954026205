/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import getFilterOptionsFactory from '../factories/get-filter-options';
import type { AllFilterSlugs } from '../../shared/data/filter';

const useFilterOptionsFactory = (getContentFunc) => {
  const getFilterOptionsFunc = getFilterOptionsFactory(getContentFunc);

  return () => {
    const [filterSlugs, setFilterSlugs] = useState<AllFilterSlugs>({
      genres: [],
      themes: [],
      types: [],
      subTypes: [],
      companies: [],
      periods: [],
      roles: [],
      skills: [],
      levels: [],
    });

    useEffect(() => {
      getFilterOptionsFunc().then((filters) => {
        setFilterSlugs(filters);
      });
    }, []);

    return filterSlugs;
  };
};

export default useFilterOptionsFactory;
