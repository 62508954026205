export const getGroupedOptionsByCategory = <T extends { category?: string }>(
  options: Array<T>
) => {
  const groupedOptions: Record<string, Array<T>> = {};
  options.forEach((option) => {
    const category = option.category || 'Undefined';
    if (!groupedOptions[category]) {
      groupedOptions[category] = [];
    }
    groupedOptions[category].push(option);
  });

  return groupedOptions;
};
