import type { Filter, FilterTreeNode } from "../data/filter";
import groupFiltersByParent from "./group-filters-by-parent";

export const buildFilterTree = (filters: Filter[]) => {
  const filtersGroupedByParents = groupFiltersByParent(filters.filter(f => "parent" in f));
  const tree: FilterTreeNode[] = Object.entries(filtersGroupedByParents).map(([parent, children]) => {
    return ({
      id: parent,
      label: parent,
      value: parent,
      parent,
      type: "subType",
      children,
      count: children.filter((f) => f.count > 0).length,
    });
  });
  const nodesWithoutParents = filters.filter(f => !("parent" in f)).map(filter => {
    return {
      ...filter,
      type: "type",
    } as FilterTreeNode;
  });
  return tree.concat(nodesWithoutParents);
};
