import { usePersonalisedMenu } from './use-personalised-menu';
import { useTypedSelector } from '../../../../store/hooks/use-typed-selector';
import {
  organisationCategorySelector,
  organisationIdSelector,
} from '../../../../store/user/selectors';
import { getEducationLevel } from '../../../../auth/utils/get-education-level';
import { useAuthContext } from '../../../../auth/providers/auth-context';
import { useShowNewUKNavigation } from '../../../hooks/use-show-new-uk-navigation';
import ks3 from '../../../../config/menu-options/ks3';
import gcse from '../../../../config/menu-options/gcse';
import middleSchool from '../../../../config/menu-options/middle-school';
import highSchool from '../../../../config/menu-options/high-school';
import higherEducationUS from '../../../../config/menu-options/higher-education-us';
import higherEducationUK from '../../../../config/menu-options/higher-education-uk';
import { useShowHEStudyUnitLinks } from '../../../hooks/use-show-he-study-unit-links';

export const useMenuOptions = (): (MenuOption | SkeletonOption)[] => {
  const { isUS, isUK, showEnglish, showDrama } = usePersonalisedMenu();
  const educationLevel = getEducationLevel(
    useTypedSelector(organisationCategorySelector)
  );
  const orgId = useTypedSelector(organisationIdSelector);
  const { appAuthState } = useAuthContext();

  const showNewUKNavigation = useShowNewUKNavigation();
  const showHEStudyUnitLinks = useShowHEStudyUnitLinks();

  let options: Array<MenuOption | SkeletonOption> = [];

  if (orgId === '' && appAuthState?.isAuthenticated) {
    options = [
      {
        id: 'first-skeleton',
        type: 'skeleton-option',
      },
      {
        id: 'second-skeleton',
        type: 'skeleton-option',
      },
    ];
  }

  if (
    (educationLevel === 'Middle School' || educationLevel === 'Secondary') &&
    isUS
  ) {
    options = [middleSchool, highSchool];
  }

  if (
    (educationLevel === 'Middle School' || educationLevel === 'Secondary') &&
    isUK &&
    showNewUKNavigation
  ) {
    options = [ks3, gcse];
  }

  if (educationLevel === 'Higher Education' && isUS) {
    options = [higherEducationUS];
  }

  if (educationLevel === 'Higher Education' && isUK) {
    options = [higherEducationUK];
  }

  if (!showHEStudyUnitLinks) {
    options = options.map((option) => {
      if ('subOptions' in option && Array.isArray(option.subOptions)) {
        return {
          ...option,
          subOptions: option.subOptions.filter(
            (subOption) => subOption.title !== 'Study Units'
          ),
        };
      }
      return option;
    });
  }

  const browseSubOptions: MenuSubOption[] = [
    {
      type: 'menu-option',
      url: '/titles',
      title: 'Titles',
    },
    {
      type: 'menu-option',
      url: '/genres',
      title: 'Genres',
    },
    {
      type: 'menu-option',
      url: '/themes',
      title: 'Themes',
    },
    {
      type: 'menu-option',
      url: '/people',
      title: 'People',
    },
    {
      type: 'menu-option',
      url: '/series',
      title: 'Collections',
    },
  ];

  if (!isUS) {
    if (showEnglish) {
      browseSubOptions.push({
        type: 'menu-option',
        title: 'English',
        url: '/subjects/english',
      });
    }
    if (showDrama) {
      browseSubOptions.push({
        type: 'menu-option',
        title: 'Drama',
        url: '/subjects/drama',
      });
    }
  }

  options.push({
    type: 'menu-option',
    title: 'Browse',
    subOptions: browseSubOptions,
  });

  return options;
};
