import { getEntries } from '../../../respositories/frontend/contentful-repository';
import mapFilterIds from './map-filter-ids';
import {
  CONTENTFUL_SORT,
  GetEntriesFromContentful,
  GetEntriesResponse,
} from './types';

const NAME_ORDER = 'fields.name';
const REVERSE_NAME_ORDER = '-fields.name';

const getEntriesFromContentfulViaSdk: GetEntriesFromContentful = async (
  content_type,
  client,
  options = {
    skip: 0,
    limit: 100,
    order: CONTENTFUL_SORT.ASCENDING,
    filters: [],
    include: 1,
  }
): Promise<GetEntriesResponse> => {
  const { skip, limit, order, filters, include, curated } = options;

  const formattedOrder =
    order === CONTENTFUL_SORT.DESCENDING ? REVERSE_NAME_ORDER : NAME_ORDER;

  const getEntriesOptions = {
    content_type,
    skip,
    limit,
    order: formattedOrder,
    include,
  };

  if (filters) {
    const filterIds = await Promise.all(filters.map(mapFilterIds));

    filterIds.forEach(({ ids, fieldName }) => {
      const idsToString = ids.join();
      getEntriesOptions[fieldName] = idsToString;
    });
  }

  if (curated !== undefined) {
    getEntriesOptions['fields.curated'] = curated;
  }

  const response = await getEntries(getEntriesOptions, client);

  const { items, total } = response;

  return {
    items,
    total,
  };
};

export default getEntriesFromContentfulViaSdk;
