import dayjs, { Dayjs } from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import {
  getAllSubscriptionsByType,
  lastSubscriptionEndDate,
} from '../../../auth/utils/subscriptions';
import { useAppDispatch } from '../../../store/hooks/use-app-dispatch';
import { useTypedSelector } from '../../../store/hooks/use-typed-selector';
import { userSelector } from '../../../store/user/selectors';
import { setUserNotificationData } from '../../../store/user/user.slice';
import { NotificationPriority } from '../../../ui/notifications/data/notifications';
import { mutateUserNotificationsData } from '../../api/index';
import type { UsatSurveyMetadata } from '../../data/usat-survey-metadata';
import { shouldActivateSubscriptionBanner } from '../../utils/should-activate-notifications';
import NotificationBanner from './notification-banner';

dayjs.extend(AdvancedFormat);

function formatExpiryDateForMessage(date: Dayjs | undefined): string {
  return date ? ` on the ${date.format('Do of MMMM, YYYY')}` : '';
}

const nextShowTimestamp = (endDate: Dayjs): Dayjs => {
  const now = dayjs();
  const diff = endDate.diff(now, 'day');
  if (diff <= 14) {
    return now.add(1, 'day');
  }
  if (diff <= 45) {
    return now.add(7, 'day');
  }
  return now.add(10, 'day');
};

const SubscriptionExpiryBanner = () => {
  const {
    query: { debug },
  } = useRouter();
  const user = useTypedSelector(userSelector);
  const userNotificationData = user.notifications
    ?.subscriptionExpiryBanner as UsatSurveyMetadata;
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>();

  const active = useMemo<boolean>(
    () =>
      Boolean(debug) ||
      (user.id !== '' &&
        shouldActivateSubscriptionBanner(
          user.id,
          user.subscriptions,
          userNotificationData
        )),
    [debug, user, userNotificationData]
  );

  useEffect(() => {
    if (data && 'user' in data) {
      dispatch(
        setUserNotificationData({
          notifications: data.user.notifications.reduce((acc, notification) => {
            const newValue = { ...notification.value };
            // eslint-disable-next-line no-underscore-dangle
            delete newValue.__typename;
            acc[notification.name] = newValue;
            return acc;
          }, {}),
        })
      );
    }
  }, [data, dispatch]);

  const date =
    user?.subscriptions?.length > 0
      ? lastSubscriptionEndDate(
          getAllSubscriptionsByType(user.subscriptions, 'full')
        )
      : undefined;

  const onDismissHandler = () => {
    if (!debug && date !== undefined) {
      const notificationData = {
        ...user.notifications,
        subscriptionExpiryBanner: {
          dismissalCounter: userNotificationData?.dismissalCounter
            ? userNotificationData.dismissalCounter + 1
            : 1,
          nextDisplayTimestamp: nextShowTimestamp(date).valueOf().toString(),
        },
      };

      mutateUserNotificationsData(user.id, notificationData).then(
        (response) => {
          setData(response);
        }
      );
    }
  };

  if (!user) {
    return null;
  }

  const expiryMessage = `Your subscription is due to expire${formatExpiryDateForMessage(
    date
  )}. To renew your Digital Theatre+ subscription, please contact your institution's administrator or get in touch with our Renewals Team.`;

  return (
    <NotificationBanner
      name="subscriptionExpiryBanner"
      active={active}
      priority={NotificationPriority.HIGH}
      top
      dismissable
      onDismiss={onDismissHandler}
      autoDismiss={false}
      dismissTestId="subscription-expiry-banner-dismiss"
      ariaLabel="Subscription Expiry Notification"
    >
      {expiryMessage}
    </NotificationBanner>
  );
};

export default SubscriptionExpiryBanner;
