import React, { useEffect, useRef } from 'react';
import { FloatingPortal } from '@floating-ui/react';

interface StaticTooltipProps {
  open: boolean;
  onDismiss: () => void;
}

export function StaticTooltip({
  open,
  onDismiss,
  children,
}: React.PropsWithChildren<StaticTooltipProps>) {
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        onDismiss();
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onDismiss]);

  if (!open) {
    return null;
  }

  return (
    <FloatingPortal id="tooltip">
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          top: '25vh',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 999,
        }}
      >
        {children}
      </div>
    </FloatingPortal>
  );
}
